import React from "react";
import { Typography, Divider, Stack, Box, Button } from "@mui/material";
import { Config } from "../../../config";
import {
  removeRefreshToken,
  setUser,
} from "../../../store/actions/user.action";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { IUser } from "../../../types/auth";
import { AccountMenuStyles as styles, StyledMenu } from "./AccountMenu.styles";
import { LoginStatusEnum } from "../../../shared/constants/enums/Login";

const AccountMenu: React.FC<{
  userEmail: string;
  username: string;
}> = ({ userEmail, username }) => {
  const user = username === "" ? "My Account" : username;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<Element> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (anchorEl) {
      setAnchorEl(undefined);
    } else {
      setAnchorEl(event.currentTarget as HTMLElement);
    }
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleSignOut = () => {
    const base = `${window.location.protocol}//${window.location.host}`;
    const logout = `${Config.logoutUrl}${process.env.REACT_APP_CLIENT_ID}&post_logout_redirect_uri=${base}`;
    const deleteAuth: IUser = {
      email: "",
      username: "",
      token: "",
      loginStatus: LoginStatusEnum.IDLE,
    };
    dispatch(setUser(deleteAuth));
    dispatch(removeRefreshToken());
    document.cookie =
      "_rg_ess_jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    window.location.href = logout;
  };

  return (
    <Box mr={4.5}>
      <Typography onClick={handleClick} sx={styles.username}>
        {user}
      </Typography>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack sx={styles.stacksCenter} spacing={1}>
          <Stack spacing={1} sx={styles.stackSecondary}>
            <Typography variant="button">{userEmail}</Typography>
          </Stack>
          <Box sx={styles.box}>
            <Divider sx={styles.divider} />
          </Box>
          <Box pl="20px">
            <Button sx={styles.button} size="large" onClick={handleSignOut}>
              <Typography sx={styles.typographyLogOut}>Logout</Typography>
            </Button>
          </Box>
        </Stack>
      </StyledMenu>
    </Box>
  );
};

export default AccountMenu;
