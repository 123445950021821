import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  getRefreshToken,
  getToken,
  getUserInfo,
} from "../../thunks/user/user.thunks";
import { IUser } from "../../../types/auth";
import { AuthStatusEnum } from "../../../shared/constants/enums/Auth";
import { LoginStatusEnum } from "../../../shared/constants/enums/Login";

export interface UserData {
  authError?: string;
  authStatus: AuthStatusEnum;
  refreshStatus: AuthStatusEnum;
  refreshToken: string;
  token: string;
  user: IUser;
}

const initialState: UserData = {
  authError: "",
  authStatus: AuthStatusEnum.IDLE,
  user: {
    email: "",
    username: "",
    token: "",
    loginStatus: LoginStatusEnum.IDLE,
  },
  refreshStatus: AuthStatusEnum.IDLE,
  refreshToken: "",
  token: "",
};

export const userDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.user.username = get(payload, "username", "");
        state.authStatus = AuthStatusEnum.SUCCESS;
        state.user.loginStatus = LoginStatusEnum.SUCCESS;
      })
      .addCase(getToken.fulfilled, (state, { payload }) => {
        state.token = payload.access_token;
        state.refreshToken = payload.refresh_token;
        state.user.email = payload.email;
        state.user.token = payload.access_token;
        state.authStatus = AuthStatusEnum.SUCCESS;
      })
      .addCase(getToken.pending, (state) => {
        state.authStatus = AuthStatusEnum.PENDING;
      })
      .addCase(getToken.rejected, (state, action) => {
        state.authStatus = AuthStatusEnum.FAILED;
        state.authError = action.error.message;
      })
      .addCase(getRefreshToken.fulfilled, (state, { payload }) => {
        state.token = payload.access_token;
        state.refreshToken = payload.refresh_token;
        state.refreshStatus = AuthStatusEnum.SUCCESS;
      });
  },
  initialState,
  name: "user",
  reducers: {
    setUser(state, action: { payload: IUser }) {
      state.user = action.payload;
    },
    setRefreshTokenStatus(state, action: { payload: AuthStatusEnum }) {
      state.refreshStatus = action.payload;
    },
    removeRefreshToken(state) {
      state.refreshToken = "";
      state.refreshStatus = AuthStatusEnum.IDLE;
    },
  },
});

export default userDataSlice.reducer;
