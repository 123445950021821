import React from "react";
import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { AppsTable } from "../../components/AppsTable/AppsTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { AppManagmentStyles as styles } from "./AppsManagement.styles";
import useAppsManagement from "./state/useAppsManagement";
import SurprisedDan from "../../assets/SurprisedDan.png";

const AppsManagement = () => {
  const {
    isLoading,
    pendingApps,
    apps,
    approvedApps,
    pendingHeaders,
    approvedHeaders,
  } = useAppsManagement();
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={styles.containerBox}>
          {!isEmpty(pendingApps) && (
            <AppsTable
              rows={pendingApps}
              title="Pending Approval"
              headers={pendingHeaders}
            />
          )}
          {!isEmpty(approvedApps) && (
            <AppsTable
              rows={approvedApps}
              title="Apps"
              headers={approvedHeaders}
            />
          )}
          {isEmpty(apps) && (
            <Box sx={styles.noResultsBox}>
              <img src={SurprisedDan} alt="no apps" />
              <Typography variant="h4">
                You don&apos;t have any apps yet.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default AppsManagement;
