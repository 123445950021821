import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum, xdpColors } from "../../shared/constants/enums/Colors";

const TopNavigationStyles = createNamedStyles({
  box: {
    display: "flex",
    flexShrink: 0,
    alignItems: "flex-start",
    padding: "20px 36px 20px 36px",
    height: "fit-content",
    backgroundColor: ColorsEnum["#111111"],
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 2000,
  },
  button: {
    alignItems: "baseline",
    color: ColorsEnum["#FCFCFC"],
    width: "20%",
    padding: 0,
    fontSize: "13px",
    fontWeight: 800,
    margin: 0,
    textTransform: "none",
    "&&& svg path": {
      fill: "#737373 !important",
    },
  },
  boxMenu: {
    alignItems: "flex-start",
    display: "flex",
    height: "32px",
    marginRight: "5px",
  },
  boxExternal: {
    alignItems: "flex-start",
    display: "flex",
    height: "32px",
    marginRight: "auto",
  },
  boxBtn: {
    display: "flex",
    height: "32px",
    marginLeft: "10px",
  },
  boxAccount: {
    display: "flex",
    height: "32px",
    marginLeft: "25px",
    marginRight: "70px",
  },
  boxSecondary: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerOptions: {
    display: "flex",
    fontWeight: 800,
    fontSize: 13,
    paddingLeft: "30px",
    color: "white",
    lineHeight: "32px",
  },
  typographyVersion: {
    display: "flex",
    fontWeight: 700,
    fontSize: 12,
    paddingLeft: "8px",
    lineHeight: "32px",
    color: xdpColors.backgroundGrey,
  },
});

export { TopNavigationStyles };
