/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { MdCheckBox, MdContentCopy } from "react-icons/md";
import copy from "copy-to-clipboard";
import { CopyInputStyles as styles } from "./CopyInput.styles";

export type CopyInputProps = {
  testId: string;
  data: string;
};

export const CopyInput: React.FC<CopyInputProps> = ({ data, testId }) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>("");

  const handleCopy = async () => {
    copy(data);
    setHasCopied(true);
  };

  useMemo(() => {
    const placeholderText = new Array(data.length + 1).join("*");
    setPlaceholder(placeholderText);
  }, [data]);

  useEffect(() => {
    if (hasCopied) {
      setShowNotification(true);
    }
  }, [hasCopied]);

  return (
    <Box>
      <TextField
        value={placeholder}
        onClick={handleCopy}
        size="small"
        fullWidth
        sx={styles.input}
        InputProps={{
          endAdornment: (
            <InputAdornment data-testid={testId} position="end">
              <IconButton sx={{ fontSize: "14px", color: "black" }}>
                <MdContentCopy />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          {showNotification && (
            <Box padding="6px 6px 6px 0" justifyContent="left">
              <Grid item display="flex">
                <MdCheckBox fill="#4caf50" fontSize="24px" />
                <Typography
                  marginLeft={1}
                  lineHeight="24px"
                  variant="subtitle1"
                >
                  Copied to clipboard
                </Typography>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
