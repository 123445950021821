import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import Welcome from "./containers/Welcome/Welcome";
import RsoRequestClient from "./containers/RsoRequestClient/RsoRequestClient";
import Main from "./containers/Main/Main";
import Home from "./containers/Home/Home";
import Submission from "./containers/Submission/Submission";
import ProtectedRoutes from "./shared/utils/ProtectedRoutes";
import Layout from "./containers/Layout/Layout";
import ClientDocumentation from "./containers/ClientDocumentation/ClientDocumentation";
import NotFound from "./containers/NotFound";
import ErrorModal from "./containers/ErrorModal/ErrorModal";
import RiotSignOn from "./containers/RiotSignOn/RiotSignOn";
import Apps from "./containers/Apps/Apps";
import AppDetails from "./containers/AppDetails/AppDetails";
import AppsManagement from "./containers/AppsManagement/AppsManagement";
import { useAppSelector } from "./store/hooks/hooks";
import { selectLoginStatus } from "./store/selectors/user.selector";
import { LoginStatusEnum } from "./shared/constants/enums/Login";
import theme from "./theme";
import Documentation from "./containers/Documentation/Documentation";
import {
  DeveloperPortalOverviewEnum,
  FaqsEnum,
  PoliciesEnum,
} from "./shared/constants/enums/Documentation";

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "xdp-public-ui",
  env: process.env.REACT_APP_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

const App = () => {
  const loginStatus = useAppSelector(selectLoginStatus);
  const isLoggedIn = loginStatus === LoginStatusEnum.SUCCESS;

  return (
    <ThemeProvider theme={theme}>
      <ErrorModal />
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/oauth2-callback" element={<Main />} />
        <Route element={<ProtectedRoutes />}>
          <Route element={<Layout />}>
            <Route path="/home" element={<Home />} />
            <Route path="/apps" element={<Apps />}>
              <Route path="" element={<AppsManagement />} />
              <Route path="new" element={<RsoRequestClient />} />
              <Route path=":appId" element={<AppDetails />} />
            </Route>
            <Route
              path="/client-documentation"
              element={<ClientDocumentation />}
            />
            <Route path="/client-view" element={<Submission />} />
            <Route path="/sign-on" element={<RiotSignOn />} />
            {Object.keys(FaqsEnum).map((key) => {
              const { url, id, label } = FaqsEnum[key];
              return (
                <Route
                  key={id}
                  path={url}
                  element={<Documentation id={id} title={label} />}
                />
              );
            })}
            {Object.keys(PoliciesEnum).map((key) => {
              const { url, id, label } = PoliciesEnum[key];
              return (
                <Route
                  key={id}
                  path={url}
                  element={<Documentation id={id} title={label} />}
                />
              );
            })}
            {Object.keys(DeveloperPortalOverviewEnum).map((key) => {
              const { url, id, label } = DeveloperPortalOverviewEnum[key];
              return (
                <Route
                  key={id}
                  path={url}
                  element={<Documentation id={id} title={label} />}
                />
              );
            })}
          </Route>
        </Route>
        {isLoggedIn ? (
          <Route path="*" element={<NotFound />} />
        ) : (
          <Route path="*" element={<Welcome />} />
        )}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
