import React, { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { defaultTo } from "lodash";
import { API_ROUTES } from "../../shared/constants/api/apiRoutes";
import { AuthStatusEnum } from "../../shared/constants/enums/Auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import {
  selectAuthStatus,
  selectToken,
} from "../../store/selectors/user.selector";
import { getToken, getUserInfo } from "../../store/thunks/user/user.thunks";
import { AuthRequest } from "../../types/auth";
import { CookieValidator } from "../../shared/utils/CookieValidator";
import Layout from "../Layout/Layout";

const Main = () => {
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);
  const tokenauth = useAppSelector(selectToken);
  const [searchParams] = useSearchParams();
  const [, setCookie] = useCookies(["_rg_ess_jwt"]);
  const code = searchParams.get("code");
  const navigate = useNavigate();
  const base = `${window.location.protocol}//${window.location.host}`;
  const isExpired = CookieValidator();

  const requestAuth = () => {
    const request: AuthRequest = {
      code: code!,
      grant_type: "authorization_code",
      redirect_uri: base + API_ROUTES.AUTH_CALLBACK,
    };

    dispatch(getToken(request));
  };

  useMemo(() => {
    if (isExpired) {
      requestAuth();
    } else {
      navigate("/home");
    }
  }, [code]);

  useEffect(() => {
    if (authStatus === AuthStatusEnum.SUCCESS) {
      dispatch(getUserInfo());

      setCookie("_rg_ess_jwt", tokenauth, {
        expires: new Date(
          Date.now() +
            defaultTo(Number(process.env.REACT_APP_COOKIE_TTL), 3600000)
        ),
      });

      navigate("/home");
    }
  }, [authStatus]);

  return <Layout />;
};

export default Main;
