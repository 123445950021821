import React from "react";
import { Box, Typography } from "@mui/material";
import Page from "../../components/styled/Content/Page";
import PageHead from "../../components/styled/PageHead/PageHead";
import SectionHead from "../../components/styled/SectionHead/SectionHead";
import SectionBody from "../../components/styled/SectionBody/SectionBody";
import Section from "../../components/styled/Section/Section";
import StyledLink from "../../components/styled/Link/Link";
import { xdpColors } from "../../theme/themeConstants";

const ClientDocumentation: React.FC = () => {
  return (
    <Page>
      <PageHead title="OAuth Client Documentation">
        <Typography variant="body1">
          Discover more about using OAuth, including an in-depth tutorial and
          frequently asked questions.
        </Typography>
      </PageHead>

      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">OAuth Integration</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          All apps must request that users opt-in to sharing their personal
          data. To do this, your application will need to verify players using
          an OAuth flow, which is done through an OAuth client.
        </Typography>
      </SectionBody>

      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Getting a Production Key</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          Before you can get started with OAuth, you need a production key. If
          you don&apos;t have one, create one at developer.riotgames.com.
          We&apos;ll contact you using the email linked to your developer
          account to kick off the OAuth integration process.
        </Typography>
      </SectionBody>

      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Requesting an App</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" marginRight="4px">
            To request an app, fill out the{" "}
          </Typography>
          <StyledLink marginLeft="8px" toUrl="/apps/new">
            form.
          </StyledLink>
        </Box>
      </SectionBody>

      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">After your App is Approved</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          Before making any calls with your app, verify that you have an
          approved, functioning production application in the Developer Portal
          and a separate, approved app (we will have created this for you) with
          an app ID that matches exactly what we send you in an email.
        </Typography>
        <Typography variant="body1">
          <br />
          Here&apos;s where you direct players to login:
        </Typography>
        <StyledLink
          customColor={xdpColors.typographyWhite}
          toUrl="https://auth.riotgames.com/authorize?client_id=&redirect_uri=&response_type=code&scope=openid+offline_access"
        >
          https://auth.riotgames.com/authorize?client_id=&redirect_uri=&response_type=code&scope=openid+offline_access
        </StyledLink>
        <br />
        <br />
        <Typography variant="body1">
          After logging in, players are redirected back to the redirect_uri you
          specified. Here&apos;s information about how to integrate with OAuth
          and to view a sample Node web server that implements the example:
        </Typography>
        <br />
        <StyledLink display="inline" toUrl="/sign-on">
          Implementing OAuth
        </StyledLink>
        <br />
        <br />
        <StyledLink
          display="inline"
          toUrl="https://static.developer.riotgames.com/docs/rso/rso-example-app.zip"
        >
          Download OAuth Node App Example
        </StyledLink>
        <br />
        <br />
        <Typography variant="body1">
          Your app has access to endpoints that lets you identify who logged in.
        </Typography>
      </SectionBody>

      <Section>
        <SectionHead>
          <Typography variant="h4">VALORANT</Typography>
        </SectionHead>
        <SectionBody>
          <Typography variant="body1">
            Use{" "}
            <StyledLink
              display="inline"
              customColor={xdpColors.typographyWhite}
              toUrl="https://developer.riotgames.com/apis#account-v1/GET_getByAccessToken"
            >
              https://developer.riotgames.com/apis#account-v1/GET_getByAccessToken
            </StyledLink>
          </Typography>

          <Box
            sx={{
              borderLeft: "6px solid #5C5C5C",
              paddingLeft: "8px",
              margin: "16px 0px",
            }}
          >
            <Typography variant="body1">
              {`curl --location --request GET
              'https://americas.api.riotgames.com/riot/account/v1/accounts/me'
              --header 'Authorization: Bearer {accessToken}' `}
            </Typography>
            <Typography variant="body1">
              {`curl --location
              --request GET
              'https://europe.api.riotgames.com/riot/account/v1/accounts/me'
              --header 'Authorization: Bearer {accessToken}'`}
            </Typography>
            <Typography variant="body1">
              {`curl --location
              --request GET
              'https://asia.api.riotgames.com/riot/account/v1/accounts/me'
              --header 'Authorization: Bearer {accessToken}'`}
            </Typography>
          </Box>
          <Typography variant="body1">
            The data return from each cluster is identical. We recommend using
            the cluster closest to your servers.
          </Typography>
        </SectionBody>
      </Section>

      <Section>
        <SectionHead>
          <Typography variant="h4">League of Legends</Typography>
        </SectionHead>
        <SectionBody>
          <Typography variant="body1">
            Use{" "}
            <StyledLink
              customColor={xdpColors.typographyWhite}
              toUrl="https://developer.riotgames.com/apis#summoner-v4/GET_getByAccessToken"
            >
              https://developer.riotgames.com/apis#summoner-v4/GET_getByAccessToken
            </StyledLink>
          </Typography>

          <Box
            sx={{
              borderLeft: "6px solid #5C5C5C",
              paddingLeft: "8px",
              margin: "16px 0px",
            }}
          >
            <Typography variant="body1">
              {`curl --location --request GET 'https://na1.api.riotgames.com/lol/summoner/v4/summoners/me' --header 'Authorization: Bearer {accessToken}'`}
            </Typography>
          </Box>
          <Typography variant="body1">
            You&apos;ll need to include the cpid (current platform id) scope
            when directing the user to login, so that the OAuth access token is
            generated with the cpid scope. The cpid scope will ensure the cpid
            field is returned when making requests to{" "}
            <StyledLink
              toUrl="https://auth.riotgames.com/userinfo"
              customColor={xdpColors.typographyWhite}
            >
              https://auth.riotgames.com/userinfo
            </StyledLink>
            . Once you determine the user&apos;s current platform id (cpid) then
            you can make requests to the DNS associated with their current
            platform. (for example,{" "}
            <StyledLink
              toUrl="https://na1.api.riotgames.com"
              customColor={xdpColors.typographyWhite}
            >
              https://na1.api.riotgames.com
            </StyledLink>
            )
          </Typography>
        </SectionBody>
      </Section>

      <Section>
        <SectionHead>
          <Typography variant="h4">Teamfight Tactics</Typography>
        </SectionHead>
        <SectionBody>
          <Typography variant="body1">
            Use{" "}
            <StyledLink
              customColor={xdpColors.typographyWhite}
              toUrl="https://developer.riotgames.com/apis#tft-summoner-v1/GET_getByAccessToken"
            >
              https://developer.riotgames.com/apis#tft-summoner-v1/GET_getByAccessToken
            </StyledLink>
          </Typography>

          <Box
            sx={{
              borderLeft: "6px solid #5C5C5C",
              paddingLeft: "8px",
              margin: "16px 0px",
            }}
          >
            <Typography variant="body1">
              {`curl --location --request GET 'https://na1.api.riotgames.com/tft/summoner/v1/summoners/me' --header 'Authorization: Bearer {accessToken}'`}
            </Typography>
          </Box>
          <Typography variant="body1">
            You&apos;ll need to include the cpid (current platform id) scope
            when directing the user to login, so that the OAuth access token is
            generated with the cpid scope. The cpid scope will ensure the cpid
            field is returned when making requests to{" "}
            <StyledLink
              toUrl="https://auth.riotgames.com/userinfo"
              customColor={xdpColors.typographyWhite}
            >
              https://auth.riotgames.com/userinfo
            </StyledLink>
            . Once you determine the user&apos;s current platform id (cpid) then
            you can make requests to the DNS associated with their current
            platform. (for example,{" "}
            <StyledLink
              toUrl="https://na1.api.riotgames.com"
              customColor={xdpColors.typographyWhite}
            >
              https://na1.api.riotgames.com
            </StyledLink>
            )
          </Typography>
        </SectionBody>
      </Section>

      <Section>
        <SectionHead>
          <Typography variant="h4">Legends of Runeterra</Typography>
        </SectionHead>
        <SectionBody>
          <Typography variant="body1">
            Use{" "}
            <StyledLink
              customColor={xdpColors.typographyWhite}
              toUrl="https://developer.riotgames.com/apis#account-v1/GET_getByAccessToken"
            >
              https://developer.riotgames.com/apis#account-v1/GET_getByAccessToken
            </StyledLink>
          </Typography>

          <Box
            sx={{
              borderLeft: "6px solid #5C5C5C",
              paddingLeft: "8px",
              margin: "16px 0px",
            }}
          >
            <Typography variant="body1">
              {`curl --location --request GET
              'https://americas.api.riotgames.com/riot/account/v1/accounts/me'
              --header 'Authorization: Bearer {accessToken}' `}
            </Typography>
            <Typography variant="body1">
              {`curl --location
              --request GET
              'https://europe.api.riotgames.com/riot/account/v1/accounts/me'
              --header 'Authorization: Bearer {accessToken}'`}
            </Typography>
            <Typography variant="body1">
              {`curl --location
              --request GET
              'https://asia.api.riotgames.com/riot/account/v1/accounts/me'
              --header 'Authorization: Bearer {accessToken}'`}
            </Typography>
          </Box>
          <Typography variant="body1">
            The data return from each cluster is identical. We recommend using
            the cluster closest to your servers.
          </Typography>
        </SectionBody>
      </Section>
    </Page>
  );
};

export default ClientDocumentation;
