import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AccountMenu from "./AccountMenu/AccountMenu";
import { TopNavigationStyles as styles } from "./TopNavigation.styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { selectUserInfo } from "../../store/selectors/user.selector";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import MainLogoRiotFist21Icon from "../../icons/mainLogoRiotfist21";
import {
  PORTAL_NAME,
  PORTAL_VERSION,
} from "../../shared/constants/labels/welcome";
import StyledLink from "../styled/Link/Link";
import { setIsSidebarDocsOpen } from "../../store/actions/app.action";

const TopNavigation: React.FC = () => {
  const { email, username } = useAppSelector(selectUserInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.innerWidth < 800) {
      dispatch(setIsSidebarDocsOpen(false));
    } else if (window.location.pathname.includes("Developer-Portal-Overview")) {
      dispatch(setIsSidebarDocsOpen(true));
    } else {
      dispatch(setIsSidebarDocsOpen(false));
    }
  }, []);

  return (
    <>
      <Box sx={styles.box}>
        <Box sx={styles.boxSecondary}>
          <MainLogoRiotFist21Icon color={ColorsEnum["#F9F9F9"]} />
          <StyledLink
            useIcon
            toUrl="/home"
            textDecoration="none"
            customColor={ColorsEnum["#F9F9F9"]}
            styles={{ display: "flex" }}
          >
            <Typography sx={styles.headerOptions}>{PORTAL_NAME}</Typography>
            <Typography sx={styles.typographyVersion}>
              {PORTAL_VERSION}
            </Typography>
          </StyledLink>
        </Box>
        <Box sx={styles.boxMenu}>
          <Box sx={styles.boxBtn}>
            <Typography sx={styles.headerOptions}>
              <StyledLink
                useIcon
                toUrl="/apps"
                textDecoration="none"
                customColor={ColorsEnum["#F9F9F9"]}
              >
                APPS
              </StyledLink>
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.boxMenu}>
          <Box sx={styles.boxBtn}>
            <Typography sx={styles.headerOptions}>
              <StyledLink
                useIcon
                toUrl="/Developer-Portal-Overview"
                textDecoration="none"
                customColor={ColorsEnum["#F9F9F9"]}
              >
                DOCS
              </StyledLink>
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.boxExternal}>
          <Box sx={styles.boxBtn}>
            <StyledLink
              useIcon
              toUrl="https://developer.riotgames.com/"
              textDecoration="none"
              fontSize="14px"
              customColor={ColorsEnum["#F9F9F9"]}
              customIconColor={ColorsEnum["#808080"]}
            >
              <Typography sx={styles.headerOptions}>
                DEVELOPER PORTAL
              </Typography>
            </StyledLink>
          </Box>
        </Box>
        <Box sx={styles.boxBtn}>
          <StyledLink
            useIcon
            toUrl="https://support-developer.riotgames.com/"
            textDecoration="none"
            fontSize="14px"
            customColor={ColorsEnum["#F9F9F9"]}
            customIconColor={ColorsEnum["#808080"]}
          >
            <Typography sx={styles.headerOptions}>SUPPORT</Typography>
          </StyledLink>
        </Box>
        <Box sx={styles.boxAccount}>
          <AccountMenu username={username} userEmail={email} />
        </Box>
      </Box>
    </>
  );
};

export default TopNavigation;
