/* eslint-disable prefer-template */
/* eslint-disable import/no-unresolved */
import React, { ReactNode } from "react";
import { Link, TypographyProps, Theme, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SxProps } from "@mui/system";
import { MdArrowOutward } from "react-icons/md";
import { startsWith } from "lodash";
import { ColorsEnum } from "../../../shared/constants/enums/Colors";

interface StyledLinkProps extends TypographyProps {
  alignItems?: string;
  children: ReactNode;
  display?: string;
  useIcon?: boolean;
  toUrl?: string;
  size?: string;
  sx?: SxProps<Theme>;
  styles?: Record<string, unknown>;
  customColor?: string;
  customIconColor?: string;
  textDecoration?: string;
}

const StyledLink = ({
  children,
  useIcon,
  toUrl = "",
  sx = {},
  styles = {},
  display = "block",
  alignItems = "center",
  customColor = ColorsEnum["#EB6567"],
  customIconColor = ColorsEnum["#EB6567"],
  textDecoration = "underline",
  fontSize,
  ...props
}: StyledLinkProps) => {
  const isInternal = startsWith(toUrl, "/");
  const isRoot = toUrl === "";

  if (isRoot) {
    return (
      <Box
        className={props.className}
        style={{
          alignItems,
          textDecoration,
          color: customColor,
          display,
          overflowWrap: "anywhere",
          fontWeight: "500 !important",
          ...styles,
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <>
      {isInternal ? (
        <RouterLink
          to={toUrl!}
          className={props.className}
          style={{
            alignItems,
            textDecoration,
            color: customColor,
            display,
            overflowWrap: "anywhere",
            fontWeight: "500 !important",
            ...styles,
          }}
        >
          {children}
        </RouterLink>
      ) : (
        <Link
          target="_blank"
          href={toUrl!}
          className={props.className}
          sx={{
            textDecoration,
            color: `${customColor} !important`,
            display: "inline-flex",
            alignItems: "center",
            overflowWrap: "anywhere",
            cursor: "pointer",
            fontSize: fontSize || "16px !important",
            fontWeight: "500 !important",
            "&:hover": {
              filter: "brightness(120%)",
            },
            ...sx,
          }}
        >
          {children}
          {useIcon && <MdArrowOutward size="14px" color={customIconColor} />}
        </Link>
      )}
    </>
  );
};

export default StyledLink;
