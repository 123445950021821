import { AppTableHeaders } from "../../../types/apps";

export enum AppsStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export const appsApprovedHeaders: AppTableHeaders = [
  {
    label: "APP NAME",
    value: "appName",
  },
  {
    label: "OAUTH CLIENT ID",
    value: "oAuthClientId",
  },
];

export const appsPendingHeaders: AppTableHeaders = [
  {
    label: "APP NAME",
    value: "appName",
  },
];

export enum AppDetailsLoadingStatusEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
  IDLE = "IDLE",
}
