import { useState } from "react";
import { useParams } from "react-router-dom";
import { isUndefined } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import { selectAppDetails } from "../../../store/selectors/app.selector";
import {
  getAppDetails,
  rotateSecretBasic,
} from "../../../store/thunks/app/app.thunks";
import { setKidProcessed } from "../../../store/actions/app.action";

interface IUseSecretBasic {
  showSecretSuccess: boolean;
  showGenerateKeyModal: boolean;
  clientSecretHash: string;
  handleCreateKey: (showModal: boolean) => void;
  handleInitKey: () => void;
  setShowSecretSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseSecretSuccess: () => void;
}

const usePrivateKeyJWT = (): IUseSecretBasic => {
  const { appId } = useParams<"appId">();
  const appDetails = useAppSelector(selectAppDetails);
  const dispatch = useAppDispatch();
  const [showSecretSuccess, setShowSecretSuccess] = useState(false);
  const [showGenerateKeyModal, setShowGenerateKeyModal] = useState(false);

  const handleCreateKey = (showModal: boolean) => {
    setShowGenerateKeyModal(showModal);
  };

  const handleInitKey = () => {
    setShowGenerateKeyModal(false);
    if (!isUndefined(appId)) {
      dispatch(setKidProcessed(""));
      dispatch(
        rotateSecretBasic({
          appId,
        })
      );
    }
    setShowSecretSuccess(true);
  };

  const handleCloseSecretSuccess = () => {
    setShowSecretSuccess(false);
    if (!isUndefined(appId)) {
      dispatch(
        getAppDetails({
          appId,
        })
      );
    }
  };

  return {
    showSecretSuccess,
    showGenerateKeyModal,
    clientSecretHash: appDetails.rso_client.client_secret,
    handleCreateKey,
    handleInitKey,
    setShowSecretSuccess,
    handleCloseSecretSuccess,
  };
};

export default usePrivateKeyJWT;
