import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const GenerateSecretBasicStyles = createNamedStyles({
  box: {
    margin: "0 20px 48px 20px",
    padding: "0 40px 0 40px",
    textAlign: "center",
    color: "black !important",
  },
  buttonCancel: {
    width: "220px",
    marginRight: "12px",
  },
  button: {
    width: "220px",
    marginRight: "12px",
  },
});
