import { RootState } from "../store.interfaces";

export const selectAppError = (state: RootState) =>
  state.RsoClientReducer.appError;
export const selectAppErrorCode = (state: RootState) =>
  state.RsoClientReducer.appErrorCode;
export const selectAppErrorMessage = (state: RootState) =>
  state.RsoClientReducer.appErrorMessage;
export const selectRsoClientRequestData = (state: RootState) =>
  state.RsoClientReducer.rsoClientRequestData;
export const selectLoading = (state: RootState) =>
  state.RsoClientReducer.loading;
export const selectIsCookieExpired = (state: RootState) =>
  state.RsoClientReducer.isCookieExpired;
export const selectBase64Image = (state: RootState) =>
  state.RsoClientReducer.base64Image;
