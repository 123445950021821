import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { defaultTo } from "lodash";
import { useLocation } from "react-router-dom";
import { MainContentStyles } from "./MainContent.styles";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { selectIsSideBarDocsOpen } from "../../store/selectors/app.selector";
import { setIsSidebarDocsOpen } from "../../store/actions/app.action";
import {
  DeveloperPortalOverviewEnum,
  FaqsEnum,
  PoliciesEnum,
} from "../../shared/constants/enums/Documentation";

interface Props {
  children: React.ReactNode;
}

const MainContent: FC<Props> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isSidebarDocsOpen = useAppSelector(selectIsSideBarDocsOpen);
  const allPaths = [
    ...Object.values(FaqsEnum),
    ...Object.values(PoliciesEnum),
    ...Object.values(DeveloperPortalOverviewEnum),
  ].map((value) => value.url);

  useEffect(() => {
    if (window.innerWidth < 800) {
      dispatch(setIsSidebarDocsOpen(false));
    } else if (allPaths.some((path) => location.pathname.includes(path))) {
      dispatch(setIsSidebarDocsOpen(true));
    } else {
      dispatch(setIsSidebarDocsOpen(false));
    }
  }, [location.pathname]);

  return (
    <Grid
      container
      sx={MainContentStyles(false, defaultTo(isSidebarDocsOpen, false))}
    >
      {children}
    </Grid>
  );
};

export default MainContent;
