import ky from "ky";
import { Cookies } from "react-cookie";
import { Config } from "../../config";

export const PublicApi = ky.create({
  prefixUrl: Config.baseAPIUrl,
  credentials: "include",
});

export const PrivateApi = () => {
  const cookies = new Cookies();
  return ky.create({
    prefixUrl: Config.baseAPIUrl,
    credentials: "include",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${cookies.get("_rg_ess_jwt")}`,
    },
  });
};

export const DocsApi = ky.create({
  prefixUrl: Config.docsUrl,
});
