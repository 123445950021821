import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { ReactComponent as SadPoroIcon } from "../icons/SadPoroIcon.svg";
import StyledLink from "../components/styled/Link/Link";

const NotFound: React.FC = () => {
  return (
    <Box
      height="100vh"
      alignItems="center"
      justifyContent="center"
      display="flex"
      width="100%"
      flexDirection="column"
      padding={3}
      borderRadius={2}
      border="0.2px solid #cccccc"
      sx={{
        backgroundColor: "#fff",
        boxShadow:
          "0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box display="flex" alignItems="baseline" justifyContent="center">
        <span style={{ fontSize: "120px" }}>4</span>
        <SadPoroIcon title="a sad creature in the shape of a 0" />
        <span style={{ fontSize: "120px" }}>4</span>
      </Box>
      <Typography variant="h2">404 Error</Typography>
      <Box
        maxWidth="560px"
        textAlign="center"
        mb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h5" mb={2}>
          The page you&apos;re looking for is either disabled or doesn&apos;t
          exist.
        </Typography>
        <StyledLink toUrl="/">
          <Button variant="red">Return home</Button>
        </StyledLink>
      </Box>
    </Box>
  );
};

export default NotFound;
