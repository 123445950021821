import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CookieValidator } from "./CookieValidator";

const ProtectedRoutes = () => {
  const isExpired = CookieValidator();
  const isPlaywright = localStorage.getItem("isPlaywright");
  if (isPlaywright) {
    return <Outlet />;
  }
  return isExpired ? <Navigate to="/" /> : <Outlet />;
};

export default ProtectedRoutes;
