import React from "react";
import { Typography, Box, Button } from "@mui/material";
import StyledModal from "../StyledModal/StyledModal";
import { GenerateSecretBasicStyles as styles } from "./GenerateSecretBasic.styles";

type GenerateModalProps = {
  confirmGenerate: () => void;
  onClose: () => void;
  openDialog: boolean;
};

export const GenerateSecretBasic: React.FC<GenerateModalProps> = ({
  confirmGenerate,
  openDialog,
  onClose,
}) => {
  return (
    <StyledModal
      contentText={
        <Box sx={styles.box}>
          <Typography variant="modalsContent" lineHeight="22.4px">
            After rotation, any apps using the old secret will need to be
            updated. The old secret may (but isn&apos;t guaranteed to) remain
            functional for up to 30 minutes.
          </Typography>
        </Box>
      }
      title="Replacing Existing Secret"
      open={openDialog}
      modalActions={
        <>
          <Button
            variant="white"
            onClick={onClose}
            size="small"
            sx={styles.buttonCancel}
          >
            Cancel
          </Button>

          <Button
            variant="red"
            onClick={confirmGenerate}
            size="small"
            sx={styles.button}
            data-testid="button-generate-rotate-secret"
          >
            Rotate Secret
          </Button>
        </>
      }
      handleClose={onClose}
    />
  );
};
