import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  createRsoClient,
  patchRsoClient,
} from "../../thunks/rsoClient/rsoClient.thunks";
import { ClientRequestData } from "../../../types/clients";

export interface IAppState {
  appError: boolean;
  appErrorCode: string;
  appErrorMessage: string;
  base64Image: string;
  isCookieExpired: boolean;
  loading: boolean;
  rsoClientRequestData: ClientRequestData;
}

const initialState: IAppState = {
  appError: false,
  appErrorCode: "",
  appErrorMessage: "",
  base64Image: "",
  loading: false,
  isCookieExpired: false,
  rsoClientRequestData: {
    appId: "",
    base64Logo: "",
    contactEmail: [],
    gameAccess: [],
    postLogoutRedirectUris: [],
    policyUri: "",
    redirectUris: [],
    tosUri: "",
  },
};

export const rsoClientDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(createRsoClient.fulfilled, (state, action) => {
        state.rsoClientRequestData = action.payload;
        state.loading = false;
        state.appErrorCode = "200";
      })
      .addCase(createRsoClient.pending, (state) => {
        state.loading = true;
        state.appErrorCode = "";
      })
      .addCase(createRsoClient.rejected, (state, { payload }) => {
        const errorCode = get(payload, "code", "").toString();
        const errorMessage = get(payload, "message", "").toString();
        if (errorCode === "401") {
          state.isCookieExpired = true;
        } else {
          state.appErrorCode = errorCode;
          state.appError = true;
          state.appErrorMessage = errorMessage;
        }
        state.loading = false;
      })
      .addCase(patchRsoClient.fulfilled, (state) => {
        state.loading = false;
        state.appErrorCode = "200";
      })
      .addCase(patchRsoClient.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchRsoClient.rejected, (state, { payload }) => {
        const errorCode = get(payload, "code", "").toString();
        const errorMessage = get(payload, "message", "").toString();
        if (errorCode === "401") {
          state.isCookieExpired = true;
        } else {
          state.appErrorCode = errorCode;
          state.appError = true;
          state.appErrorMessage = errorMessage;
        }
        state.loading = false;
      });
  },
  initialState,
  name: "rsoClient",
  reducers: {
    setBase64Image(state, action: { payload: string }) {
      state.base64Image = action.payload;
    },
    setIsCookieExpired(state, action: { payload: boolean }) {
      state.isCookieExpired = action.payload;
    },
    setIsLoading(state, action: { payload: boolean }) {
      state.loading = action.payload;
    },
    removeError(state) {
      state.appError = false;
    },
    resetAppErrorCode(state) {
      state.appErrorCode = "";
    },
  },
});

export default rsoClientDataSlice.reducer;
