import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  selectJWKs,
  selectIsGetJWKLoading,
  selectIsDeleteJWKLoading,
  selectKidStored,
  selectAppDetails,
} from "../../../store/selectors/app.selector";
import {
  deleteJWK,
  generateJWK,
  getJWKs,
} from "../../../store/thunks/app/app.thunks";
import { AppsStatus } from "../../../shared/constants/enums/Apps";
import { DeleteKeyStatusEnum } from "../../../shared/constants/enums/SecretsRotation";
import {
  removeDeleteKeyStatus,
  setKidProcessed,
} from "../../../store/actions/app.action";
import { JWKData } from "../../../types/secretsRotation";

interface IUsePrivateKeyJWT {
  isGetLoading: boolean;
  showSecretSuccess: boolean;
  showGenerateKeyModal: boolean;
  showDeleteJWKModal: boolean;
  applicationKeys: JWKData[];
  handleCreateKey: (showModal: boolean) => void;
  handleInitKey: () => void;
  handleDeleteKeyModal: (showModal: boolean) => void;
  handleDeleteKey: () => void;
  setShowSecretSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseSecretSuccess: () => void;
}

const usePrivateKeyJWT = (): IUsePrivateKeyJWT => {
  const { appId } = useParams<"appId">();
  const applicationKeys = useAppSelector(selectJWKs);
  const isGetLoading = useAppSelector(selectIsGetJWKLoading);
  const appDetails = useAppSelector(selectAppDetails);
  const keyStored = useAppSelector(selectKidStored);
  const isDeleteLoading = useAppSelector(selectIsDeleteJWKLoading);
  const dispatch = useAppDispatch();
  const [showSecretSuccess, setShowSecretSuccess] = useState(false);
  const [showGenerateKeyModal, setShowGenerateKeyModal] = useState(false);
  const [showDeleteJWKModal, setShowDeleteKeyModal] = useState(false);

  const handleCreateKey = (showModal: boolean) => {
    setShowGenerateKeyModal(showModal);
  };

  const handleInitKey = () => {
    setShowGenerateKeyModal(false);
    if (!isUndefined(appId)) {
      dispatch(setKidProcessed(""));
      dispatch(
        generateJWK({
          appId,
        })
      );
    }
    setShowSecretSuccess(true);
  };

  const handleDeleteKeyModal = (showModal: boolean) => {
    dispatch(setKidProcessed(""));
    setShowDeleteKeyModal(showModal);
  };

  const handleDeleteKey = () => {
    if (!isUndefined(appId) && !isEmpty(keyStored)) {
      dispatch(
        deleteJWK({
          appId,
          kid: keyStored,
        })
      );
      dispatch(setKidProcessed(""));
    }
    setShowDeleteKeyModal(false);
  };

  const handleCloseSecretSuccess = () => {
    setShowSecretSuccess(false);
    if (!isUndefined(appId)) {
      dispatch(
        getJWKs({
          appId,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !!appDetails?.app?.status &&
      appDetails.app.status === AppsStatus.APPROVED &&
      !isUndefined(appId)
    ) {
      dispatch(
        getJWKs({
          appId,
        })
      );
    }
  }, [appDetails]);

  useMemo(() => {
    if (
      !isUndefined(appId) &&
      isDeleteLoading === DeleteKeyStatusEnum.SUCCESS
    ) {
      dispatch(
        getJWKs({
          appId,
        })
      );
      dispatch(removeDeleteKeyStatus());
    }
  }, [isDeleteLoading]);

  useEffect(() => {
    if (!isEmpty(keyStored)) {
      setShowDeleteKeyModal(true);
    }
  }, [keyStored]);

  return {
    isGetLoading,
    showSecretSuccess,
    showGenerateKeyModal,
    showDeleteJWKModal,
    applicationKeys,
    handleCreateKey,
    handleInitKey,
    handleDeleteKey,
    handleDeleteKeyModal,
    setShowSecretSuccess,
    handleCloseSecretSuccess,
  };
};

export default usePrivateKeyJWT;
