import React from "react";
import { Typography } from "@mui/material";

export const codeParser = (code: string[]) => {
  const parsedCode = code.map((c) => {
    if (c === "") {
      return (
        <>
          <br />
        </>
      );
    }
    return (
      <Typography sx={{ whiteSpace: "break-spaces" }} variant="body1">
        {c}
      </Typography>
    );
  });
  return parsedCode;
};
