export const xdpColors = {
  blackResting: "#292727",
  blackHover: "#211E1F",
  blackActive: "#211E1F",
  black: "#8A8A8A",
  grey: "#1F1F1F",
  tableBorder: "#8080804D",
  backgroundGrey: "#575757",
  shadowGrey: "#686868",
  shadowGreyDisable: "#404040",
  whiteResting: "#E5E5E5",
  whiteButtonText: "#EBEBEB",
  whiteHover: "#F0F0F0",
  whiteActive: "#F9F9F9",
  white: "#FCFCFC",
  buttonGreyBackground: "#282828",
  typographyGrey: "#636363",
  typographySubtitle: "#666666",
  typographyWhite: "#C7C7C7",
  typographyBlack: "#0A0A0A",
  redActive: "#BD2A2C",
  redHover: "#C73033",
  redResting: "#D13639",
  typographyRed: "#EB6567",
};
