import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { DocsApi } from "../../../shared/utils/KyApi";
import {
  ZendesArticleRequest,
  ZendeskArticleResponse,
  ZendeskSectionResponse,
  ZendeskSectionsRequest,
} from "../../../types/documentation";

export const getArticle = createAsyncThunk<
  ZendeskArticleResponse,
  ZendesArticleRequest
>(
  "/api/v2/help_center/en-us/articles/",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocsApi.get(
        `api/v2/help_center/en-us/articles/${payload.articleId}`
      )
        .json<ZendeskArticleResponse>()
        .catch((error) => {
          throw error.response;
        });

      return response;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);

export const getArticlesBySection = createAsyncThunk<
  ZendeskSectionResponse,
  ZendeskSectionsRequest
>(
  "/api/v2/help_center/en-us/sections/",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await DocsApi.get(
        `api/v2/help_center/en-us/sections/${payload.sectionId}/articles`
      )
        .json<ZendeskSectionResponse>()
        .catch((error) => {
          throw error.response;
        });

      return response;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);
