import React, { ReactNode } from "react";
import { Typography, Grid } from "@mui/material";
import { HeadStyles, PageHeadStyles } from "./PageHead.styles";
import { xdpColors } from "../../../theme/themeConstants";

type PageHeadProps = {
  title: string;
  children?: ReactNode;
  color?: string;
  fontSize?: string;
  marginBottom?: string;
};

const PageHead = ({
  children,
  title,
  color = xdpColors.white,
  fontSize = "32px",
  marginBottom = "36px",
}: PageHeadProps) => {
  return (
    <Grid item xs={12} sx={HeadStyles(marginBottom)}>
      <Typography variant="h2" mb="16px" sx={PageHeadStyles(color, fontSize)}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

export default PageHead;
