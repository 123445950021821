import getLegacyPortalUrlForApp from "../../../shared/utils/LegacyPortal";
import { useAppSelector } from "../../../store/hooks/hooks";
import { selectRsoClientRequestData } from "../../../store/selectors/rsoClient.selector";
import { ClientRequestData } from "../../../types/clients";

interface ISubmissionView {
  rsoClientRequest: ClientRequestData;
  email: string;
}

const useSubmissionView = (): ISubmissionView => {
  const email = useAppSelector((state) => state.userReducer.user.email);
  const rsoClientRequest = useAppSelector(selectRsoClientRequestData);
  const appUrl = getLegacyPortalUrlForApp(rsoClientRequest.appId);

  const useRsoRequestClientValues: ISubmissionView = {
    rsoClientRequest: { ...rsoClientRequest, appUrl },
    email,
  };

  return useRsoRequestClientValues;
};

export default useSubmissionView;
