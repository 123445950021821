export enum DocumentationStatusEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
  IDLE = "IDLE",
}

type DocumentationData = {
  url: string;
  testId: string;
  label: string;
  id: string;
};

export const FaqsEnum: Record<string, DocumentationData> = {
  FAQS: {
    url: "/FAQs-The-Basics",
    testId: "FAQs-The-Basics",
    label: "FAQs: The Basics",
    id: "22801164172051",
  },
  RESPONSE_TIMES: {
    url: "/Response-Times",
    testId: "Response-Times",
    label: "Response Times",
    id: "22801270481427",
  },
  MULTIPLE_APPS: {
    url: "/Using-Multiple-Applications",
    testId: "Using-Multiple-Applications",
    label: "Using Multiple Applications",
    id: "22801284198035",
  },
  RIOT_RSO: {
    url: "/RSO-Riot-Sign-On",
    testId: "RSO-Riot-Sign-On",
    label: "RSO (Riot Sign On)",
    id: "22801670382739",
  },
  PRODUCTION_KEYS: {
    url: "/Production-Key-Applications",
    testId: "Production-Key-Applications",
    label: "Production Key Applications",
    id: "22801383038867",
  },
  VERIFICATION_TIMES: {
    url: "/Verification-for-Production-Applications",
    testId: "Verification-for-Production-Applications",
    label: "Verification for Production Applications",
    id: "22801461443091",
  },
  REPORTING_ISSUES: {
    url: "/Reporting-Issues",
    testId: "Reporting-Issues",
    label: "Reporting Issues",
    id: "22801519716883",
  },
  SUMMONER_NAME: {
    url: "/Summoner-Name-to-Riot-ID",
    testId: "Summoner-Name-to-Riot-ID",
    label: "Summoner Name to Riot ID",
    id: "22698983117587",
  },
};

export const DeveloperPortalOverviewEnum: Record<string, DocumentationData> = {
  OVERVIEW: {
    url: "/Developer-Portal-Overview",
    testId: "Developer-Portal-Overview",
    label: "Developer Portal Overview",
    id: "22698431229203",
  },
  TERMS: {
    url: "/API-Terms-and-Conditions",
    testId: "API-Terms-and-Conditions",
    label: "API Terms and Conditions",
    id: "22698917218323",
  },
  LEGAL_POLICES: {
    url: "/Riot-Legal-Policies",
    testId: "Riot-Legal-Policies",
    label: "Riot Legal Policies",
    id: "22941160802835",
  },
  AUTH_DOC: {
    url: "/Client-Documentation-Auth",
    testId: "OAuth-Client-Documentation",
    label: "OAuth Client Documentation",
    id: "22897607341075",
  },
};

export const PoliciesEnum: Record<string, DocumentationData> = {
  GENERAL_POLICIES: {
    url: "/General-Policies",
    testId: "General-Policies",
    label: "General Policies",
    id: "22698591841939",
  },
  GAME_SPECIFIC: {
    url: "/Game-Specific-Policies-Overview",
    testId: "Game-Specific-Policies-Overview",
    label: "Game-Specific Policies Overview",
    id: "22698614472339",
  },
  LOL: {
    url: "/League-of-Legends",
    testId: "League-of-Legends",
    label: "League of Legends",
    id: "22698698001939",
  },
  LOR: {
    url: "/Legends-of-Runeterra",
    testId: "Legends-of-Runeterra",
    label: "Legends of Runeterra",
    id: "22698735834515",
  },
  TFT: {
    url: "/Teamfight-Tactics",
    testId: "Teamfight-Tactics",
    label: "Teamfight Tactics",
    id: "22698732381587",
  },
  VALORANT: {
    url: "/VALORANT",
    testId: "VALORANT",
    label: "VALORANT     ",
    id: "22698769097107",
  },
  NA_TOURNAMENTS: {
    url: "/NA-Tournaments",
    testId: "NA-Tournaments",
    label: "NA Tournaments",
    id: "22698775429395",
  },
  OCE_TOURNAMENTS: {
    url: "/OCE-Tournaments",
    testId: "OCE-Tournaments",
    label: "OCE Tournaments",
    id: "22698854904723",
  },
  UE_TOURNAMENTS: {
    url: "/EU-Tournaments",
    testId: "EU-Tournaments",
    label: "EU Tournaments",
    id: "22915431358867",
  },
};
