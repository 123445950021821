import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const InputStyles = {
  borderColor: "rgba(128, 128, 128, 0.3)",
  maxWidth: "1000px",
  width: "100%",
  borderRadius: "4px",
  paddingRight: 0,

  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #00000000 inset",
      WebkitTextFillColor: "#fff",
      transitionDelay: "9999s",
    },
    color: ColorsEnum["#FCFCFC"],
    background: "rgba(128, 128, 128, 0.3)",
    minHeight: "40px",
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: "-8px",
    fontWeight: 700,
    fontSize: "16px",
    "&::placeholder": {
      color: ColorsEnum["#C7C7C7"],
    },
  },
  "p.Mui-error": {
    color: "#E15241",
  },
  // todo(qm): check with design on style of this, TEMP
  "& .Mui-disabled": {
    color: "rgb(213, 213, 213)",
    WebkitTextFillColor: "rgb(213, 213, 213) !important",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& label.Mui-focused": {
    color: ColorsEnum["#FCFCFC"],
  },

  "&&& .Mui-error": {
    "& fieldset": {
      borderColor: "#E15241 !important",
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: ColorsEnum["#FCFCFC"],
  },
  "& .MuiOutlinedInput-root": {
    paddingRight: 0,
    "& fieldset": {
      borderColor: "rgba(128, 128, 128, 0.3)",
    },
    "&:hover fieldset": {
      borderColor: ColorsEnum["#FCFCFC"],
    },
    "&.Mui-focused fieldset": {
      borderColor: ColorsEnum["#FCFCFC"],
    },
  },
};
