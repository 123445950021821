import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAppsTable, Order } from "./useAppsTable.interface";
import { AppTableData, AppsTableData } from "../../../types/apps";
import sortingAscDesc from "../../../shared/utils/SortingAscDesc";

type useAppsTableProps = {
  rows: AppsTableData;
};

export const useAppsTable = ({ rows }: useAppsTableProps): IAppsTable => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof AppTableData>("appName");
  const sortedRows = useMemo(
    () => sortingAscDesc({ order, rows, orderBy }),
    [orderBy, order]
  );

  const handleRequestSort = (property: keyof AppTableData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const navigate = useNavigate();

  const handleAppDetails = (param: string) => {
    navigate(`/apps/${param}`);
  };

  return {
    handleAppDetails,
    handleRequestSort,
    order,
    orderBy,
    sortedRows,
  };
};
