import React, { ReactNode, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { defaultTo } from "lodash";
import { AuthStatusEnum } from "../../shared/constants/enums/Auth";
import {
  setIsCookieExpired,
  setIsLoading,
} from "../../store/actions/rsoClient.action";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { selectIsCookieExpired } from "../../store/selectors/rsoClient.selector";
import {
  selectRefreshToken,
  selectRefreshTokenStatus,
  selectToken,
} from "../../store/selectors/user.selector";
import { getRefreshToken } from "../../store/thunks/user/user.thunks";
import { RefreshTokenRequest } from "../../types/auth";
import { setRefreshTokenStatus } from "../../store/actions/user.action";

type CheckCookieExpirationProps = {
  children: ReactNode;
};

const CheckCookieExpiration = ({ children }: CheckCookieExpirationProps) => {
  const cookieFromState = useAppSelector(selectIsCookieExpired);
  const refreshToken = useAppSelector(selectRefreshToken);
  const refreshTokenStatus = useAppSelector(selectRefreshTokenStatus);
  const newToken = useAppSelector(selectToken);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, setCookie] = useCookies(["_rg_ess_jwt"]);

  const checkCookieExpiration = () => {
    let isExpired = true;
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
      const cookieValue = cookie.trim();
      if (cookieValue.startsWith(`${"_rg_ess_jwt"}=`)) {
        isExpired = false;
      }
    });

    dispatch(setIsCookieExpired(isExpired));
  };

  useEffect(() => {
    checkCookieExpiration();

    const intervalId = setInterval(
      checkCookieExpiration,
      defaultTo(Number(process.env.REACT_APP_VALIDATION_TIME), 60000)
    );

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    checkCookieExpiration();
  }, [location.pathname]);

  useEffect(() => {
    if (cookieFromState) {
      const refreshRequest: RefreshTokenRequest = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };

      dispatch(getRefreshToken(refreshRequest));
    }
  }, [cookieFromState]);

  useEffect(() => {
    if (refreshTokenStatus === AuthStatusEnum.SUCCESS) {
      dispatch(setRefreshTokenStatus(AuthStatusEnum.IDLE));
      setCookie("_rg_ess_jwt", newToken, {
        expires: new Date(
          Date.now() +
            defaultTo(Number(process.env.REACT_APP_COOKIE_TTL), 3600000)
        ),
      });
      dispatch(setIsLoading(false));
    }
  }, [refreshTokenStatus, newToken]);

  return <>{children}</>;
};

export default CheckCookieExpiration;
