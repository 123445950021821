import React from "react";
import { Typography, Box, Button } from "@mui/material";
import StyledModal from "../StyledModal/StyledModal";
import { DeleteJWKStyles as styles } from "./DeleteJWK.styles";

type DeleteJWKProps = {
  confirmDelete: () => void;
  onClose: () => void;
  openDialog: boolean;
};

export const DeleteJWK: React.FC<DeleteJWKProps> = ({
  confirmDelete,
  openDialog,
  onClose,
}) => {
  return (
    <StyledModal
      contentText={
        <Box sx={styles.box}>
          <Typography variant="modalsContent" lineHeight="22.4px">
            Are you sure you want to delete this public key? The associated
            private key and all associated JWKs will no longer be accepted.
          </Typography>
        </Box>
      }
      title="Deleting Existing Secret"
      open={openDialog}
      modalActions={
        <>
          <Button
            variant="white"
            onClick={onClose}
            size="small"
            sx={styles.button}
          >
            Cancel
          </Button>

          <Button
            variant="red"
            onClick={confirmDelete}
            size="small"
            sx={styles.button}
          >
            Delete Secret
          </Button>
        </>
      }
      handleClose={onClose}
    />
  );
};
