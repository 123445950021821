import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { ClientSecret } from "../../types/clients";
import { CopyInput } from "../CopyInput/CopyInput";
import { JWTPanelStyles as styles } from "./JWTPanel.styles";

export type JWTPanelProps = {
  secret: ClientSecret;
};

export const JWTPanel: React.FC<JWTPanelProps> = ({ secret }) => {
  return (
    <Grid container flexDirection="column">
      <Box sx={styles.box}>
        <Typography variant="modalsContent" lineHeight="22.4px">
          Make sure to copy and paste each token and key before closing this
          window.
        </Typography>
      </Box>
      <Typography variant="modalsTitle">Client Secret Details</Typography>
      <Grid container marginTop={1} flexDirection="column" rowGap={2}>
        <Grid item>
          <Typography variant="subtitle1">Sample Client Assertion</Typography>
          <Typography mb="8px" variant="subtitle2" lineHeight="22.4px">
            A JWT used to prove your client&apos;s identity, valid for 100 years
            <br />
            and usable like a password.
          </Typography>
          <Grid>
            <CopyInput testId="button-sampleToken" data={secret.sampleToken} />
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            <Typography variant="subtitle1">Private Key</Typography>
            <Typography variant="subtitle2" lineHeight="22.4px">
              A JWK that lets you sign your own client assertions. <br />
            </Typography>
            <Typography
              variant="italic"
              mb="8px"
              lineHeight="19.4px !important"
            >
              <i>
                Using this private JWK to sign your own short-lived client
                assertion JWTs adds another layer of security.
              </i>
            </Typography>
          </Grid>
          <Grid mt={1.5}>
            <CopyInput testId="button-privateKey" data={secret.privateKey} />
          </Grid>
        </Grid>
        <Grid>
          <Grid>
            <Typography variant="subtitle1">Public Key</Typography>
            <Typography mb="8px" variant="subtitle2">
              A JWK that lets Riot services validate your client assertion.
            </Typography>
          </Grid>
          <Grid>
            <CopyInput testId="button-publicKey" data={secret.publicKey} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
