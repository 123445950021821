import React, { ReactNode } from "react";
import { Grid, GridProps, SxProps } from "@mui/material";
import { SectionBodyStyles as styles } from "./SectionBody.styles";

type SectionBodyProps = {
  children: ReactNode | ReactNode[];
} & GridProps;

const SectionBody = ({ children, sx = {}, ...props }: SectionBodyProps) => {
  return (
    <Grid item xs={12} sx={{ ...styles.root, ...sx } as SxProps} {...props}>
      {children}
    </Grid>
  );
};

export default SectionBody;
