import { ColorsEnum } from "../../shared/constants/enums/Colors";
import { IStyles } from "../../shared/CreateNamedStyles";

export const MainContentStyles = (isOpen: boolean, isDocsOpen: boolean) => {
  const gridStyles: IStyles = {
    grid: {
      minWidth: "100%",
      minHeight: "calc(100vh - 357px)",
      bgcolor: ColorsEnum["#0A0A0A"],
      border: 0,
      paddingLeft: isOpen || isDocsOpen ? "clamp(15px, 25vw, 50px)" : "148px",
      paddingRight: "148px",
      transitionDuration: "0.3s",
    },
  };
  return gridStyles.grid;
};

export default MainContentStyles;
