import { ColorsEnum } from "../../../shared/constants/enums/Colors";
import { createNamedStyles } from "../../../shared/CreateNamedStyles";

export const SectionStyles = createNamedStyles({
  root: {
    padding: "32px",
    marginBottom: "24px",
    borderRadius: "4px",
    backgroundColor: ColorsEnum["#1F1F1F"],
    "& .MuiButton-root": {
      marginTop: "16px",
    },
  },
});
