import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import {
  AppTableStyles as styles,
  StyledAppTableCell,
  StyledAppTableHeader,
  StyledTableSortLabel,
} from "./AppsTable.styles";
import StyledChip from "../styled/Chip/Chip";
import { AppsTableData, AppTableHeaders } from "../../types/apps";
import { AppsStatus } from "../../shared/constants/enums/Apps";
import { CopyButton } from "../CopyButton/CopyButton";
import { useAppsTable } from "./state/useAppsTable";

export interface IAppTable {
  rows: AppsTableData;
  title: string;
  headers: AppTableHeaders;
}

export const AppsTable = ({ rows, title, headers }: IAppTable) => {
  const { order, orderBy, sortedRows, handleAppDetails, handleRequestSort } =
    useAppsTable({
      rows,
    });

  return (
    <Box sx={styles.box}>
      <Paper sx={styles.paper}>
        <Typography
          data-testid={`table-title-${title}`}
          sx={styles.tableHead}
          variant="h4"
        >
          {title}
        </Typography>
        <TableContainer>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <StyledAppTableHeader key={header.value}>
                    <StyledTableSortLabel
                      direction={orderBy === header.value ? order : "asc"}
                      active={orderBy === header.value}
                      onClick={() => handleRequestSort(header.value)}
                    >
                      {header.label}
                    </StyledTableSortLabel>
                  </StyledAppTableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row) => (
                <TableRow key={row.appId} sx={styles.tableRow}>
                  <StyledAppTableCell
                    component="th"
                    scope="row"
                    onClick={() => handleAppDetails(row.appId)}
                  >
                    <Stack>
                      <Typography variant="h6" sx={styles.appName}>
                        {row.appName}
                      </Typography>
                      <StyledChip label={row.role} sx={styles.chip} />
                    </Stack>
                  </StyledAppTableCell>
                  {row.status !== AppsStatus.PENDING && (
                    <StyledAppTableHeader>
                      <Stack alignItems="baseline" direction="row">
                        <Box sx={styles.idText}>{row.oAuthClientId}</Box>
                        <CopyButton
                          valueToCopy={row.oAuthClientId}
                          confirmationText="OAuth Client Id copied!"
                        />
                      </Stack>
                    </StyledAppTableHeader>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
