import React from "react";
/* eslint-disable import/no-unresolved */
import InputFieldProps from "@rdxportal/ui-components/dist/components/InputField/InputField.type";
import { InputField } from "@rdxportal/ui-components";
import { InputStyles } from "./StyledIInput.styles";

const StyledInput = ({ sx = {}, ...props }: InputFieldProps) => {
  return (
    <InputField
      placeholder="https://"
      sx={{
        ...InputStyles,
        ...sx,
      }}
      {...props}
    />
  );
};

export default StyledInput;
