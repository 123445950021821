import { xdpColors } from "../../shared/constants/enums/Colors";
import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const AppManagmentStyles = createNamedStyles({
  containerBox: {
    mb: 5,
  },
  noResultsBox: {
    backgroundColor: xdpColors.grey,
    borderRadius: "8px",
    padding: 14,
    textAlign: "center",
    margin: "40px 0px 400px",
    height: "280px",
    "& img": {
      width: "200px",
      height: "200px",
    },
  },
});
