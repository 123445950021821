import { createNamedStyles } from "../../shared/CreateNamedStyles";
import ClientSummary from "../../assets/WelcomePageBackground.jpg";
import { topNavHeight } from "../../types/style";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const WelcomeStyles = createNamedStyles({
  root: {},
  page: {
    backgroundImage: `url(${ClientSummary})`,
    backgroundSize: "cover",
    alignItems: "flex-start",
    display: "flex",
    flexShrink: 0,
    justifyContent: "flex-start",
    flexDirection: "column",
    height: `calc(100vh - ${topNavHeight})`,
    minHeight: "650px",
  },
  pageBody: {
    maxWidth: "55%",
    paddingRight: "24px",
    justifyContent: "center",
    background:
      "linear-gradient(180deg, rgba(18, 20, 20, 0.9) 0%, rgba(29, 57, 68, 0.9) 100%)",
    borderRight: "1px solid black",
    clipPath: "polygon(0 0, 93% 0, 100% 100%, 0% 100%)",
    height: "100%",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "36px",
  },
  content: {
    maxWidth: "700px",
    "& .MuiTypography-h5": {
      marginTop: "16px",
    },
    paddingTop: "116px",
    marginLeft: "72px",
    marginRight: "72px",
    px: 2,
  },
  description: {
    marginBottom: "50px",
  },
  welcomeTitle: {
    letterSpacing: "1px",
  },
  portalNameTitle: {
    marginBottom: "10px",
    letterSpacing: "1px",
  },
  boxBtn: {
    marginRight: "24px",
  },
  developerPortalBtn: {
    backgroundColor: "transparent",
    color: "#cccccc",
    padding: "16px 32px",
    border: "2px solid #3f555c",
    textTransform: "uppercase",
    boxShadow: "none",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 600,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#cccccc",
      border: "2px solid #3f555c",
      filter: "brightness(120%)",
    },
  },
  signInBtn: {
    backgroundColor: `${ColorsEnum["#D53235"]}`,
    color: `${ColorsEnum["#FCFCFC"]}`,
    padding: "16px 32px",
    border: "0px solid #3f555c",
    textTransform: "uppercase",
    boxShadow: "none",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 600,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: `${ColorsEnum["#D53235"]}`,
      color: `${ColorsEnum["#FCFCFC"]}`,
      filter: "brightness(120%)",
    },
  },
});
