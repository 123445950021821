import { createSlice } from "@reduxjs/toolkit";
import {
  getArticle,
  getArticlesBySection,
} from "../../thunks/documentation/documentation.thunks";
import { DocumentationStatusEnum } from "../../../shared/constants/enums/Documentation";
import {
  ZendeskArticleResponse,
  ZendeskSectionResponse,
} from "../../../types/documentation";

export interface ArticleData {
  articleError?: string;
  sectionError?: string;
  articleStatus: DocumentationStatusEnum;
  sectionStatus: DocumentationStatusEnum;
  article?: ZendeskArticleResponse;
  section?: ZendeskSectionResponse;
}

const initialState: ArticleData = {
  articleStatus: DocumentationStatusEnum.IDLE,
  sectionStatus: DocumentationStatusEnum.IDLE,
  articleError: "",
};

export const documentationDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getArticle.fulfilled, (state, { payload }) => {
        state.article = payload;
        state.articleStatus = DocumentationStatusEnum.SUCCESS;
      })
      .addCase(getArticle.pending, (state) => {
        state.articleStatus = DocumentationStatusEnum.PENDING;
      })
      .addCase(getArticle.rejected, (state, action) => {
        state.articleStatus = DocumentationStatusEnum.FAILED;
        state.articleError = action.error.message;
      })
      .addCase(getArticlesBySection.fulfilled, (state, { payload }) => {
        state.section = payload;
        state.sectionStatus = DocumentationStatusEnum.SUCCESS;
      })
      .addCase(getArticlesBySection.pending, (state) => {
        state.sectionStatus = DocumentationStatusEnum.PENDING;
      })
      .addCase(getArticlesBySection.rejected, (state, section) => {
        state.sectionStatus = DocumentationStatusEnum.FAILED;
        state.sectionError = section.error.message;
      });
  },
  initialState,
  name: "documentation",
  reducers: {},
});

export default documentationDataSlice.reducer;
