import { TableCell, TableSortLabel } from "@mui/material";
import { styled } from "@mui/system";
import { xdpColors } from "../../shared/constants/enums/Colors";
import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const AppTableStyles = createNamedStyles({
  box: {
    margin: "24px 0px 0px",
  },
  paper: {
    width: "100%",
    borderRadius: "8px",
    backgroundColor: xdpColors.grey,
  },
  tableHead: {
    padding: "24px 0px 24px 20px",
  },
  table: {
    tableLayout: "fixed",
  },
  img: {
    height: "50px",
    width: "45px",
  },
  chip: {
    fontSize: "13px",
    fontWeight: 400,
    marginTop: "6px",
    textTransform: "capitalize",
    padding: "3px 4px",
    height: "fit-content",
    width: "fit-content",
    ".MuiChip-label": {
      padding: "0px 6px",
    },
  },
  tableSort: {
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&:active": {
        color: "white",
      },
    },
  },
  idText: {
    width: "95px",
    fontSize: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginRight: 2,
    color: `${xdpColors.typographyWhite}`,
    fontWeight: "medium",
  },
  tableRow: {
    "&:last-child td, &:last-child th": { border: 0 },
    "&:hover .MuiTableCell-root": {
      cursor: "pointer",
      backgroundColor: "rgba(128,128,128, 0.3)",
    },
  },
  appName: {
    color: `${xdpColors.typographyWhite} !important`,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export const StyledAppTableHeader = styled(TableCell)(() => ({
  color: "white !important",
  paddingLeft: "30px",
  fontSize: "12px",
  letterSpacing: "0.06em",
  fontWeight: "bold",
  borderBottom: `0.5px solid ${xdpColors.tableBorder}`,
  "& .Mui-active": {
    color: "white !important",
    opacity: 1,
  },
}));

export const StyledAppTableCell = styled(TableCell)(() => ({
  color: `${xdpColors.typographyWhite}`,
  paddingLeft: "30px",
  borderBottom: `0.5px solid ${xdpColors.tableBorder}`,
  display: "flex",
}));

export const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  "&:hover": {
    color: "white",
    opacity: 0.5,
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
    opacity: 0.5,
  },
}));
