import React from "react";
import { isEmpty } from "lodash";
import { UseFormTrigger } from "react-hook-form";
import { Button, Box, Grid, Stack } from "@mui/material";
import StyledInput from "../StyledInput/StyledInput";
import { MultipleInputStyles as styles } from "./MultipleInput.styles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import { FormInput } from "../../types/clientForm";

export type MultipleInputProps = {
  placeholder: string;
  onChange: (elements: string[]) => void;
  values: string[];
  errors: string[];
  helperText?: string;
  triggerValidation?: UseFormTrigger<FormInput>;
};

const MultipleInput: React.FC<MultipleInputProps> = ({
  placeholder,
  onChange,
  values,
  helperText,
  errors,
  triggerValidation,
}) => {
  const handleChange = (
    idx: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...values];
    newValues[idx] = event.target.value;
    if (values[idx].length === 0 && newValues[idx + 1] === undefined) {
      newValues.push("");
    }
    onChange(newValues);
    if (triggerValidation) {
      triggerValidation();
    }
  };

  const handleDelete = (idx: number) => {
    const newValues = values.filter((_, itr) => itr !== idx);
    onChange(newValues);
    if (triggerValidation) {
      triggerValidation();
    }
  };
  const canDelete = (index: number, value?: string) =>
    value !== "" || index !== values.length - 1;

  const handleEmptyArrayError = (index: number) => {
    if (isEmpty(errors) && index !== 0) {
      return false;
    }
    return !!errors[index] || !!helperText;
  };

  return (
    <Box>
      {values.map((value, index) => (
        <Grid
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          container
          gridAutoFlow="row"
          sx={styles.gridContainer}
        >
          <Stack
            direction="row"
            spacing={0}
            width="100%"
            sx={{ alignItems: "baseline" }}
          >
            <StyledInput
              sx={{ marginRight: canDelete(index, value) ? 0 : "20px" }}
              placeholder={index === 0 ? placeholder : ""}
              value={value}
              onChange={(e) => handleChange(index, e)}
              data-cy="client-input"
              error={handleEmptyArrayError(index)}
              helperText={errors[index] || helperText}
            />

            {canDelete(index, value) && (
              <Button
                disableTouchRipple
                sx={{
                  backgroundColor: "none",
                  color: ColorsEnum["#C7C7C7"],
                  margin: "0px 0px 0px 10px !important",
                  padding: "0px",
                  minWidth: 0,
                  "&:hover": {
                    backgroundColor: "none",
                  },
                }}
                onClick={() => handleDelete(index)}
              >
                X
              </Button>
            )}
          </Stack>
        </Grid>
      ))}
      {/* {globalError && (
        <Grid container sx={styles.gridContainerGlobalError}>
          <Grid item marginRight={1} color="#F54251">
            <MdError color="inherit" />
          </Grid>
          <Grid item color="#F54251">
            <Typography variant="subtitle1" color="inherit">
              {globalError}
            </Typography>
          </Grid>
        </Grid>
      )} */}
    </Box>
  );
};

export default MultipleInput;
