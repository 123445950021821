import React from "react";
import { Typography, Grid } from "@mui/material";
import CreateRsoClientForm from "../../components/CreateRsoClientForm/CreateRsoClientForm";
import useRsoRequestClient from "./state/useRsoRequestClient";
import { Environments } from "../../shared/utils/Environments";
import { ClientType } from "../../types/clients";
import PageHead from "../../components/styled/PageHead/PageHead";
import PageBody from "../../components/styled/PageBody/PageBody";
import StyledLink from "../../components/styled/Link/Link";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { useRefreshToken } from "../../shared/utils/useRefreshToken";

const RsoRequestClient: React.FC = () => {
  useRefreshToken();
  const { modalOpen, onSave, resetError, setModalOpen } = useRsoRequestClient();

  return (
    <>
      <PageHead title="App Request Form">
        <Typography variant="body1">
          To submit your request, please complete this form. Please note that
          once you submit, you&apos;ll have to make a request at{" "}
          <StyledLink toUrl="https://developer.riotgames.com">
            developer.riotgames.com
          </StyledLink>{" "}
          to make any changes.
        </Typography>
      </PageHead>
      <PageBody>
        <Grid container>
          <CreateRsoClientForm
            env={Environments.DEV}
            onSave={onSave}
            onCancel={() => setModalOpen(true)}
            resetError={resetError}
            clientType={ClientType.ThirdParty}
            error={null}
          />
        </Grid>
      </PageBody>
      <ConfirmationModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default RsoRequestClient;
