import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import { PageBodyStyles as styles } from "./PageBody.styles";

type PageBodyProps = {
  children: ReactNode;
};

const PageBody = ({ children }: PageBodyProps) => {
  return (
    <Grid item xs={12} sx={styles.root}>
      {children}
    </Grid>
  );
};

export default PageBody;
