import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { MdAdd } from "react-icons/md";
import StyledInput from "../../components/StyledInput/StyledInput";
import usePrivateKeyJWT from "./state/usePrivateKeyJWT";
import { GenerateJWK } from "../../components/GenerateJWK/GenerateJWK";
import { CreateJWKSuccess } from "../../components/CreateJWKSuccess/CreateJWKSuccess";
import { DeleteJWK } from "../../components/DeleteJWK/DeleteJWK";
import { PrivateKeyStyles as styles } from "./PrivateKeyJWT.styles";
import { SecretsRotationTable } from "../../components/SecretsRorationTable/SecretsRotationTable";
import { secretsRotationHeaders } from "../../shared/constants/enums/SecretsRotation";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

export const PrivateKeyJWT: React.FC = () => {
  const {
    isGetLoading,
    applicationKeys,
    showSecretSuccess,
    showGenerateKeyModal,
    showDeleteJWKModal,
    handleCloseSecretSuccess,
    handleDeleteKeyModal,
    handleDeleteKey,
    handleCreateKey,
    handleInitKey,
  } = usePrivateKeyJWT();

  return (
    <>
      {!isGetLoading ? (
        <>
          <Grid item mt={2} mb="32px" md={3} alignContent="flex-start">
            <Grid width="100%">
              <Grid marginTop={3}>
                <Typography variant="h5">Token Endpoint Auth Method</Typography>
              </Grid>
              <Grid mb={2}>
                <Typography variant="body2">
                  How your OAuth client proves its identity.
                </Typography>
              </Grid>
              <StyledInput
                sx={styles.input}
                fullWidth
                disabled
                value="Private Key JWT"
              />
            </Grid>
          </Grid>
          <GenerateJWK
            openDialog={showGenerateKeyModal}
            onClose={() => handleCreateKey(false)}
            confirmGenerate={handleInitKey}
          />
          <CreateJWKSuccess
            showCreateSuccess={showSecretSuccess}
            onClose={handleCloseSecretSuccess}
          />
          <DeleteJWK
            openDialog={showDeleteJWKModal}
            onClose={() => handleDeleteKeyModal(false)}
            confirmDelete={handleDeleteKey}
          />
          <Box width="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                JSON Web Keys ({applicationKeys.length})
              </Typography>
              <Button
                sx={styles.button}
                variant="white"
                size="medium"
                onClick={() => handleCreateKey(true)}
              >
                <MdAdd />
                &nbsp;Create New Keypair
              </Button>
            </Box>
            <Box marginTop="16px">
              <SecretsRotationTable
                headers={secretsRotationHeaders}
                rows={applicationKeys!}
              />
            </Box>
          </Box>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
