import React from "react";
import { Button } from "@mui/material";
import useSubmissionView from "./state/useSubmissionView";
import ClientSummary from "../../components/ClientSummary/ClientSummary";
import Page from "../../components/styled/Content/Page";
import PageHead from "../../components/styled/PageHead/PageHead";
import PageBody from "../../components/styled/PageBody/PageBody";
import StyledLink from "../../components/styled/Link/Link";
import { gamesToValues } from "../../types/clients";
import { AppDetailsResponse } from "../../types/auth";

const Submission: React.FC = () => {
  const { rsoClientRequest, email } = useSubmissionView();
  const clientSummaryProps = { ...rsoClientRequest, contactEmail: [email] };
  const gamesAccess = (
    rsoClientRequest.gameAccess as AppDetailsResponse["app"]["games"]
  ).map((game) => gamesToValues[game]);

  clientSummaryProps.gameAccess = gamesAccess;

  return (
    <Page>
      <PageHead title="App Request" />
      <PageBody>
        <ClientSummary {...clientSummaryProps} />
        <StyledLink toUrl="/apps" textDecoration="none">
          <Button variant="red" sx={{ mb: "72px" }}>
            Go To Apps
          </Button>
        </StyledLink>
      </PageBody>
    </Page>
  );
};

export default Submission;
