import { RootState } from "../store.interfaces";

export const selectArticle = (state: RootState) =>
  state.DocumentationReducer.article;
export const selectArticleStatus = (state: RootState) =>
  state.DocumentationReducer.articleStatus;
export const selectArticleError = (state: RootState) =>
  state.DocumentationReducer.articleError;
export const selectSection = (state: RootState) =>
  state.DocumentationReducer.section;
export const selectSectionStatus = (state: RootState) =>
  state.DocumentationReducer.sectionStatus;
export const selectSectionError = (state: RootState) =>
  state.DocumentationReducer.sectionError;
