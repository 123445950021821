import { RootState } from "../store.interfaces";

export const selectToken = (state: RootState) => state.userReducer.token;
export const selectAuthStatus = (state: RootState) =>
  state.userReducer.authStatus;

export const selectUserInfo = (state: RootState) => state.userReducer.user;
export const selectRefreshToken = (state: RootState) =>
  state.userReducer.refreshToken;
export const selectRefreshTokenStatus = (state: RootState) =>
  state.userReducer.refreshStatus;
export const selectLoginStatus = (state: RootState) =>
  state.userReducer.user.loginStatus;
