import React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import CodeBox from "../../components/styled/CodeBox/CodeBox";
import Page from "../../components/styled/Content/Page";
import StyledLink from "../../components/styled/Link/Link";
import PageHead from "../../components/styled/PageHead/PageHead";
import SectionBody from "../../components/styled/SectionBody/SectionBody";
import SectionHead from "../../components/styled/SectionHead/SectionHead";
import { RiotSignOnStyles as styles } from "./RiotSignOnStyles.styles";
import { code1, code2, code3, code4, code5, code6, code7 } from "./codes";
import { codeParser } from "../../shared/utils/CodeParser";
import { xdpColors } from "../../shared/constants/enums/Colors";

const RiotSignOn = () => {
  return (
    <Page>
      <StyledLink
        styles={{ marginLeft: "-15px" }}
        textDecoration="none"
        toUrl="/Developer-Portal-Overview"
      >{`< Docs`}</StyledLink>
      <PageHead title="Implementing OAuth" />
      <Typography variant="body1">
        This tutorial provides guidance for implementing OAuth from initially
        connecting the service, serving a Sign In link, authenticating the user,
        to processing the users tokens.
        <ul>
          <li>To start working with OAuth, you will need the following: </li>
          <li>
            OAuth — hosted at{" "}
            <Link
              color={xdpColors.whiteResting}
              href="https://auth.riotgames.com"
            >
              https://auth.riotgames.com
            </Link>
            . Use this to authenticate during this process.
          </li>
          <ul style={{ listStyleType: "disc" }}>
            <li>/authorize — endpoint for obtaining an authorization code</li>
            <li>
              /token — endpoint to exchange authorization codes for access,
              identity, and refresh tokens
            </li>
            <li>
              /jwks.json — endpoint to obtain JSON Web Keys for verifying the
              authenticity of access and identity tokens{" "}
            </li>
            <li>
              (optional) /userinfo — endpoint to use your Access Token to obtain
              user information
            </li>
          </ul>
          <li>
            For this process, add{" "}
            <span style={{ backgroundColor: xdpColors.shadowGrey }}>
              127.0.0.1 local.example.com
            </span>{" "}
            to your /etc/hosts file
          </li>
          <li>
            Register a client — use this to ensure you have credentials to use
            with OAuth
            <ul style={{ listStyleType: "disc" }}>
              <li>
                the client must be registered to allow
                https://local.example.com/oauth2-callback as a redirect_uri
              </li>
            </ul>
          </li>
          <li>The service must be secured with https</li>
          <li>
            Access to standard NPM libraries to run the Node.js code examples in
            this process.
          </li>
        </ul>
        This tutorial will be giving most examples as Node.js code samples, and
        assumes that you have access to standard npm libraries, such as request.
      </Typography>
      <SectionHead sx={{ marginBottom: "8px", marginTop: "40px" }}>
        <Typography variant="h4">Registering a New OAuth Client</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          Follow the instructions on the App Request Form to submit a request
          for an OAuth client. Older clients may continue to use client secret
          basic and receive a client_secret, but newer clients will receive a
          few fields to support private key JWT client auth, such as:
          <ul>
            <li>client_id</li>
            <li>public key</li>
            <li>private key</li>
            <li>example signed 100 year token (jwt)</li>
          </ul>
          Note: The private key that is generated on your behalf is not stored
          and cannot be retrieved. New keypairs can be generated without
          disabling the old keypair.
          <br />
          <br />
          If you are familiar with JWT signing, you may choose to implement
          proper token minting using the returned private key for increased
          security.
          <br />
          <br />
          If you are not familiar with JWTs and signing, continue to use the
          example 100 year token as your client assertion in the code examples
          in this process, but be sure to store and treat it as you would a
          plaintext password.
          <br />
          <br />
          Note: If you want locale-specific values for client name, logo uri,
          privacy policy uri, and terms of service, after your client is
          created, please respond with the list using the following form:{" "}
          {`<field>#<locale>, for example, privacy policy url#de_DE: <url>`}
          <br />
          <br />
          Use the following Riot-supported locales: cs_CZ, de_DE, el_GR, en_AU,
          en_GB, en_PL, en_US, es_AR, es_ES, es_MX, fr_FR, hu_HU, it_IT, ja_JP,
          pl_PL, pt_BR, ro_RO, ru_RU, tr_TR.
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">
          Understanding the Authorization Code Flow
        </Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          The Authorization Code flow consists of the following:
          <ul>
            <li>
              / - the index page, a place where we can click a nice big ‘Sign
              nIn’ link
            </li>
            <li>
              /oauth2-callback - where we will ask OAuth to redirect the player
              after a successful Sign In.
            </li>
          </ul>
          <CodeBox>{codeParser(code1)}</CodeBox>
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Sending Users to OAuth</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          The purpose of route / is to deliver a Sign In link that the player
          can click to authenticate against OAuth. OAuth expects several
          parameters passed to it or it will throw an error indicating something
          not included. Make sure the following fields are included:
          <br />
          <br />
          <br />
          Mandatory fields:
          <ul style={{ marginTop: 0 }}>
            <li>
              redirect_uri - the OAuth 2 Callback route set up on the Riot
              server. This route needs to be able to process a code query
              parameter that is added to the URI on when OAuth redirects the
              player back to the URI. Make sure to have this URI added as one of
              the redirect_uris during client registration. In this example, use
              http://local.exmaple.com:3000/oauth2-callback
            </li>
            <li>
              client_id - ID assigned to client during registration. This will
              be the Client ID when you registered a client during the first
              part of this process.
            </li>
            <li>
              response_type - response type expected, should be code for
              authorization code flow
            </li>
            <li>
              scope - a predefined data scope, must include openid to
              authenticate.
            </li>
            <li>Additional scopes that can be requested are:</li>
            <ul style={{ listStyleType: "disc" }}>
              <li>cpid - returns the game region for League of Legends.</li>
              <li>
                offline_access - allows Refresh Tokens to be used to retrieve
                new access_tokens that have access to the /userinfo endpoint
              </li>
            </ul>
          </ul>
          Optional fields:
          <ul style={{ marginTop: 0 }}>
            <li>
              login_hint - field used to specify hints to pre-populate data on
              the Login Page. Supports several formats:
            </li>
            <ul style={{ listStyleType: "disc" }}>
              <li>{`{regioncode} - lowercase region code, for example, dev9`}</li>
              <li>
                {`{regioncode}|{username} - lowercase region code, pipe, and
              username, for example, dev9|daguava`}
              </li>
              <li>
                {`{regioncode}#{userid} - lowercase region code, hash, and user ID,
              for example, dev9#2054`}
              </li>
            </ul>
            <li>
              ui_locales - space-separated list of player’s preferred BCP47
              language tag values in order of most to least preferred.
            </li>
            <li>
              state - an opaque value provided to the authorize endpoint. The
              same value is returned to you when the endpoint sends its reply.
              Enables you to compare value sent and received, to prevent CSRF.
            </li>
          </ul>
          Using all the mandatory fields, the Sign In link is:
          <br />
          <br />
          <span style={{ textDecoration: "underline" }}>
            https://auth.riotgames.com/authorize?redirect_uri=http://local.leagueoflegends.com:3000/oauth2-callback&client_id=oujzg5jiibvzo&response_type=code&scope=openid
          </span>
          <br />
          <br />
          Modifying the code to make the Sign In link a bit more portable, the
          server.js now looks as follows:
          <CodeBox>{codeParser(code2)}</CodeBox>
          Here, the player is presented with the Login Page of OAuth, and may
          sign in.
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Response from OAuth</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          When the player successfully logs in, a 302 Redirect sends their
          browser to the redirect_uri that was included in the Sign In link.
          <br />
          <br />
          Note: The callback route https://local.example.com/oauth2-callback
          does not do anything yet.
          <br />
          <br />
          This route receives a code as a url query-string parameter, and the
          server must then make a server-to-server request to exchange this code
          for Access, Identity, and Refresh Tokens. You must send the following
          to OAuth’s Token endpoint to receive these Tokens back:
          <ul style={{ marginTop: 0 }}>
            <li>
              Authorization: Basic ... - Set an Authorization Header. The format
              of this header is &quot;Basic&quot;+ Base64Encode(client_id +
              &quot;&quot; + client_secret)
            </li>
            <li>form data</li>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                grant_type - Tell OAuth you are working with the
                authorization_code grant type.
              </li>
              <li>
                code - Pass OAuth the access code, which was received as a
                querystring parameter to the oauth2-callback route:
                ...com:3000/oauth2-callback?code=CxhkPgX8GiMKR4-E-YD8Ng
              </li>
              <li>redirect_uri - Pass in the same redirect_uri used before.</li>
            </ul>
          </ul>
          To simplify making requests, use the request package. The basic
          callback route using private key JWT, looks as follows:
          <CodeBox>{codeParser(code3)}</CodeBox>
          The following example uses Client Secret Basic:
          <CodeBox>{codeParser(code4)}</CodeBox>A raw Token endpoint response
          looks similar to:
          <CodeBox>{codeParser(code5)}</CodeBox>
          Change the callback of the response to parse this and get the Tokens.
          <CodeBox>{codeParser(code6)}</CodeBox>
          The following is an explanation of the fields in the response:
          <ul style={{ marginTop: 0 }}>
            <li>
              scope - Details what level of access the given Access Token
              provides.
            </li>
            <li>expires_in - The life span(in seconds)</li>
            <li>token_type - Method of authorization token provides.</li>
            <li>
              Bearer means the entire Token should be provided. Bearer
              eyJhbGciOi1NsImZCI6InM ... NTkzMTA3LCJjaWQiJnmE-BVnZbYqY
            </li>
            <li>
              sub_sid - The identifier of an existing session (SID) for the
              subject (player).
            </li>
            <li>
              refresh/id/access_token - Detailed in the following section.
            </li>
          </ul>
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Using Tokens and Verification</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          The Access and ID Tokens are received as the final step of a grant.
          Access Tokens are used for scoped authentication of a client and
          player to a resource, while ID Tokens provide information necessary to
          authenticate a player’s identity. ID Tokens are usually set as a
          cookie in the user’s browser to establish identity between pages and
          services.
          <br />
          <br />
          OAuth Access and ID Tokens are encoded as Signed JSON Web Tokens (JWT)
          to prevent tampering. Additionally, Access Tokens are encrypted and
          cannot be decoded.
          <br />
          <br />
          Refresh Tokens are used to obtain a new Access Token when a given
          Access Token has expired, and have a much longer lifespan than an
          Access Token. The expiration flow of Access Tokens ensures that even
          if one is compromised, it has a very limited life-span.
          <br />
          <br />
          IMPORTANT: Note that Access Tokens are encrypted and cannot be decoded
          or verified.
          <br />
          <br />
          Once complete, a full example provides the following:
          <ul style={{ marginTop: 0 }}>
            <li>A Sign In link to the player</li>
            <li>The link takes the user to OAuth to log in</li>
            <li>
              The player is redirected back to our redirect_uri with an access
              code appended to the URL
            </li>
            <li>
              The Oauth2-Callback route exchanges the access code for tokens
            </li>
            <li>
              A server-to-server request is made to exchange the code for
              Access, Identity, and Refresh Tokens
            </li>
            <li>Riot Games verifies the authenticity of these tokens</li>
          </ul>
          You can now send a player to Riot to authenticate, receive the auth
          code returned. and exchange it for Access, Identity, and Refresh
          tokens.
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Using Refresh Tokens</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          The Refresh Token is issued for the purpose of obtaining new Access
          Tokens when an older one expires. OAuth Refresh tokens are
          self-contained, signed JSON Web Tokens (JWT) that can be inspected and
          validated locally.
        </Typography>
        <Grid container sx={styles.tableContainer}>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="h6" sx={styles.headers}>
                CATEGORY
              </Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="h6" sx={styles.headers}>
                DESCRIPTION
              </Typography>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="body1">Format</Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1">JWT signed</Typography>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="body1">Refreshable</Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1">N/A</Typography>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="body1">Usage</Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1">Obtain a new Access Token</Typography>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="body1">Visibility To Javascript</Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1">No</Typography>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="body1">Visibility to User</Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1">No</Typography>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid md={6}>
              <Typography variant="body1">Visibility to Server</Typography>
            </Grid>
            <Grid md={6}>
              <Typography variant="body1">Yes</Typography>
            </Grid>
          </Box>
        </Grid>
        <Typography variant="body1">
          <span style={{ fontWeight: "bold" }}>Example encrypted token:</span>
          <br /> <br />
          dXJuOnJpb3Q6cGlkOnYxOk1qVXdNalE2UkVWV09R.Z2pyamNvaG8zN3NzbQ.xw96rZeGEmeMtrFlDCGLyA
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Refresh Token Flow</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          Refresh Tokens are only used to obtain a new Access Token, usually
          when the previous one has expired due to time expiration or
          revocation.
          <br /> <br />
          Headers
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Authorization: Basic Z2pyamNvaG8NzbTpPLWpTb
          ... tkcEN6amp13U2ZTOWpjU0w=POST-Data
          <br /> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;grant_type: refresh_token
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;refresh_token: dXJuOnJpb3Q6cGlknYxO
          ...G8zN3NzbQ.xw96rZEmeMtrFlDCGLyA
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;(optional) scope: [ same or narrower
          scope ]
          <br /> <br />
          URI &nbsp;&nbsp;&nbsp;&nbsp;
          <br />{" "}
          <Link
            color={xdpColors.whiteResting}
            href="https://auth.riotgames.com/token"
          >
            https://auth.riotgames.com/token
          </Link>
          <br /> <br />
          Response
          <CodeBox>{codeParser(code7)}</CodeBox>
          When using a Refresh Token, there are two actions OAuth may take. If
          it replies with a new Refresh Token, it must be used in all future
          Access Token refreshes and the previous Refresh Token is now invalid.
          <br /> <br />
          If the same Refresh Token is received in the response, you may
          continue to use it in future refresh requests.
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Accessing the Userinfo Endpoint</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          The UserInfo endpoint is a protected OAuth endpoint where client
          applications can retrieve consented claims, or assertions, about the
          logged in player.
          <br />
          <br />
          The claims are typically packaged in a JSON object where the sub
          member denotes the subject (player) identifier.
          <br />
          <br />
          This is the first endpoint established to support Bearer access tokens
          from Implementing Authorization Codes on the Web.
        </Typography>
      </SectionBody>
      <SectionHead sx={{ marginBottom: "8px" }}>
        <Typography variant="h4">Accessing the Userinfo Endpoint</Typography>
      </SectionHead>
      <SectionBody sx={{ marginBottom: "40px" }}>
        <Typography variant="body1">
          Headers
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;Authorization: Bearer eyJhbGciOiJSUzI ...
          axZMUW2WchZU9fGHM_h61A
          <br />
          <br />
          URI
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            color={xdpColors.whiteResting}
            href="https://auth.riotgames.com/userinfo"
          >
            https://auth.riotgames.com/userinfo
          </Link>
          <br />
          <br />
          Response
        </Typography>
      </SectionBody>
    </Page>
  );
};

export default RiotSignOn;
