const identation = (identationAmount: number) => {
  if (identationAmount === 1) {
    return "    ";
  }
  if (identationAmount === 2) {
    return "        ";
  }
  return "            ";
};

export const code1 = [
  `var express = require('express'),`,
  `${identation(1)}app = express();`,
  "",
  `app.get('/', function(req, res) {`,
  `${identation(1)}res.send("index");`,
  `});`,
  "",
  `app.get('/oauth2-callback', function(req, res) {`,
  `${identation(1)}res.send("callback");`,
  `});`,
  "",
  `var server = app.listen(3000, function () {`,
  `${identation(1)}var host = server.address().address;`,
  `${identation(1)}var port = server.address().port;`,
  "",
  `${identation(
    1
  )}console.log('Example app listening at http://%s:%s', host, port);`,
  `});`,
];

export const code2 = [
  `var appBaseUrl = "http://local.example.com:3000",`,
  `${identation(1)}appCallbackUrl = appBaseUrl + "/oauth2-callback";`,
  "",
  `var provider = "https://auth.riotgames.com",`,
  `${identation(2)}authorizeUrl = provider + "/authorize";`,
  "",
  `var clientID = "oujzg5jiibvzo";`,
  "",
  `app.get('/', function(req, res) {`,
  `${identation(1)}var link = authorizeUrl}`,
  `${identation(2)}+ "?redirect_uri=" + appCallbackUrl`,
  `${identation(2)}+ "&client_id=" + clientID`,
  `${identation(2)}+ "&response_type=code"`,
  `${identation(2)}+ "&scope=openid";"`,
  `${identation(1)}// create a single link, send as an html document`,
  `${identation(1)}res.send('<a href="' + link + '">Sign In</a>');`,
  `});`,
];

export const code3 = [
  `var request = require('request')`,
  "",
  `${identation(1)}var clientAssertion = "your-signed-jwt-here";`,
  "",
  `${identation(1)}var appBaseUrl = "http://local.example.com:3000"`,
  `${identation(2)}appCallbackUrl = appBaseUrl + "/oauth2-callback";`,
  "",
  `${identation(1)}var provider = "https://auth.riotgames.com",`,
  `${identation(2)}authorizeUrl = provider + "/authorize",`,
  `${identation(2)}tokenUrl = provider + "/token";`,
  "",
  `app.get('/oauth2-callback', function(req, res) {`,
  `${identation(1)}var accessCode = req.query.code;`,
  "",
  `${identation(1)}// make server-to-server request to token endpoint`,
  `${identation(1)}// exchange authorization code for tokens`,
  `${identation(2)}request.post({`,
  `${identation(2)}url: tokenUrl,`,
  "",
  `${identation(2)}form: { // post information as x-www-form-urlencoded`,
  `${identation(
    3
  )}client_assertion_type: "urn:ietf:params:oauth:client-assertion-type:jwt-bearer",`,
  `${identation(3)}client_assertion: clientAssertion,`,
  `${identation(3)}grant_type: "authorization_code",`,
  `${identation(
    3
  )}code: accessCode, // accessCode should be url decoded before being set here`,
  `${identation(3)}redirect_uri: appCallbackUrl`,
  `${identation(2)}}`,
  `${identation(1)}}, function (error, response, body) {`,
  `${identation(2)}// do something with the response?`,
  `${identation(1)}});`,
  `});`,
];

export const code4 = [
  `var request = require('request');`,
  "",
  `var clientID = "your-client-id-here",`,
  `${identation(1)}clientSecret = "your-client-secret-here";`,
  "",
  `var appBaseUrl = "http://local.example.com:3000",`,
  `${identation(1)}appCallbackUrl = appBaseUrl + "/oauth2-callback";`,
  "",
  `var provider = "https://auth.riotgames.com",`,
  `${identation(1)}authorizeUrl = provider + "/authorize",`,
  `${identation(1)}tokenUrl = provider + "/token";`,
  "",
  `app.get('/oauth2-callback', function(req, res) {`,
  `${identation(1)}var accessCode = req.query.code;`,
  "",
  `${identation(1)}// make server-to-server request to token endpoint`,
  `${identation(1)}// exchange authorization code for tokens`,
  `${identation(1)}request.post({`,
  `${identation(2)}url: tokenUrl,`,
  `${identation(2)}auth: { // sets "Authorization: Basic ..." header`,
  `${identation(3)}user: clientID,`,
  `${identation(3)}pass: clientSecret`,
  `${identation(2)} },`,
  `${identation(2)} form: { // post information as x-www-form-urlencoded`,
  `${identation(3)} grant_type: "authorization_code",`,
  `${identation(
    3
  )} code: accessCode, // accessCode should be url decoded before being set here`,
  `${identation(3)} redirect_uri: appCallbackUrl`,
  `${identation(2)} }`,
  `${identation(1)}}, function (error, response, body) {`,
  `${identation(2)} // do something with the response?`,
  `${identation(1)} });`,
  `});`,
];

export const code5 = [
  `{`,
  ` "scope":"openid",`,
  ` "expires_in":600,`,
  ` "token_type":"Bearer",`,
  ` "refresh_token":"dXJuOnJpb3Q6cOk1qdNal ... 8zN3NzbQ.xw96rZeGEMtrFlDCGLyA",`,
  ` "id_token":"eyJhbGciJSUzI1mtpZCInMxIn0 ... YiI6InVybjpyaW90OpZDp2MTpNalV",`,
  ` "sub_sid":"vldfsXGdDPoafSKfjS932cslKu8JDUKZ-woZvXDoq8",`,
  ` "access_token":"eyJhbGciOi1NsImZCI6InM ... NTkzMTA3LCJjaWQiJnmE-BVnZbYqY"`,
  `}`,
];

export const code6 = [
  `request.post({`,
  `${identation(1)}. . .`,
  `}, function (error, response, body) {`,
  `${identation(1)}if (!error && response.statusCode == 200) {`,
  `${identation(2)}// parse the response to JSON`,
  `${identation(2)} var payload = JSON.parse(body);`,
  `${identation(2)} // separate the tokens from the entire response body`,
  `${identation(2)} var tokens = {`,
  `${identation(3)} refresh_token: payload.refresh_token,`,
  `${identation(3)} id_token:    payload.id_token,`,
  `${identation(3)} access_token:  payload.access_token`,
  `${identation(2)} };`,
  "",
  `${identation(2)} // legibly print out our tokens`,
  `${identation(
    2
  )} res.send("<pre>" + JSON.stringify(tokens, false, 4) + "</pre>");`,
  `${identation(1)} } else {`,
  `${identation(2)} res.send("/token request failed");`,
  `${identation(1)} }`,
  ` });`,
];

export const code7 = [
  `{`,
  `${identation(1)}"scope":"openid",`,
  `${identation(1)}"expires_in":600,`,
  `${identation(1)}"token_type":"Bearer",`,
  `${identation(
    1
  )}"refresh_token":"dXJuOnJpb3Q6cGlkOn ... amNvaG8zN3NzbQeGEmeMtrFlDCGLyA",`,
  `${identation(
    1
  )}"access_token":"eyJhbGciOiJSUzI1NiI ... sFwkadLmWmwtvJouhX22Tc6vPnfXTk"`,
  `}`,
];
