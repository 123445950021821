import { Backdrop, Box } from "@mui/material";
import React, { memo } from "react";
import { Outlet } from "react-router-dom";
import CheckCookieExpiration from "../../components/CookieValidation/CookieValidation";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import MainContent from "../../components/MainContent/MainContent";
import TopNavigation from "../../components/TopNavigation";
import { AuthStatusEnum } from "../../shared/constants/enums/Auth";
import { useAppSelector } from "../../store/hooks/hooks";
import { selectAuthStatus } from "../../store/selectors/user.selector";

const Layout = memo(() => {
  const authStatus = useAppSelector(selectAuthStatus);
  const authPending = authStatus === AuthStatusEnum.PENDING;

  return (
    <Box sx={{ boder: 0 }}>
      <CheckCookieExpiration>
        <TopNavigation />
        <MainContent>{!authPending && <Outlet />}</MainContent>
      </CheckCookieExpiration>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={authPending}
      >
        <LoadingSpinner />
      </Backdrop>
    </Box>
  );
});

export default Layout;
