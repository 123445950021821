import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import { Navigate } from "react-router-dom";
import { WelcomeStyles as styles } from "./Welcome.styles";
import SimpleHeader from "../../components/SimpleHeader/SimpleHeader";
import {
  PORTAL_NAME,
  PORTAL_VERSION,
} from "../../shared/constants/labels/welcome";
import useWelcome from "./state/useWelcome";
import StyledLink from "../../components/styled/Link/Link";

const Welcome: React.FC = () => {
  const { handleRedirect, redirectLegacyPortal, loginUrl, hasCookie } =
    useWelcome();

  return !hasCookie ? (
    <Navigate to="/home" replace />
  ) : (
    <Grid sx={styles.root}>
      <SimpleHeader
        loginURL={loginUrl}
        externalPortalName={PORTAL_NAME}
        version={PORTAL_VERSION}
      />
      <Grid container sx={styles.page}>
        <Grid container sx={styles.pageBody}>
          <Box sx={styles.content}>
            <Typography variant="h1">
              Submit Your <br />
              App Request
            </Typography>
            <Typography variant="h5" color="white">
              Welcome to the beta of the Riot Developer Portal! This is just the{" "}
              <br />
              beginning of our new and upcoming{" "}
              <StyledLink
                fontSize="18px"
                toUrl="https://developer.riotgames.com"
              >
                developer.riotgames.com
              </StyledLink>{" "}
              relaunch.
              <br /> For now, the focus of this beta site is to enable you to
              request an app.
            </Typography>
            <Typography variant="h5" color="white">
              If you haven&apos;t registered your product yet, continue to
              <br />
              <StyledLink
                fontSize="18px"
                toUrl="https://developer.riotgames.com"
              >
                developer.riotgames.com
              </StyledLink>{" "}
              to submit an application for a <strong>production API key</strong>
              . <br />
              If your product has already been approved with a production key,{" "}
              <br />
              sign in on this page to continue.
            </Typography>
            <Box sx={styles.buttonContainer}>
              <Box sx={styles.boxBtn}>
                <Button
                  sx={styles.developerPortalBtn}
                  onClick={redirectLegacyPortal}
                >
                  Developer Portal
                </Button>
              </Box>

              <Button onClick={handleRedirect} sx={styles.signInBtn}>
                Sign in
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Welcome;
