/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useState } from "react";
import { NavItemDrawer } from "@rdxportal/ui-components";
import { MdArrowOutward } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks/hooks";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import StyledLink from "../styled/Link/Link";
import { createNamedStyles } from "../../shared/CreateNamedStyles";

interface StyledLinkProps {
  iconElement?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  url: string;
  label: string;
  children?: ReactNode;
}

export const StyleLinkElement = ({
  iconElement,
  label,
  url,
  children,
}: StyledLinkProps): JSX.Element => {
  const location = useLocation();
  const isActive = location.pathname.includes(url);
  const isSidebarOpen = useAppSelector((state) => state.app.isSidebarDocsOpen);
  const [isOpen, setIsOpen] = useState(false);
  const isExternal = url.includes("https://");
  const disabled = false;

  useEffect(() => {
    setIsOpen(isActive);
  }, [isActive]);

  const Icon = iconElement;
  return (
    <StyledLink
      toUrl={url}
      className="sidebarLink"
      sx={{ display: "block !important" }}
    >
      <NavItemDrawer
        tabIndex={-1}
        endArrowIconClick={() => setIsOpen(isOpen)}
        sx={{
          width: "300px",
          pointerEvents: disabled ? "none" : "auto",
          "&&&&": {
            backgroundColor: ColorsEnum["#0A0A0A"],
          },
          "&&&& .MuiTypography-root": {
            fontSize: "16px",
            color: ColorsEnum["#C7C7C7"],
            fontWeight: isActive ? 800 : 500,
          },
          "& + .MuiPaper-root .MuiMenuItem-root span": {
            color: ColorsEnum["#C7C7C7"],
          },
          "&&&:hover": {
            "&&&& .MuiTypography-root": {
              color: ColorsEnum["#FCFCFC"],
            },
            "& .MuiListItemIcon-root path": {
              fill: ColorsEnum["#D53235"],
            },
            "& .MuiListItemButton-root > svg": {
              color: `${ColorsEnum["#C7C7C7"]} !important`,
            },
          },
          "&&&& .Mui-focusVisible": {
            ".MuiTypography-root": {
              color: `${ColorsEnum["#C7C7C7"]} !important`,
            },
            ".MuiListItemIcon-root path": {
              fill: ColorsEnum["#D53235"],
            },
            ".MuiListItemButton-root > svg": {
              color: `${ColorsEnum["#C7C7C7"]} !important`,
            },
          },
          "& .MuiListItemButton-root": {
            minHeight: "48px",
            "& > svg": {
              color: `${ColorsEnum["#C7C7C7"]} !important`,
              marginRight: "176px",
            },
          },
          "& .MuiListItemIcon-root path": {
            fill: isActive ? ColorsEnum["#D53235"] : "#737373",
          },
        }}
        startIcon={
          Icon && (
            <Icon
              style={{
                width: 16,
                height: 16,
                marginBottom: "2px",
              }}
            />
          )
        }
        label={label}
        isOpen={isOpen}
        endArrowIcon={!!children && isSidebarOpen}
        customEndIcon={
          isExternal && isSidebarOpen ? (
            <MdArrowOutward size={16} style={{ color: "#575757" }} />
          ) : undefined
        }
        isActive={false}
        collapsed={!isSidebarOpen}
      >
        {children}
      </NavItemDrawer>
    </StyledLink>
  );
};

export const SideBarDocsStyles = createNamedStyles({
  menu: {
    paddingTop: "0 !important",
  },
  accordionSummary: {
    ".Mui-expanded": {
      margin: "0 !important",
    },
    margin: "0 !important",
    padding: 0,
    minHeight: "48px !important",
  },
  accordion: {
    margin: "0 !important",
    padding: 0,
  },
});

export const SideBarDocsIStyles = (
  element: string,
  isActive?: boolean,
  isOpen?: boolean
) => {
  const SideBarStyles: { [key: string]: any } = {
    title: {
      fontWeight: isActive ? 800 : 500,
      color: ColorsEnum["#C7C7C7"],
      display: "flex",
    },
    box: {
      width: isOpen ? "330px" : "0",
      zIndex: 20,
      "& .MuiPaper-root": {
        backgroundColor: ColorsEnum["#0A0A0A"],
        paddingLeft: "0",
        "& div": {
          backgroundColor: ColorsEnum["#0A0A0A"],
        },
      },
    },
  };

  return SideBarStyles[element];
};
