import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import AppReducer from "./reducers/app/app.slice";
import UserReducer from "./reducers/user/user.slice";
import RsoClientReducer from "./reducers/rsoClient/rsoClient.slice";
import DocumentationReducer from "./reducers/documentation/documentation.slice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "refreshToken"],
};

const persistedReducer = persistReducer(persistConfig, UserReducer);

export const store = configureStore({
  reducer: {
    app: AppReducer,
    RsoClientReducer,
    DocumentationReducer,
    userReducer: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
