/* eslint-disable @typescript-eslint/no-explicit-any */
interface IUseSorting<R> {
  order: string;
  rows: R[];
  orderBy: keyof R;
}

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
  const dateFormatRegex =
    /^\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}:\d{2} (AM|PM)$/;

  const compareValues = (valueA: any, valueB: any): number => {
    if (typeof valueA !== "number" && typeof valueB !== "number") {
      if (!dateFormatRegex.test(valueA)) {
        if (valueB < valueA) {
          return -1;
        }
        if (valueB > valueA) {
          return 1;
        }
        return 0;
      }
    }

    const dateA = new Date(valueA).getTime();
    const dateB = new Date(valueB).getTime();

    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  };

  return compareValues(a[orderBy], b[orderBy]);
};

const sortingAscDesc = <R,>({ order, rows, orderBy }: IUseSorting<R>) => {
  if (order === "desc") {
    const sortedDesc = rows
      .slice()
      .sort((a, b) => descendingComparator(a, b, orderBy));
    return sortedDesc;
  }
  const sortedAsc = rows
    .slice()
    .sort((a, b) => descendingComparator(b, a, orderBy));
  return sortedAsc;
};

export default sortingAscDesc;
