import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";
import { defaultTo, isArray, isEmpty } from "lodash";
import { useFormState } from "react-hook-form";
import {
  IUseCreateRsoClientForm,
  IUseCreateRsoClientFormProps,
} from "./useCreateRsoClientForm.interface";
import { getDefaultFieldsForClientType } from "../../../shared/utils/RsoClientMapping";
import {
  useRsoForm,
  translateError,
  transformInitialData,
  getFieldError,
} from "../../../shared/utils/ValidateForm";
import { FormInput } from "../../../types/clientForm";
import { ClientRequestData, gamesAccessValues } from "../../../types/clients";

const useCreateRsoClientForm = ({
  clientType,
  error,
  env,
  initialData,
  onSave,
  resetError,
}: IUseCreateRsoClientFormProps): IUseCreateRsoClientForm => {
  const history = useNavigate();
  const { product } = useParams<"product">();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useRsoForm(defaultTo(clientType, ""), initialData);
  const { dirtyFields, isValid, isSubmitting } = useFormState({
    control,
  });

  const deleteGameAccessValue = (gameAccessToDelete: string) => {
    setValue(
      "gamesAccess",
      getValues("gamesAccess").filter((game) => game !== gameAccessToDelete),
      { shouldDirty: true }
    );
    trigger("gamesAccess");
  };
  const [redirect, setRedirect] = useState(false);

  const clientTypeProperties = getDefaultFieldsForClientType(
    defaultTo(clientType, "")
  );
  const globalError = translateError(error);
  const isEditing = !!initialData;

  useDeepCompareEffect(() => {
    if (initialData && !isEmpty(dirtyFields)) {
      reset(transformInitialData(initialData));
    }
  }, [initialData, dirtyFields]);

  const getNewData = (
    dirtyFieldsObj: Record<string, boolean | boolean[]>,
    newData: Record<string, string | string[] | undefined>
  ) => {
    const changes = Object.keys(dirtyFieldsObj).reduce((acc, next) => {
      let data = newData[next];
      if (isArray(data)) {
        data = data.filter(Boolean);
      }
      return { ...acc, [next]: data };
    }, {});
    return changes;
  };

  const onSubmit = (input: FormInput) => {
    if (initialData) {
      const updatedData = getNewData(dirtyFields, input);
      setRedirect(false);
      return onSave(updatedData as ClientRequestData);
    }
    const parseGameAccess = input.gamesAccess.map(
      (game) => gamesAccessValues[game as keyof typeof gamesAccessValues]
    );
    const data = {
      appId: input.appId,
      contactEmail: clientTypeProperties.defaultFields.contacts,
      product: product!,
      applicationType: "",
      clientName: input.clientName,
      environment: env,
      redirectUris: input.redirectUris
        ? input.redirectUris.filter((uri: string) => uri !== "")
        : [],
      gameAccess: parseGameAccess || [],
      tosUri: input.tosUri || "",
      policyUri: input.privacyPolicyUri || "",
      base64Logo: input.companyLogo || "",
      postLogoutRedirectUris: input.postLogoutRedirectUris
        ? input.postLogoutRedirectUris.filter((uri: string) => uri !== "")
        : [],
    };

    setRedirect(false);
    return onSave(data);
  };

  useEffect(() => {
    const subscription = watch(() => {
      if (globalError) {
        resetError();
      }
    });
    return () => subscription.unsubscribe();
  }, [resetError, globalError, watch]);

  useEffect(() => {
    if (redirect) history("client-view");
  }, [redirect]);

  const useCreateRsoClientFormValues: IUseCreateRsoClientForm = {
    control,
    handleSubmit,
    errors,
    setValue,
    isEditing,
    onSubmit,
    clientTypeProperties,
    globalError,
    dirtyFields,
    getFieldError,
    deleteGameAccessValue,
    trigger,
    isValid,
    setError,
    isSubmitting,
    clearErrors,
  };

  return useCreateRsoClientFormValues;
};

export default useCreateRsoClientForm;
