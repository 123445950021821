import { createContext, useContext } from "react";
import { IconType } from "react-icons";
import {
  MdBuild,
  MdBuildCircle,
  MdStream,
  MdSportsEsports,
  MdConstruction,
} from "react-icons/md";

export enum Environments {
  DEV = "dev",
  STAGE = "stage",
  PARTNER1 = "partner1",
  ESPORTS = "esports",
  PROD = "prod",
}

export const EnvironmentNone = "none";

const EnvironmentColor = {
  [Environments.DEV]: "affirmation",
  [Environments.STAGE]: "alert",
  [Environments.PARTNER1]: "blue.500",
  [Environments.ESPORTS]: "purple.500",
  [Environments.PROD]: "riotRed",
  [typeof EnvironmentNone]: "offBlack",
};

const EnvironmentName = {
  [Environments.DEV]: "dev",
  [Environments.STAGE]: "stage",
  [Environments.PARTNER1]: "partner1",
  [Environments.ESPORTS]: "esports",
  [Environments.PROD]: "prod",
  [typeof EnvironmentNone]: "-",
};

const EnvironmentIcon = {
  [Environments.DEV]: MdConstruction,
  [Environments.STAGE]: MdBuild,
  [Environments.PARTNER1]: MdBuildCircle,
  [Environments.ESPORTS]: MdSportsEsports,
  [Environments.PROD]: MdStream,
};

export const portalRSOEnvMapping: { [portalEnv: string]: Environments[] } = {
  sandbox: [Environments.DEV, Environments.STAGE, Environments.PARTNER1],
  local: [Environments.DEV, Environments.STAGE, Environments.PARTNER1],
  test: [Environments.DEV, Environments.STAGE, Environments.PARTNER1],
  prelive: [
    Environments.DEV,
    Environments.STAGE,
    Environments.PARTNER1,
    Environments.ESPORTS,
    Environments.PROD,
  ],
  // TODO: We temporarily removed prod from live - undo this change when ready
  live: [Environments.STAGE, Environments.PARTNER1, Environments.ESPORTS],
};

export const pickEnvironmentColor = (
  environment: Environments | typeof EnvironmentNone
): string => EnvironmentColor[environment];

export const pickEnvironmentName = (
  environment: Environments | typeof EnvironmentNone
): string => EnvironmentName[environment];

export const pickEnvironmentIcon = (environment: Environments): IconType =>
  EnvironmentIcon[environment];

export const EnvironmentContext = createContext({
  environment: Environments.DEV,
  setEnvironment: (environment: Environments, product: string) => {
    console.error("Environment context not set", environment, product);
  },
});

export function isRSOEnvironmentEnabled(
  portalEnv: string,
  rsoEnv: Environments
) {
  return portalRSOEnvMapping[portalEnv].includes(rsoEnv);
}

export function getEnabledRSOEnvironments(portalEnv: string) {
  return portalRSOEnvMapping[portalEnv] ? portalRSOEnvMapping[portalEnv] : [];
}

export const useEnvironment = () => useContext(EnvironmentContext);
