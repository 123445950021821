import React from "react";
import { Grid } from "@mui/material";
import { MdCheckCircle } from "react-icons/md";
import SectionUpdatedNotification from "./SectionUpdatedNotification";

const SectionUpdateNotificationRow = () => (
  <Grid
    container
    justifyContent="end"
    marginTop="-32px"
    marginBottom="32px"
    sx={{
      "&:last-of-type": {
        marginBottom: 0,
      },
    }}
  >
    <SectionUpdatedNotification
      iconElement={<MdCheckCircle fill="#4caf50" fontSize="24px" />}
      message="Field Updated"
    />
  </Grid>
);

export default SectionUpdateNotificationRow;
