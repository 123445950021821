/* eslint-disable prefer-template */
import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const StyledModalStyles = createNamedStyles({
  dialogContainer: {
    borderRadius: "8px",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 0,
  },
  dialogActions: {
    padding: 0,
    backgroundColor: "rgba(128, 128, 128, 0.1) !important",
    justifyContent: "center",
  },
  boxContainer: {
    display: "flex",
    marginLeft: "auto",
    margin: "0 56px 0 56px",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  title: {
    marginTop: "24px",
  },
});
