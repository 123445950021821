import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { PrivateApi } from "../../../shared/utils/KyApi";
import {
  ClientRequestData,
  CreateClientRequest,
  gamesAccessValues,
} from "../../../types/clients";

export const createRsoClient = createAsyncThunk<
  ClientRequestData,
  ClientRequestData
>("/api/xdp/v1/rso-client", async (payload, { rejectWithValue }) => {
  const sanitizedRequest: CreateClientRequest = {
    app_id: get(payload, "appId", ""),
    client_name: get(payload, "clientName", ""),
    contacts: get(payload, "contactEmail", []),
    games: get(payload, "gameAccess", []),
    logo: get(payload, "base64Logo", ""),
    policy_uri: get(payload, "policyUri", ""),
    tos_uri: get(payload, "tosUri", ""),
    redirect_uris: get(payload, "redirectUris", []),
    post_logout_redirect_uris: get(payload, "postLogoutRedirectUris", []),
  };
  try {
    await PrivateApi()
      .post("api/xdp/v1/rso-client", {
        json: sanitizedRequest,
      })
      .json()
      .catch((error) => {
        throw error.response;
      });
    return payload;
  } catch (error) {
    const errorCode = get(error, "status", "");
    const resultError = await (error as Response).json();

    return rejectWithValue({
      message: resultError.message,
      code: errorCode,
    });
  }
});

const formFieldsToApiFields = {
  appId: "app_id",
  clientName: "client_name",
  contactEmail: "contacts",
  gameAccess: "games",
  gamesAccess: "games",
  base64Logo: "logo",
  privacyPolicyUri: "policy_uri",
  tosUri: "tos_uri",
  redirectUris: "redirect_uris",
  postLogoutRedirectUris: "post_logout_redirect_uris",
  companyLogo: "logo",
};

const mapFormFieldsToApiFields = (payload: Partial<ClientRequestData>) => {
  return Object.entries(payload).reduce((acc, [k, v]) => {
    if (!Object.prototype.hasOwnProperty.call(formFieldsToApiFields, k)) {
      return acc;
    }
    return {
      ...acc,
      [formFieldsToApiFields[k as keyof typeof formFieldsToApiFields]]: v,
    };
  }, {});
};
export const patchRsoClient = createAsyncThunk<
  Partial<ClientRequestData>,
  Partial<ClientRequestData> & {
    appUuid: string;
  }
>("/api/xdp/v1/apps/appId", async (payload, { rejectWithValue }) => {
  try {
    payload.gameAccess = payload.gamesAccess?.map(
      (game) => gamesAccessValues[game as keyof typeof gamesAccessValues]
    );

    await PrivateApi()
      .patch(`api/xdp/v1/apps/${payload.appUuid}`, {
        json: { rso_client: mapFormFieldsToApiFields(payload) },
      })
      .json()
      .catch((error) => {
        throw error.response;
      });
    return payload;
  } catch (error) {
    const errorCode = get(error, "status", "");
    const resultError = await (error as Response).json();

    return rejectWithValue({
      message: resultError.message,
      code: errorCode,
    });
  }
});
