import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoReloadSharp } from "react-icons/io5";
import StyledInput from "../../components/StyledInput/StyledInput";
import usePrivateKeyJWT from "./state/useSecretBasic";
import { SecretBasicStyles as styles } from "./SecretBasic.styles";
import { GenerateSecretBasic } from "../../components/GenerateSecretBasic/GenerateSecretBasic";
import { CreateSecretBasicSuccess } from "../../components/CreateSecretBasicSuccess/CreateSecretBasicSuccess";

export const SecretBasic: React.FC = () => {
  const {
    showSecretSuccess,
    showGenerateKeyModal,
    clientSecretHash,
    handleCloseSecretSuccess,
    handleCreateKey,
    handleInitKey,
  } = usePrivateKeyJWT();

  return (
    <>
      <Grid item mt={2} mb="32px" alignContent="flex-start">
        <Grid width="100%">
          <Grid marginTop={3}>
            <Typography variant="h6" lineHeight="22.4px">
              Client Auth Method
            </Typography>
          </Grid>
          <Grid mb={2}>
            <Typography variant="body2" fontWeight={500}>
              How your OAuth client proves its identity.
            </Typography>
          </Grid>
          <StyledInput
            sx={styles.input}
            fullWidth
            disabled
            value="Client Secret Basic"
          />
        </Grid>
      </Grid>
      <Grid item mt={2} mb="32px" alignContent="flex-start">
        <Grid width="100%">
          <Grid marginTop={3}>
            <Typography variant="h6" lineHeight="22.4px">
              Client Secret Hash
            </Typography>
          </Grid>
          <Grid mb={2} display="flex" alignItems="center">
            <AiOutlineInfoCircle color="#FAB514" />
            <Typography variant="body2" fontWeight={500}>
              &nbsp;This isn&apos;t your client secret! Rotate secret to replace
              the current one.
            </Typography>
          </Grid>
          <StyledInput
            sx={styles.input}
            fullWidth
            disabled
            value={clientSecretHash}
            placeholder="hash"
          />
        </Grid>
      </Grid>
      <Grid item mt="32px" alignContent="flex-start">
        <Button
          sx={styles.button}
          variant="red"
          size="medium"
          onClick={() => handleCreateKey(true)}
          data-testid="button-rotate-secrets"
        >
          <IoReloadSharp color="white" />
          &nbsp;Rotate Secrets
        </Button>
      </Grid>
      <GenerateSecretBasic
        openDialog={showGenerateKeyModal}
        onClose={() => handleCreateKey(false)}
        confirmGenerate={handleInitKey}
      />
      <CreateSecretBasicSuccess
        showCreateSuccess={showSecretSuccess}
        onClose={handleCloseSecretSuccess}
      />
    </>
  );
};
