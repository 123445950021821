import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { CreateJWKSuccessStyles as styles } from "./CreateSecretBasicSuccess.styles";
import { useAppSelector } from "../../store/hooks/hooks";
import {
  selectClientSecret,
  selectIsSecretBasicLoading,
} from "../../store/selectors/app.selector";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { CopyInput } from "../CopyInput/CopyInput";

export type CreateSecretSuccessProps = {
  showCreateSuccess: boolean;
  onClose: () => void;
};

export const CreateSecretBasicSuccess: React.FC<CreateSecretSuccessProps> = ({
  showCreateSuccess,
  onClose,
}) => {
  const isLoading = useAppSelector(selectIsSecretBasicLoading);
  const generatedSecretBasic = useAppSelector(selectClientSecret);

  return (
    <Dialog open={showCreateSuccess} sx={styles.dialog}>
      {!isLoading ? (
        <>
          <DialogTitle sx={styles.box}>
            <Typography variant="modalsTitle">Success!</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container flexDirection="column" px="20px">
              <Box sx={styles.box}>
                <Typography variant="modalsContent" lineHeight="22.4px">
                  Make sure to copy and paste each token and key before closing
                  this window.
                </Typography>
              </Box>
              <Grid container marginTop={1} flexDirection="column" rowGap={2}>
                <Grid item>
                  <Typography variant="subtitle1" my="8px" lineHeight="19.6px">
                    Client Secret
                  </Typography>
                  <Typography mb="8px" variant="subtitle2" lineHeight="22.4px">
                    A secret used to prove your client&apos;s identity, like a
                    password
                  </Typography>
                  <Grid>
                    <CopyInput
                      testId="button-client-secret"
                      data={generatedSecretBasic}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={styles.actions}>
            <Button
              variant="white"
              onClick={onClose}
              size="small"
              sx={styles.button}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent
          sx={{
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </DialogContent>
      )}
    </Dialog>
  );
};
