import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import { SectionStyles as styles } from "./Section.styles";

type SectionStylesProps = {
  children: ReactNode;
};

const SectionStyles = ({ children }: SectionStylesProps) => {
  return (
    <Grid item xs={12} sx={styles.root}>
      {children}
    </Grid>
  );
};

export default SectionStyles;
