import { TableCell, TableSortLabel } from "@mui/material";
import { styled } from "@mui/system";
import { ColorsEnum, xdpColors } from "../../shared/constants/enums/Colors";
import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const SecretsRotationTableStyles = createNamedStyles({
  box: {
    margin: "32px 0px 0px 16px",
  },
  paper: {
    width: "100%",
    backgroundColor: xdpColors.grey,
  },
  tableHead: {
    padding: "24px 0px 0px 20px",
    marginBottom: "36px",
  },
  headers: { "&:last-child td, &:last-child th": { border: 0 } },
  table: {
    tableLayout: "fixed",
  },
  row: {
    fontWeight: "400 !important",
    color: `${ColorsEnum["#C7C7C7"]} !important`,
  },
  img: {
    height: "50px",
    width: "45px",
  },
  tableSort: {
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&:active": {
        color: "white",
      },
    },
  },
  idText: {
    width: "200px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginRight: 2,
  },
});
export const StyledAppTableHeader = styled(TableCell)(() => ({
  color: `${ColorsEnum["#FCFCFC"]} !important`,
  paddingLeft: "30px",
  borderBottom: `0.5px solid ${xdpColors.tableBorder}`,
  fontSize: "12px",
  fontWeight: 800,
  lineHeight: "140%",
  "& .Mui-active": {
    color: "white !important",
    opacity: 1,
    "&:focus": {
      color: `${ColorsEnum["#FCFCFC"]} !important`,
      opacity: 0.8,
      outline: "none",
      backgroundColor: "rgb(199, 199, 199, 0.2)",
      width: "350px",
      height: "25px",
      borderRadius: "5px",
    },
  },
}));

export const StyledAppTableRows = styled(TableCell)(() => ({
  color: `${ColorsEnum["#FCFCFC"]} !important`,
  paddingLeft: "30px",
  borderBottom: `0.5px solid ${xdpColors.tableBorder}`,
  "& .Mui-active": {
    color: "white !important",
    opacity: 1,
  },
}));

export const StyledAppTableCell = styled(TableCell)(() => ({
  color: `${ColorsEnum["#FCFCFC"]} !important`,
  paddingLeft: "30px",
  borderBottom: `0.5px solid ${xdpColors.tableBorder}`,
  display: "flex",
}));

export const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  "&:hover": {
    color: "white",
    opacity: 0.5,
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important",
    opacity: 0.5,
  },
  "&:focus": {
    color: `${ColorsEnum["#FCFCFC"]} !important`,
    opacity: 0.8,
    outline: "none",
    backgroundColor: "rgb(199, 199, 199, 0.2)",
    width: "350px",
    height: "25px",
    borderRadius: "5px",
  },
}));
