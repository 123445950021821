import { useEffect } from "react";
import {
  appsPendingHeaders,
  appsApprovedHeaders,
  AppsStatus,
} from "../../../shared/constants/enums/Apps";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  selectApps,
  selectLoading,
} from "../../../store/selectors/app.selector";
import { getApps } from "../../../store/thunks/app/app.thunks";
import { AppsTableData, AppTableHeaders } from "../../../types/apps";

interface IAppsManagement {
  apps: AppsTableData;
  pendingHeaders: AppTableHeaders;
  approvedHeaders: AppTableHeaders;
  isLoading: boolean;
  pendingApps: AppsTableData;
  approvedApps: AppsTableData;
}

const useAppsManagement = (): IAppsManagement => {
  const dispatch = useAppDispatch();
  const apps = useAppSelector(selectApps);
  const isLoading = useAppSelector(selectLoading);
  const pendingHeaders = appsPendingHeaders;
  const approvedHeaders = appsApprovedHeaders;

  useEffect(() => {
    dispatch(getApps());
  }, []);

  const pendingApps = apps.filter((app) => app.status === AppsStatus.PENDING);
  const approvedApps = apps.filter((app) => app.status === AppsStatus.APPROVED);

  const useRsoRequestClientValues: IAppsManagement = {
    apps,
    pendingHeaders,
    approvedHeaders,
    isLoading,
    pendingApps,
    approvedApps,
  };

  return useRsoRequestClientValues;
};

export default useAppsManagement;
