import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { AppDataResponse, AppsTableData } from "../../../types/apps";
import { AppDetailsRequest, AppDetailsResponse } from "../../../types/auth";
import {
  DeleteJWKRequest,
  DeleteJWKResponse,
  GenerateJWKRequest,
  GenerateJWKResponse,
  GenerateSecretBasicRequest,
  GenerateSecretBasicResponse,
  GetJWKRequest,
  GetJWKsResponse,
} from "../../../types/secretsRotation";
import { PrivateApi } from "../../../shared/utils/KyApi";

export const getAppDetails = createAsyncThunk<
  AppDetailsResponse,
  AppDetailsRequest
>("/api/xdp/v1/apps/", async (payload, { rejectWithValue }) => {
  try {
    const response = await PrivateApi()
      .get(`api/xdp/v1/apps/${payload.appId}`)
      .json<AppDetailsResponse>()
      .catch((error) => {
        throw error.response;
      });
    return response;
  } catch (error) {
    return rejectWithValue({
      message: get(error, "statusText", ""),
      code: get(error, "status", ""),
    });
  }
});

export const getJWKs = createAsyncThunk<GetJWKsResponse, GetJWKRequest>(
  "/api/xdp/v1/apps/get/secrets",
  async (payload, { rejectWithValue }) => {
    try {
      const getJWKResponse = await PrivateApi()
        .get(`api/xdp/v1/apps/${payload.appId}/secrets`)
        .json<GetJWKsResponse>()
        .catch((error) => {
          throw error.response;
        });

      return getJWKResponse;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);

export const generateJWK = createAsyncThunk<
  GenerateJWKResponse,
  GenerateJWKRequest
>("/api/xdp/v1/apps/generate/secrets", async (payload, { rejectWithValue }) => {
  try {
    const generatedJWKResponse = await PrivateApi()
      .post(`api/xdp/v1/apps/${payload.appId}/secrets`)
      .json<GenerateJWKResponse>()
      .catch((error) => {
        throw error.response;
      });

    return generatedJWKResponse;
  } catch (error) {
    return rejectWithValue({
      message: get(error, "statusText", ""),
      code: get(error, "status", ""),
    });
  }
});

export const deleteJWK = createAsyncThunk<DeleteJWKResponse, DeleteJWKRequest>(
  "/api/xdp/v1/apps/delete/secrets",
  async (payload, { rejectWithValue }) => {
    try {
      const deleteJWKResponse = await PrivateApi()
        .delete(`api/xdp/v1/apps/${payload.appId}/secrets/${payload.kid}`)
        .json<DeleteJWKResponse>()
        .catch((error) => {
          throw error.response;
        });

      return deleteJWKResponse;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);

export const getApps = createAsyncThunk<AppsTableData>(
  "/api/xdp/v1/apps",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await PrivateApi()
        .get(`api/xdp/v1/apps`, {
          credentials: "include",
        })
        .json<AppDataResponse>()
        .catch((error) => {
          throw error.response;
        });
      const parsedResult = response.apps.map((data) => {
        return {
          appName: data.app.name,
          oAuthClientId: data.app.rso_client_id,
          status: data.app.status,
          role: data.user_membership.role.toLowerCase(),
          appId: data.app.app_uuid,
        };
      });
      return parsedResult;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);

export const rotateSecretBasic = createAsyncThunk<
  GenerateSecretBasicResponse,
  GenerateSecretBasicRequest
>("/api/xdp/v1/apps/rotate/secrets", async (payload, { rejectWithValue }) => {
  try {
    const generatedSecretBasicResponse = await PrivateApi()
      .post(`api/xdp/v1/apps/${payload.appId}/rotate-secret`)
      .json<GenerateSecretBasicResponse>()
      .catch((error) => {
        throw error.response;
      });

    return generatedSecretBasicResponse;
  } catch (error) {
    return rejectWithValue({
      message: get(error, "statusText", ""),
      code: get(error, "status", ""),
    });
  }
});
