import { xdpColors } from "./themeConstants";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    red: true;
    white: true;
    black: true;
    grey: true;
    customWhite: true;
  }
}

export const buttonVariants = [
  {
    props: { variant: "red" },
    style: {
      color: xdpColors.whiteHover,
      backgroundColor: xdpColors.redResting,
      "&:hover": {
        backgroundColor: xdpColors.redHover,
      },
      "&:disabled": {
        backgroundColor: xdpColors.shadowGrey,
        color: xdpColors.shadowGreyDisable,
      },
    },
  },
  {
    props: { variant: "white" },
    style: {
      color: xdpColors.blackActive,
      backgroundColor: xdpColors.whiteResting,
      "&:hover": {
        backgroundColor: xdpColors.whiteHover,
      },
    },
  },
  {
    props: { variant: "black" },
    style: {
      color: xdpColors.whiteHover,
      backgroundColor: xdpColors.blackActive,
      "&:hover": {
        backgroundColor: xdpColors.blackResting,
      },
    },
  },
  {
    props: { variant: "grey" },
    style: {
      color: xdpColors.white,
      // replace with color + opacity
      backgroundColor: "rgba(128, 128, 128, 0.3) !important",
      "&:hover": {
        backgroundColor: xdpColors.backgroundGrey,
      },
    },
  },
  {
    props: { variant: "customWhite" },
    style: {
      padding: "4px 16px 4px 16px !important",
      fontSize: "14px",
      fontWeight: 800,
      color: xdpColors.blackActive,
      backgroundColor: xdpColors.whiteResting,
      "&:hover": {
        backgroundColor: xdpColors.whiteHover,
      },
    },
  },
];
