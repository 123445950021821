import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Grid, Button } from "@mui/material";
import { MdAdd } from "react-icons/md";
import Page from "../../components/styled/Content/Page";
import { AppsStyles as styles } from "./Apps.styles";
import StyledLink from "../../components/styled/Link/Link";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import PendingApprovalsBanner from "../../components/PendingApprovalsBanner/PendingApprovalsBanner";

const Apps: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const showButton = location.pathname === "/apps";

  return (
    <Page>
      <PendingApprovalsBanner />
      {!showButton && (
        <StyledLink
          styles={{
            fontSize: "12px",
            fontWeight: "bold",
            margin: "0 0 16px",
          }}
          textDecoration="none"
          customColor={ColorsEnum["#C7C7C7"]}
          toUrl="/apps"
        >{`< APPS`}</StyledLink>
      )}
      <Grid sx={styles.grid}>
        {showButton && (
          <>
            <Box width="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h2">Apps</Typography>
                <Button
                  sx={styles.button}
                  variant="white"
                  size="medium"
                  onClick={() => navigate("/apps/new")}
                >
                  <MdAdd />
                  &nbsp;Request New App
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Grid>
      <Outlet />
    </Page>
  );
};

export default Apps;
