import { SecretsRotationTableHeaders } from "../../../types/secretsRotation";

export const secretsRotationHeaders: SecretsRotationTableHeaders = [
  {
    label: "KEY ID",
    value: "kid",
  },
  {
    label: "DATE CREATED",
    value: "created_at",
  },
];

export enum DeleteKeyStatusEnum {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
  IDLE = "IDLE",
}
