/* eslint-disable prefer-template */
import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const UploadImageStyles = createNamedStyles({
  root: {},
  boxContainer: {
    marginTop: "20px",
    width: "100%",
  },
  boxButton: {
    display: "block",
    backgroundColor: ColorsEnum["#0A0A0A"],
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: `2px dashed rgba(128, 128, 128, 0.3)`,
    borderRadius: "4px 4px 0px 0px",
  },
  error: {
    borderColor: "#E15241",
  },
  boxIcon: {
    width: "32px",
    height: "32px",
    margin: "31px auto 16px auto",
    display: "block",
  },
  boxBtnText: {
    py: "20px",
    px: "35px",
  },
  BtnTextTagColor: {
    fontSize: "14px !important",
  },
  boxImg: {
    display: "flex",
    my: "10px",
    marginLeft: "12px",
    "& img": {
      minHeight: "60px",
      minWidth: "60px",
      maxHeight: "275px",
      maxWidth: "275px",
    },
  },
  imgContainer: {
    display: "flex",
    minWidth: "300px",
    flexDirection: "column",
  },
  boxImgBtns: {
    display: "flex",
    justifyContent: "flex-start",
  },
  image: {
    backgroundColor: ColorsEnum["#0A0A0A"],
    display: "block",
    padding: "0 !important",
    width: "100%",
    margin: "0 !important",
    color: ColorsEnum["#2561bb"],
  },
  replaceTxt: {
    color: "#1F1F1F !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    textTransform: "none !important",
  },
  buttonDelete: {
    marginLeft: "12px",
    marginRight: "24px",
    padding: "0 36px 0 36px",
    backgroundColor: ColorsEnum["#1F1F1F"],
    color: ColorsEnum["#808080"],
    borderRadious: "6px",
    border: `2px solid ${ColorsEnum["#808080"]}`,
  },
  buttonReplace: {
    marginRight: "auto",
    padding: "0 36px 0 36px",
  },
});
