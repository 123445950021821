export const CookieValidator = (): boolean => {
  const cookies = document.cookie.split(";");
  let isExpired = true;

  cookies.forEach((cookie) => {
    const cookieValue = cookie.trim();
    if (cookieValue.startsWith(`${"_rg_ess_jwt"}=`)) {
      isExpired = false;
    }
  });

  return isExpired;
};
