import { ColorsEnum } from "../../../shared/constants/enums/Colors";
import { IStyles } from "../../../shared/CreateNamedStyles";

export const PageContentStyles = () => {
  const gridStyles: IStyles = {
    root: {
      backgroundColor: ColorsEnum["#0A0A0A"],
      zIndex: 100,
      minWidth: "500px",
      paddingTop: "128px",
      alignItems: "center",
      display: "block",
      flexShrink: 0,
      justifyContent: "center",
      flexDirection: "column",
    },
  };
  return gridStyles.root;
};
