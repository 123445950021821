import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  getAppDetails,
  getJWKs,
  generateJWK,
  getApps,
  deleteJWK,
  rotateSecretBasic,
} from "../../thunks/app/app.thunks";
import { DeleteKeyStatusEnum } from "../../../shared/constants/enums/SecretsRotation";
import { AppDetailsLoadingStatusEnum } from "../../../shared/constants/enums/Apps";
import { AppsTableData } from "../../../types/apps";
import { AppDetailsResponse } from "../../../types/auth";
import { SecretRotation } from "../../../types/secretsRotation";

export interface IAppState {
  appError: boolean;
  appErrorCode: string;
  appDetails: AppDetailsResponse;
  appDetailsLoadingStatus: AppDetailsLoadingStatusEnum;
  apps: AppsTableData;
  isSidebarOpen: boolean;
  isSidebarDocsOpen: boolean;
  loading: boolean;
  secretRotation: SecretRotation;
}

const initialState: IAppState = {
  appError: false,
  appErrorCode: "",
  appDetails: {
    app: {
      app_uuid: "",
      rso_client_id: "",
      games: [],
      legacy_app_id: "",
      status: "",
      name: "",
      members: [
        {
          puuid: "",
          role: "",
          created_by_puuid: "",
          created_at: "",
          updated_at: "",
        },
      ],
      has_pending_approvals: false,
      created_at: "",
      updated_at: "",
    },
    rso_client: {
      client_id: "",
      client_name: "",
      application_type: "",
      contacts: [""],
      grant_types: [""],
      token_endpoint_auth_method: "",
      public_jwk: "",
      scope: "",
      redirect_uris: [""],
      client_secret: "",
      logo_uri: "",
      policy_uri: "",
      registration_client_uri: "",
      post_logout_redirect_uris: [""],
      tos_uri: "",
      preferred_client_id: "",
      data: {
        template: "",
        third_party: false,
      },
    },
    user_membership: {
      created_at: "",
      created_by_puuid: "",
      puuid: "",
      role: "",
      updated_at: "",
    },
  },
  appDetailsLoadingStatus: AppDetailsLoadingStatusEnum.IDLE,
  isSidebarOpen: false,
  isSidebarDocsOpen: false,
  secretRotation: {
    isGetJWKLoading: false,
    isJWKLoading: false,
    isSecretBasicLoading: false,
    isDeleteJWKLoading: DeleteKeyStatusEnum.IDLE,
    generatedJWK: {},
    JWKs: [],
    kid: "",
    clientSecret: "",
  },
  loading: false,
  apps: [],
};

export const appDataSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getAppDetails.pending, (state) => {
        state.loading = true;
        state.appDetailsLoadingStatus = AppDetailsLoadingStatusEnum.PENDING;
      })
      .addCase(getAppDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.appDetails = payload;
        state.appDetailsLoadingStatus = AppDetailsLoadingStatusEnum.SUCCESS;
      })
      .addCase(getAppDetails.rejected, (state) => {
        state.loading = false;
        state.appDetailsLoadingStatus = AppDetailsLoadingStatusEnum.FAILED;
      })
      .addCase(getJWKs.rejected, (state, { payload }) => {
        const errorCode = get(payload, "code", "").toString();
        if (errorCode) {
          state.appErrorCode = errorCode;
          state.appError = true;
        }
        state.secretRotation.isGetJWKLoading = false;
      })
      .addCase(getJWKs.pending, (state) => {
        state.secretRotation.isGetJWKLoading = true;
        state.secretRotation.JWKs = [];
      })
      .addCase(getJWKs.fulfilled, (state, { payload }) => {
        state.secretRotation.isGetJWKLoading = false;
        state.secretRotation.JWKs = payload.keys;
      })
      .addCase(generateJWK.pending, (state) => {
        state.secretRotation.isJWKLoading = true;
      })
      .addCase(generateJWK.rejected, (state, { payload }) => {
        const errorCode = get(payload, "code", "").toString();
        if (errorCode) {
          state.appErrorCode = errorCode;
          state.appError = true;
        }
        state.secretRotation.isJWKLoading = false;
      })
      .addCase(generateJWK.fulfilled, (state, { payload }) => {
        state.secretRotation.generatedJWK = payload;
        state.secretRotation.isJWKLoading = false;
      })
      .addCase(getApps.fulfilled, (state, { payload }) => {
        state.apps = payload;
        state.loading = false;
      })
      .addCase(getApps.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApps.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteJWK.pending, (state) => {
        state.secretRotation.isDeleteJWKLoading = DeleteKeyStatusEnum.PENDING;
      })
      .addCase(deleteJWK.rejected, (state, { payload }) => {
        const errorCode = get(payload, "code", "").toString();
        if (errorCode) {
          state.appErrorCode = errorCode;
          state.appError = true;
        }
        state.secretRotation.isDeleteJWKLoading = DeleteKeyStatusEnum.FAILED;
      })
      .addCase(deleteJWK.fulfilled, (state) => {
        state.secretRotation.isDeleteJWKLoading = DeleteKeyStatusEnum.SUCCESS;
      })
      .addCase(rotateSecretBasic.pending, (state) => {
        state.secretRotation.isSecretBasicLoading = true;
      })
      .addCase(rotateSecretBasic.rejected, (state, { payload }) => {
        state.secretRotation.isSecretBasicLoading = false;
        const errorCode = get(payload, "code", "").toString();
        if (errorCode) {
          state.appErrorCode = errorCode;
          state.appError = true;
        }
      })
      .addCase(rotateSecretBasic.fulfilled, (state, { payload }) => {
        state.secretRotation.isSecretBasicLoading = false;
        state.secretRotation.clientSecret = get(payload, "client_secret", "");
      });
  },
  initialState,
  name: "app",
  reducers: {
    setIsSidebarOpen(state, action: { payload: boolean }) {
      state.isSidebarOpen = action.payload;
    },
    setIsSidebarDocsOpen(state, action: { payload: boolean }) {
      state.isSidebarDocsOpen = action.payload;
    },
    setIsLoading(state, action: { payload: boolean }) {
      state.loading = action.payload;
    },
    setKidProcessed(state, action: { payload: string }) {
      state.secretRotation.kid = action.payload;
    },
    removeError(state) {
      state.appError = false;
    },
    removeDeleteKeyStatus(state) {
      state.secretRotation.isDeleteJWKLoading = DeleteKeyStatusEnum.IDLE;
    },
    resetAppErrorCode(state) {
      state.appErrorCode = "";
    },
    resetAppDetails(state) {
      state.appDetails = initialState.appDetails;
    },
  },
});

export default appDataSlice.reducer;
