import { IStyles } from "../../../shared/CreateNamedStyles";

export const PageHeadStyles = (color: string, fontSize: string) => {
  const rootStyles: IStyles = {
    root: {
      color,
      fontSize,
    },
  };
  return rootStyles.root;
};

export const HeadStyles = (marginBottom: string) => {
  const rootStyles: IStyles = {
    root: {
      marginBottom,
      textAlign: "left",
      fontSize: "32px !important",
    },
  };
  return rootStyles.root;
};
