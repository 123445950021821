import React from "react";
import { Typography } from "@mui/material";

interface IErrorInputMessage {
  error: string;
}

const ErrorInputMessage = ({ error }: IErrorInputMessage) => {
  return (
    <Typography
      color="#E15241"
      variant="subtitle1"
      sx={{
        marginTop: "3px",
        fontWeight: "400",
        fontSize: "0.75rem",
        lineHeight: "1.66",
        letterSpacing: "0.03333em",
        textAlign: "left",
        marginLeft: 0,
      }}
    >
      {error}
    </Typography>
  );
};

export default ErrorInputMessage;
