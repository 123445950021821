import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const DeleteJWKStyles = createNamedStyles({
  box: {
    margin: "0 40px 48px 40px",
    padding: "0 10px 0 10px",
    textAlign: "center",
    lineHeight: "32px !important",
    inlineSize: "450px",
  },
  button: {
    width: "220px",
  },
});
