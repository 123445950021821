import React from "react";
import { Typography, Box, Button } from "@mui/material";
import StyledModal from "../StyledModal/StyledModal";
import { GenerateJWKStyles as styles } from "./GenerateJWK.styles";

type GenerateModalProps = {
  confirmGenerate: () => void;
  onClose: () => void;
  openDialog: boolean;
};

export const GenerateJWK: React.FC<GenerateModalProps> = ({
  confirmGenerate,
  openDialog,
  onClose,
}) => {
  return (
    <StyledModal
      contentText={
        <Box sx={styles.box}>
          <Typography variant="modalsContent">
            Are you sure you want to create a new keypair?
          </Typography>
        </Box>
      }
      title="Confirm Keypair Creation"
      open={openDialog}
      modalActions={
        <>
          <Button
            variant="white"
            onClick={onClose}
            size="small"
            sx={styles.button}
          >
            Cancel
          </Button>

          <Button
            variant="black"
            onClick={confirmGenerate}
            size="small"
            sx={styles.button}
          >
            Create Keypair
          </Button>
        </>
      }
      handleClose={onClose}
    />
  );
};
