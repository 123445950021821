import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { PrivateApi, PublicApi } from "../../../shared/utils/KyApi";
import {
  AuthRequest,
  AuthResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from "../../../types/auth";
import { UsesrInfoResponse } from "../../../types/user";

export const getToken = createAsyncThunk<AuthResponse, AuthRequest>(
  "/api/xdp/v1/oauth2-callback",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await PublicApi.get(
        `api/xdp/v1/oauth2-callback?code=${payload.code}`,
        { credentials: "include" }
      )
        .json<AuthResponse>()
        .catch((error) => {
          throw error.response;
        });

      return response;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);

export const getRefreshToken = createAsyncThunk<
  RefreshTokenResponse,
  RefreshTokenRequest
>("/api/xdp/v1/refresh-token", async (payload, { rejectWithValue }) => {
  try {
    const response = await PublicApi.post(`api/xdp/v1/refresh-token`, {
      credentials: "include",
      json: payload,
    })
      .json<AuthResponse>()
      .catch((error) => {
        throw error.response;
      });

    return response;
  } catch (error) {
    return rejectWithValue({
      message: get(error, "statusText", ""),
      code: get(error, "status", ""),
    });
  }
});

export const getUserInfo = createAsyncThunk<UsesrInfoResponse>(
  "/api/xdp/v1/user-info",
  async (_, { rejectWithValue }) => {
    try {
      const responseUser = await PrivateApi()
        .get(`api/xdp/v1/user-info`)
        .json<UsesrInfoResponse>()
        .catch((error) => {
          throw error.response;
        });

      return responseUser;
    } catch (error) {
      return rejectWithValue({
        message: get(error, "statusText", ""),
        code: get(error, "status", ""),
      });
    }
  }
);
