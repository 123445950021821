import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { SimpleHeaderStyles as styles } from "./SimpleHeader.styles";
import { SIGN_IN_BUTTON } from "../../shared/constants/labels/General";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import MainLogoRiotFist21Icon from "../../icons/mainLogoRiotfist21";

type simpleHeaderProps = {
  externalPortalName: string;
  version: string;
  loginURL: string;
};

const SimpleHeader = ({
  externalPortalName,
  loginURL,
  version,
}: simpleHeaderProps) => {
  const handleRedirect = useCallback(() => {
    window.location.href = loginURL;
  }, [loginURL]);

  return (
    <Box sx={styles.box}>
      <Box sx={styles.boxSecondary}>
        <MainLogoRiotFist21Icon color={ColorsEnum["#F9F9F9"]} />
        <Typography sx={styles.portalName}>{externalPortalName}</Typography>
        <Typography sx={styles.typographyVersion}>{version}</Typography>
      </Box>
      <Box sx={styles.boxBtn}>
        <Button
          onClick={handleRedirect}
          size="small"
          variant="contained"
          sx={styles.signInBtn}
        >
          {SIGN_IN_BUTTON}
        </Button>
      </Box>
    </Box>
  );
};

export default SimpleHeader;
