import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const LoadingSpinner = createNamedStyles({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});
