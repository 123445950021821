import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const JWTPanelStyles = createNamedStyles({
  box: {
    textAlign: "center",
    display: "block",
    mb: "32px",
    padding: "0 10px 0 10px",
  },
});
