/* eslint-disable prefer-template */
import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const ConfirmationModalStyles = createNamedStyles({
  customLinkBox: {
    padding: "16px 64px 12px 64px !important",
    borderRadius: "9.6px",
    textTransform: "none !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    margin: "0 8px 0 8px",
    boxShadow: "none",
    backgroundColor: ColorsEnum["#1F1F1F"],
    ":hover": {
      cursor: "pointer",
      boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
    },
  },
});
