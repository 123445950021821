import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const DocumentationStyles = createNamedStyles({
  root: {},
  box: {
    color: "white",
    marginBottom: "100px",
  },
  boxMain: {
    display: "flex",
  },
  boxSidebar: {
    width: "400px",
    maxHeight: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarColor: "#636363 #292929",
  },
  boxContent: {
    width: "100%",
  },
});
