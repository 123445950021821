import React from "react";
import { Button } from "@mui/material";
import StyledModal from "../../components/StyledModal/StyledModal";
import { removeError } from "../../store/actions/app.action";
import { removeError as removeErrorRso } from "../../store/actions/rsoClient.action";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { selectAppError } from "../../store/selectors/app.selector";
import {
  selectAppErrorMessage,
  selectAppError as selectAppErrorRso,
} from "../../store/selectors/rsoClient.selector";

const ErrorModal = () => {
  const isModalOpen = useAppSelector(selectAppError);
  const isModalOpenRso = useAppSelector(selectAppErrorRso);
  const errorMessage = useAppSelector(selectAppErrorMessage);
  const dispatch = useAppDispatch();
  const handleRemoveError = () => {
    if (isModalOpen) dispatch(removeError());
    if (isModalOpenRso) dispatch(removeErrorRso());
  };

  return (
    <StyledModal
      contentText={errorMessage || "Oops! An error occurred. Please try again."}
      open={isModalOpen || isModalOpenRso}
      modalActions={
        <Button
          variant="black"
          onClick={handleRemoveError}
          sx={{ width: "408px" }}
          size="small"
        >
          Dismiss
        </Button>
      }
      handleClose={() => dispatch(removeError())}
    />
  );
};

export default ErrorModal;
