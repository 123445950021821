import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import { useAppSelector } from "../../store/hooks/hooks";
import {
  SideBarDocsIStyles,
  StyleLinkElement,
  SideBarDocsStyles as styles,
} from "./SidebarDocs.styles";
import {
  DeveloperPortalOverviewEnum,
  FaqsEnum,
  PoliciesEnum,
} from "../../shared/constants/enums/Documentation";
import { selectIsSideBarDocsOpen } from "../../store/selectors/app.selector";

const SideBarDocs = () => {
  const isSideBarDocsOpen = useAppSelector(selectIsSideBarDocsOpen);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box sx={SideBarDocsIStyles("box", false, isSideBarDocsOpen)}>
      <Accordion
        expanded={expanded === "dpo"}
        onChange={handleChange("dpo")}
        sx={styles.accordion}
      >
        <AccordionSummary sx={styles.accordionSummary}>
          <Typography sx={SideBarDocsIStyles("title", expanded === "dpo")}>
            Developer Portal Overview{" "}
            <Box mt={0.3}>
              {expanded === "dpo" ? (
                <MdArrowDropDown fontSize="16px" />
              ) : (
                <MdArrowRight fontSize="16px" />
              )}
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordion}>
          {Object.keys(DeveloperPortalOverviewEnum).map((key) => {
            const { url, label, id, testId } = DeveloperPortalOverviewEnum[key];

            return (
              <StyleLinkElement
                key={id}
                data-testid={testId}
                label={label}
                url={url}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "faqs"}
        onChange={handleChange("faqs")}
        sx={styles.accordion}
      >
        <AccordionSummary sx={styles.accordionSummary}>
          <Typography sx={SideBarDocsIStyles("title", expanded === "faqs")}>
            Frequently Asked Questions{" "}
            <Box mt={0.3}>
              {expanded === "faqs" ? (
                <MdArrowDropDown fontSize="16px" />
              ) : (
                <MdArrowRight fontSize="16px" />
              )}
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordion}>
          {Object.keys(FaqsEnum).map((key) => {
            const { url, label, id, testId } = FaqsEnum[key];
            return (
              <StyleLinkElement
                key={id}
                data-testid={testId}
                label={label}
                url={url}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "policies"}
        onChange={handleChange("policies")}
        sx={styles.accordion}
      >
        <AccordionSummary sx={styles.accordion}>
          <Typography sx={SideBarDocsIStyles("title", expanded === "policies")}>
            Policies{" "}
            <Box mt={0.3}>
              {expanded === "policies" ? (
                <MdArrowDropDown fontSize="16px" />
              ) : (
                <MdArrowRight fontSize="16px" />
              )}
            </Box>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordion}>
          {Object.keys(PoliciesEnum).map((key) => {
            const { url, label, id, testId } = PoliciesEnum[key];
            return (
              <StyleLinkElement
                key={id}
                data-testid={testId}
                label={label}
                url={url}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SideBarDocs;
