import React, { ReactNode } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { StyledModalStyles as styles } from "./StyledModal.styles";

interface IStyledModal {
  open: boolean;
  handleClose: () => void;
  contentText: string | ReactNode;
  title?: string;
  modalActions?: ReactNode;
}

const StyledModal = ({
  open,
  handleClose,
  contentText,
  title,
  modalActions,
}: IStyledModal) => {
  return (
    <Dialog
      sx={styles.dialogContainer}
      open={open}
      onClose={handleClose}
      tabIndex={-1}
    >
      {title && (
        <DialogTitle sx={styles.title}>
          <Typography sx={{ color: "black" }} variant="h4" textAlign="center">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent sx={styles.dialogContent}>
        <Stack direction="row" spacing={1.5}>
          {contentText}
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Box sx={styles.boxContainer}>{modalActions}</Box>
      </DialogActions>
    </Dialog>
  );
};

export default StyledModal;
