import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import { PageContentStyles } from "./Page.styles";

type PageProps = {
  children: ReactNode;
};

const Page = ({ children }: PageProps) => {
  return (
    <Grid container sx={PageContentStyles()}>
      {children}
    </Grid>
  );
};

export default Page;
