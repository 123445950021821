import React from "react";
import { Typography, TypographyVariant, Box } from "@mui/material";

interface InputWrapperProps {
  title: string;
  titleVariant?: TypographyVariant;
  description: string | React.ReactNode;
  children: React.ReactNode;
}

const InputWrapper = ({
  title,
  titleVariant = "h6",
  description,
  children,
}: InputWrapperProps) => {
  return (
    <>
      <Box
        sx={{
          marginBottom: "40px",
          width: "100%",
          maxWidth: "1000px",
          "&:last-of-type": {
            marginBottom: 0,
          },
        }}
      >
        <Typography sx={{ marginBottom: "4px" }} variant={titleVariant}>
          {title}
        </Typography>
        <Typography sx={{ marginBottom: "20px" }} variant="body2">
          {description}
        </Typography>
        {children}
      </Box>
    </>
  );
};

export default InputWrapper;
