import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const CreateRsoClientFormStyles = createNamedStyles({
  gridSection: {
    marginTop: "20px",
    p: "20px",
    border: "0.2px solid #575757",
    borderRadius: "8px",
    boxShadow:
      "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
    marginBottom: "30px",
  },
  gridButtons: {
    marginTop: "20px",
    marginBottom: "30px",
  },
  titleForm: {
    marginBottom: {
      marginBottom: "20px",
    },
  },
});

export default CreateRsoClientFormStyles;
