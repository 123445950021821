import {
  TokenEndpointAuthMethods,
  GrantTypes,
  ClientType,
  GamesAccess,
} from "../../types/clients";

export type DefaultClientFields = {
  contacts: string[];
  companyLogo?: string;
  redirectUris: string[];
  postLogoutRedirectUris?: string[];
  authMethod: TokenEndpointAuthMethods;
  gamesAccess: string[];
  scopes: string[];
  grantType: GrantTypes;
};

const defaultClientFields: { [clientType: string]: DefaultClientFields } = {
  [ClientType.ThirdParty]: {
    contacts: ["devrel-rg@riotgames.com"],
    authMethod: TokenEndpointAuthMethods.ClientSecretBasic,
    gamesAccess: [
      GamesAccess.Lol,
      GamesAccess.Lor,
      GamesAccess.Tft,
      GamesAccess.Val,
    ],
    redirectUris: [""],
    postLogoutRedirectUris: [""],
    scopes: ["openid", "offline_access"],
    grantType: GrantTypes.AuthorizationCode,
  },
};

function getDefaultClientFieldsForClientType(clientType: string) {
  return defaultClientFields[clientType] != null
    ? defaultClientFields[clientType]
    : {
        contacts: ["devrel-rg@riotgames.com"],
        companyLogo: "",
        redirectUris: [""],
        postLogoutRedirectUris: [""],
        scopes: ["openid"],
        authMethod: TokenEndpointAuthMethods.None,
        gamesAccess: [GamesAccess.Lol],
        grantType: GrantTypes.Implicit,
      };
}

const defaultClientAuthnTypeMap: {
  [clientType: string]: TokenEndpointAuthMethods[];
} = {
  [ClientType.ThirdParty]: [
    TokenEndpointAuthMethods.ClientSecretBasic,
    TokenEndpointAuthMethods.PrivateKeyJWT,
  ],
};

function getDefaultClientAuthnTypeForClientType(clientType: string) {
  return defaultClientAuthnTypeMap[clientType] != null
    ? defaultClientAuthnTypeMap[clientType]
    : [TokenEndpointAuthMethods.PrivateKeyJWT];
}

const additionalClientFields: { [clientType: string]: string[] } = {
  [ClientType.ThirdParty]: [
    "policy_uri",
    "tos_uri",
    "post_logout_redirect_uris",
    "logo_uri",
    "subject_type",
  ],
};

function getDefaultClientPropertiesForClientType(clientType: string) {
  return additionalClientFields[clientType] != null
    ? additionalClientFields[clientType]
    : [];
}

export type ClientDefaultFields = {
  clientAuthMethods: TokenEndpointAuthMethods[];
  additionalFields: string[];
  defaultFields: DefaultClientFields;
};

export function getDefaultFieldsForClientType(clientType: string) {
  const defaults: ClientDefaultFields = {
    clientAuthMethods: getDefaultClientAuthnTypeForClientType(clientType),
    additionalFields: getDefaultClientPropertiesForClientType(clientType),
    defaultFields: getDefaultClientFieldsForClientType(clientType),
  };
  return defaults;
}
