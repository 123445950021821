import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import { xdpColors } from "../../theme/themeConstants";

export const SimpleHeaderStyles = createNamedStyles({
  box: {
    display: "flex",
    flexShrink: 0,
    alignItems: "flex-start",
    padding: "24px 36px 24px 36px",
    height: "fit-content",
    backgroundColor: ColorsEnum["#111111"],
  },
  boxSecondary: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "auto",
  },
  boxBtn: {
    display: "flex",
    height: "32px",
    marginLeft: "auto",
  },
  boxDivider: {
    paddingLeft: "36px",
    paddingRight: "54px",
  },
  portalName: {
    fontWeight: 700,
    fontSize: 14,
    paddingLeft: "36px",
    color: "white",
    lineHeight: "32px",
  },
  typographyVersion: {
    fontWeight: 700,
    fontSize: 14,
    paddingLeft: "8px",
    lineHeight: "32px",
    color: xdpColors.backgroundGrey,
  },
  loginBtn: {
    color: ColorsEnum["#F2F2F2"],
  },
  signInBtn: {
    backgroundColor: `${ColorsEnum["#292727"]}`,
    color: `${ColorsEnum["#ffffff"]}`,
    paddingLeft: "36px",
    paddingRight: "36px",
    fontWeight: 700,
    fontSize: "13px",
    borderRadius: "6.4px",
  },
});
