import { InputBase } from "@mui/material";
import { styled } from "@mui/system";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const StyledInputBase = styled(InputBase)(() => ({
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  background: "rgba(128, 128, 128, 0.3)",
  borderColor: "rgba(128, 128, 128, 0.3)",
  borderRadius: "4px",
  input: {
    color: ColorsEnum["#FCFCFC"],
    minHeight: "40px",
    minWidth: "440px",
    fontWeight: 700,
    fontSize: "16px",
    "&::placeholder": {
      color: ColorsEnum["#C7C7C7"],
    },
  },
  "& label.Mui-focused": {
    color: ColorsEnum["#FCFCFC"],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: ColorsEnum["#FCFCFC"],
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(128, 128, 128, 0.3)",
    },
    "&:hover fieldset": {
      borderColor: ColorsEnum["#FCFCFC"],
    },
    "&.Mui-focused fieldset": {
      borderColor: ColorsEnum["#FCFCFC"],
    },
  },
  "& .MuiInputBase-input": {
    minWidth: 440,
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderRadius: 4,
      color: ColorsEnum["#FCFCFC"],
    },
  },
}));
