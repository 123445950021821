import React, { useEffect } from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Controller } from "react-hook-form";
import { IoMdLock } from "react-icons/io";
import SectionUpdateNotificationRow from "../SectionUpdateNotificationRow/SectionUpdateNotificationRow";
import { ICreateRsoClientForm } from "./state/useCreateRsoClientForm.interface";
import useCreateRsoClientForm from "./state/useCreateRsoClientForm";
import { CreateRsoClientFormStyles as styles } from "./CreateRsoClientForm.styles";
import UploadImage from "../UploadImage/UploadImage";
import MultipleInput from "../MultipleInputs/MultipleInput";
import ErrorInputMessage from "../InputMessages/ErrorMessage";
import StyledSection from "../styled/Section/Section";
import SectionHead from "../styled/SectionHead/SectionHead";
import SectionBody from "../styled/SectionBody/SectionBody";
import StyledInput from "../StyledInput/StyledInput";
import InputWrapper from "../InputWrapper/InputWrapper";
import StyledLink from "../styled/Link/Link";
import StyledSelect from "../StyledSelect/StyledSelect";
import { useAppSelector } from "../../store/hooks/hooks";
import { selectIsCookieExpired } from "../../store/selectors/rsoClient.selector";
import PendingChangeInfo from "../PendingChangeInfo/PendingChangeInfo";
import DisplayField from "../DisplayField/DisplayField";
import getLegacyPortalUrlForApp from "../../shared/utils/LegacyPortal";

const CreateRsoClientForm: React.FC<ICreateRsoClientForm> = ({
  env,
  clientType,
  onSave,
  error,
  resetError,
  initialData,
  onCancel,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    errors,
    isEditing,
    onSubmit,
    trigger,
    clientTypeProperties,
    globalError,
    dirtyFields,
    getFieldError,
    deleteGameAccessValue,
    isValid,
    setError,
    clearErrors,
    isSubmitting,
  } = useCreateRsoClientForm({
    env,
    clientType,
    onSave,
    resetError,
    initialData,
    error,
  });

  const cookieFromState = useAppSelector(selectIsCookieExpired);

  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      if (!isEmpty(dirtyFields) && !cookieFromState) {
        const message = "Sure you want to leave?";
        event.preventDefault();
        event.returnValue = message;
      }
    };
    window.addEventListener("beforeunload", preventUnload);

    return () => {
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, [dirtyFields]);

  return (
    <Grid item xs={12}>
      <StyledSection>
        <SectionHead>
          <Box display="flex" alignItems="center">
            <Typography variant="h4">Linked App ID</Typography>
            {!!initialData && (
              <IoMdLock color="white" style={{ marginLeft: "5px" }} />
            )}
          </Box>
        </SectionHead>
        <SectionBody>
          {initialData ? (
            <DisplayField title="App ID">
              <StyledLink
                toUrl={getLegacyPortalUrlForApp(initialData?.appId)}
                sx={{ fontSize: "14px !important" }}
              >
                {initialData.appId}
              </StyledLink>
            </DisplayField>
          ) : (
            <Controller
              name="appId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputWrapper
                  title="Application ID"
                  description={
                    <>
                      Enter the 6-digit number from your Riot API production
                      application. You can find this at{" "}
                      <StyledLink
                        toUrl="https://developer.riotgames.com"
                        sx={{ fontSize: "14px !important" }}
                      >
                        developer.riotgames.com
                      </StyledLink>
                      .
                    </>
                  }
                >
                  <StyledInput
                    placeholder="Application ID"
                    disabled={!!initialData}
                    hidden={isEditing}
                    helperText={errors.appId && errors.appId.message}
                    value={field.value}
                    onChange={(event) => {
                      const formattedAppId = event.target.value
                        .replace(/[\s_]+/g, "")
                        .toLowerCase()
                        .replace(/[^0-9]+/g, "");

                      setValue("appId", formattedAppId.slice(0, 6), {
                        shouldDirty: true,
                      });
                      trigger("appId");
                    }}
                    error={
                      errors.appId?.message ||
                      getFieldError("appId", globalError)
                    }
                  />
                </InputWrapper>
              )}
            />
          )}
          {isEditing && !!dirtyFields.appId && <SectionUpdateNotificationRow />}
          {!!initialData && (
            <Box>
              <PendingChangeInfo fieldName="the application ID" />
            </Box>
          )}
        </SectionBody>
      </StyledSection>
      <StyledSection>
        <SectionHead>
          <Box display="flex" alignItems="center">
            <Typography variant="h4">App Information</Typography>
          </Box>
        </SectionHead>
        <SectionBody>
          <Controller
            name="clientName"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <InputWrapper
                title="Client Name"
                description="Use a human-readable name to identify your application. Players will see this name as part of a UI element."
              >
                <StyledInput
                  placeholder="Client Name"
                  hidden={isEditing}
                  helperText={errors.clientName && errors.clientName.message}
                  value={field.value}
                  onChange={(event) => {
                    const formattedClientName = event.target.value.replace(
                      /[^a-zA-Z0-9-\s_]+/g,
                      ""
                    );

                    setValue("clientName", formattedClientName, {
                      shouldDirty: true,
                    });
                    trigger("clientName");
                  }}
                  error={
                    errors.clientName?.message ||
                    getFieldError("clientName", globalError)
                  }
                />
              </InputWrapper>
            )}
          />
          {isEditing && !!dirtyFields.clientName && (
            <SectionUpdateNotificationRow />
          )}
          <Controller
            name="companyLogo"
            control={control}
            rules={{ required: true }}
            defaultValue={clientTypeProperties.defaultFields.companyLogo}
            render={({ field: { value } }) => (
              <InputWrapper
                title="Client Logo"
                description={
                  <>
                    Upload your company logo, making sure it follows these
                    requirements:
                    <br /> The logo should scale between{" "}
                    <strong>275x275 down to 60x60</strong>. For best results, we
                    recommend using a PNG file. The logo will be displayed on
                    both light and dark backgrounds.
                  </>
                }
              >
                <UploadImage
                  value={value}
                  canDelete={!initialData}
                  setFormError={setError}
                  setFormValue={setValue}
                  clearErrors={clearErrors}
                  error={!!errors.companyLogo}
                  triggerValidation={() => trigger(["companyLogo"])}
                />
                {errors.companyLogo && (
                  <ErrorInputMessage error={errors.companyLogo.message} />
                )}
              </InputWrapper>
            )}
          />
          {isEditing &&
            !!dirtyFields.companyLogo &&
            isEmpty(errors.companyLogo) && <SectionUpdateNotificationRow />}
          <Controller
            name="gamesAccess"
            control={control}
            rules={{ required: true }}
            defaultValue={clientTypeProperties.defaultFields.gamesAccess}
            render={({ field: { onChange, value } }) => (
              <InputWrapper
                title="Game Access"
                description="Select the games that this client will have access to."
              >
                <StyledSelect
                  options={clientTypeProperties.defaultFields.gamesAccess.map(
                    (game: string) => ({
                      label: game,
                      value: game,
                      key: game,
                      isDisabled: false,
                    })
                  )}
                  fullWidth
                  onDelete={deleteGameAccessValue}
                  multiple
                  onChange={(...event) => {
                    trigger("gamesAccess");
                    onChange(...event);
                  }}
                  value={value}
                  data-cy="game-access-selector"
                />
                {errors.gamesAccess && (
                  <ErrorInputMessage error={errors.gamesAccess.message} />
                )}
              </InputWrapper>
            )}
          />

          {isEditing && !!dirtyFields.gamesAccess && (
            <SectionUpdateNotificationRow />
          )}
        </SectionBody>
      </StyledSection>

      <StyledSection>
        <SectionHead>
          <Typography variant="h4">URLs</Typography>
        </SectionHead>
        <SectionBody>
          <Controller
            name="privacyPolicyUri"
            rules={{ required: true }}
            control={control}
            defaultValue=""
            render={({ field: { value } }) => (
              <InputWrapper
                title="Privacy Policy URL"
                description="Enter a URL to your Privacy Policy URL."
              >
                <StyledInput
                  placeholder="https://auth.riotgames.com/privacy"
                  onChange={(event) => {
                    setValue("privacyPolicyUri", event.target.value, {
                      shouldDirty: true,
                    });
                    trigger("privacyPolicyUri");
                  }}
                  defaultValue={value}
                  helperText={errors.privacyPolicyUri?.message}
                  error={
                    errors.privacyPolicyUri?.message ||
                    getFieldError("privacyPolicyUri", globalError)
                  }
                />
              </InputWrapper>
            )}
          />
          {isEditing && !!dirtyFields.privacyPolicyUri && (
            <SectionUpdateNotificationRow />
          )}
          <Controller
            name="tosUri"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field: { value } }) => (
              <InputWrapper
                title="Terms of Service URL"
                description="Enter a URL to your Terms of Service."
              >
                <StyledInput
                  placeholder="https://auth.riotgames.com/tos"
                  onChange={(event) => {
                    setValue("tosUri", event.target.value, {
                      shouldDirty: true,
                    });
                    trigger("tosUri");
                  }}
                  helperText={errors.tosUri?.message}
                  defaultValue={value}
                  error={
                    errors.tosUri?.message ||
                    getFieldError("tosUri", globalError)
                  }
                />
              </InputWrapper>
            )}
          />
          {isEditing && !!dirtyFields.tosUri && (
            <SectionUpdateNotificationRow />
          )}
        </SectionBody>
      </StyledSection>
      <StyledSection>
        <SectionHead>
          <Typography variant="h4">URIs</Typography>
        </SectionHead>
        <SectionBody>
          <Controller
            name="redirectUris"
            control={control}
            rules={{ required: true }}
            defaultValue={clientTypeProperties.defaultFields.redirectUris}
            render={({ field }) => (
              <InputWrapper
                title="Redirect URIs"
                description="Enter a list of URLs you want RSO to redirect players to after they complete their login."
              >
                <MultipleInput
                  helperText={errors.redirectUris?.message}
                  placeholder="https://auth.riotgames.com/oauth/callback"
                  onChange={field.onChange}
                  triggerValidation={() => trigger("redirectUris")}
                  values={field.value}
                  errors={
                    Array.isArray(errors.redirectUris)
                      ? errors.redirectUris?.map(
                          (uri: { message: string }) => uri.message
                        ) || []
                      : []
                  }
                />
              </InputWrapper>
            )}
          />
          {isEditing && !!dirtyFields.redirectUris && (
            <SectionUpdateNotificationRow />
          )}
          <Controller
            name="postLogoutRedirectUris"
            control={control}
            rules={{ required: true }}
            defaultValue={
              clientTypeProperties.defaultFields.postLogoutRedirectUris
            }
            render={({ field }) => (
              <InputWrapper
                title="Post Logout Redirect URIs"
                description="Enter a list of any URLs you want RSO to redirect players to after they log out. "
              >
                <MultipleInput
                  placeholder="https://auth.riotgames.com/logout"
                  onChange={field.onChange}
                  triggerValidation={() => trigger("postLogoutRedirectUris")}
                  values={field.value}
                  helperText={errors.postLogoutRedirectUris?.message}
                  errors={
                    Array.isArray(errors.postLogoutRedirectUris)
                      ? errors.postLogoutRedirectUris?.map(
                          (uri: { message: string }) => uri.message
                        ) || []
                      : []
                  }
                />
              </InputWrapper>
            )}
          />
          {isEditing && !!dirtyFields.postLogoutRedirectUris && (
            <SectionUpdateNotificationRow />
          )}
        </SectionBody>
      </StyledSection>

      <Grid container sx={styles.gridButtons}>
        {onCancel && (
          <Box sx={{ marginRight: "20px" }}>
            <Button variant="grey" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        )}
        <Button
          variant="red"
          disabled={!isValid || isEmpty(dirtyFields) || isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {initialData ? "Save" : "Submit"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateRsoClientForm;
