import { useCallback } from "react";
import { Config } from "../../../config";
import { CookieValidator } from "../../../shared/utils/CookieValidator";

interface IWelcome {
  redirectLegacyPortal: () => void;
  handleRedirect: () => void;
  loginUrl: string;
  hasCookie: boolean;
}

const useWelcome = (): IWelcome => {
  const replaceTrailingSlash = (url: string) => url.replace(/\/+$/, "");
  const base = new URL(window.location.href).origin;
  const url = replaceTrailingSlash(base);
  const redirectUrl = encodeURIComponent(`${url}/`);
  const loginUrl = `${Config.authClientUrl}${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${redirectUrl}oauth2-callback&response_type=code&scope=openid+email+summoner&ui_locales=en`;
  const hasCookie = CookieValidator();
  const handleRedirect = useCallback(() => {
    window.location.href = loginUrl;
  }, [loginUrl]);

  const redirectLegacyPortal = useCallback(() => {
    window.location.href = "https://developer.riotgames.com/";
  }, []);

  return {
    handleRedirect,
    loginUrl,
    redirectLegacyPortal,
    hasCookie,
  };
};

export default useWelcome;
