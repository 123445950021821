import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const AppsStyles = createNamedStyles({
  grid: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    padding: "4px 16px 4px 16px !important",
    fontSize: "14px",
    fontWeight: 800,
  },
  requestTitle: {
    color: "#080808 !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    paddingLeft: "7px",
  },
});
