import React from "react";
import { Grid, Typography } from "@mui/material";
import useSecretRotation from "./state/useSecretRotation";
import { SecretRotationStyles as styles } from "./SecretRotation.styles";
import SectionBody from "../../components/styled/SectionBody/SectionBody";
import { PrivateKeyJWT } from "../PrivateKeyJWT/PrivateKeyJWT";
import { SecretBasic } from "../SecretBasic/SecretBasic";

export const SecretRotation: React.FC = () => {
  const { authMethod } = useSecretRotation();

  return (
    <Grid item xs={12} sx={styles.grid}>
      <SectionBody>
        <Typography variant="h4">Secret Summary</Typography>

        {authMethod === "Private Key JWT" ? (
          <PrivateKeyJWT />
        ) : (
          <Grid item xs={12} md={6}>
            <SecretBasic />
          </Grid>
        )}
      </SectionBody>
    </Grid>
  );
};
