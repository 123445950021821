import { appDataSlice } from "../reducers/app/app.slice";

export const {
  setIsLoading,
  setIsSidebarOpen,
  setIsSidebarDocsOpen,
  setKidProcessed,
  removeError,
  resetAppErrorCode,
  resetAppDetails,
  removeDeleteKeyStatus,
} = appDataSlice.actions;
