import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const SecretRotationStyles = createNamedStyles({
  grid: {
    mb: 20,
    padding: 3,
    borderRadius: 2,
    width: "100%",
    backgroundColor: "#1F1F1F",
  },
  button: {
    padding: "4px 16px 4px 16px !important",
    fontSize: "14px",
    fontWeight: 800,
  },
  input: {
    ".Mui-disabled": {
      "-webkit-text-fill-color": "#C7C7C7 !important",
      opacity: 0.5,
    },
  },
  spinner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  requestTitle: {
    color: "#080808 !important",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    paddingLeft: "7px",
  },
});

export default SecretRotationStyles;
