import styled from "styled-components";
import { MenuList } from "@rdxportal/ui-components";
import { createNamedStyles } from "../../../shared/CreateNamedStyles";

const AccountMenuStyles = createNamedStyles({
  username: {
    color: "white",
    position: "relative",
    padding: "8px 11px",
    backgroundColor: "#212121",
    borderRadius: "10px",
    width: "100%",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "16px",
    cursor: "pointer",
    "&:after": {
      content: "''",
      position: "absolute",
      left: "85%",
      top: "40%",
      width: 0,
      height: 0,
      borderLeft: "3px solid transparent",
      borderRight: "3px solid transparent",
      borderTop: "4px solid #737373",
      clear: "both",
    },
  },
  stacksCenter: {
    alignItems: "left",
    justifyContent: "left",
  },
  stackSecondary: {
    alignItems: "left",
    justifyContent: "left",
    width: "250px",
    padding: "10px 0 0 20px",
  },
  typographyLogOut: {
    color: "#ffffff !important",
    fontSize: "13px !important",
    "&:hover": {
      color: "#ffffff !important",
    },
  },
  box: {
    width: "100%",
  },
  button: {
    width: "90%",
    borderRadius: "10px !important",
    color: "#ffffff !important",
    backgroundColor: "#212121",
    padding: "2px auto 2px 10px",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "rgba(116, 116, 116, 0.1)",
      color: "#ffffff !important",
    },
  },
  divider: {
    borderColor: "rgba(128, 128, 128, 0.2)",
  },
});

const StyledMenu = styled(MenuList)`
  &&& .MuiPaper-root {
    :focus {
      outline: 0px;
      box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
        rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    }
    margin-top: 30px;
    border-radius: 3px;
    border-top: 4px solid #d1363a;
    color: white;
    background-color: #212121;
  }
`;

export { AccountMenuStyles, StyledMenu };
