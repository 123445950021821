import React from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import StyledSection from "../../components/styled/Section/Section";
import PageHead from "../../components/styled/PageHead/PageHead";
import Page from "../../components/styled/Content/Page";
import PageBody from "../../components/styled/PageBody/PageBody";
import SectionHead from "../../components/styled/SectionHead/SectionHead";
import SectionBody from "../../components/styled/SectionBody/SectionBody";
import StyledLink from "../../components/styled/Link/Link";

const helpfulResources = [
  {
    text: "Getting Started with Developer Portal",
    url: "https://developer.riotgames.com/docs/portal#_getting-started",
    id: "1",
  },
  {
    text: "League of Legends",
    url: "https://developer.riotgames.com/docs/lol",
    id: "2",
  },
  {
    text: "Legends of Runeterra",
    url: "https://developer.riotgames.com/docs/lor",
    id: "3",
  },
  {
    text: "Teamfight Tactics",
    url: "https://developer.riotgames.com/docs/tft",
    id: "4",
  },
  {
    text: "VALORANT",
    url: "https://developer.riotgames.com/docs/valorant",
    id: "5",
  },
];

const Home: React.FC = () => {
  return (
    <Page>
      <PageHead title="App Request" />
      <PageBody>
        <Grid container mb={15}>
          <Grid item lg={6.5} md={12} mr="25px">
            <StyledSection>
              <SectionHead>
                <Typography variant="h4">Before You Request an App</Typography>
              </SectionHead>
              <SectionBody>
                <Typography variant="body1">
                  Your product must already be registered with a production API
                  key. If you haven&apos;t done so already, register your
                  product at{" "}
                  <StyledLink
                    display="inline"
                    toUrl="https://developer.riotgames.com"
                  >
                    developer.riotgames.com.{" "}
                  </StyledLink>{" "}
                  Once your product is approved, return here.
                </Typography>
              </SectionBody>
            </StyledSection>

            <StyledSection>
              <SectionHead>
                <Typography variant="h4">Request an App</Typography>
              </SectionHead>
              <SectionBody>
                <Typography variant="body1">
                  If your product has already been approved with a production
                  key, continue here.
                </Typography>
                <Button variant="customWhite" size="medium">
                  <StyledLink
                    toUrl="/apps/new"
                    size="small"
                    customColor="black"
                    textDecoration="none"
                    tabIndex={-1}
                  >
                    Request App
                  </StyledLink>
                </Button>
              </SectionBody>
            </StyledSection>

            <StyledSection>
              <SectionHead>
                <Typography variant="h4">Manage Apps</Typography>
              </SectionHead>
              <SectionBody>
                <Typography variant="body1">
                  View apps, modify app details, and manage secrets.
                </Typography>
                <Button variant="customWhite">
                  <StyledLink
                    toUrl="/apps"
                    size="small"
                    customColor="black"
                    textDecoration="none"
                    tabIndex={-1}
                  >
                    Manage Apps
                  </StyledLink>
                </Button>
              </SectionBody>
            </StyledSection>
          </Grid>
          <Grid item lg={5.25} md={12}>
            <StyledSection>
              <SectionHead>
                <Typography variant="h4">Helpful Resources</Typography>
              </SectionHead>
              <SectionBody>
                <Typography variant="body1">
                  Learn more about the Developer Portal and game-specific
                  developer resources.
                </Typography>
                <Box mt={6}>
                  {helpfulResources.map((resource) => (
                    <Typography key={resource.id} variant="body2">
                      <StyledLink
                        useIcon
                        toUrl={resource.url}
                        textDecoration="none"
                      >
                        {resource.text}
                      </StyledLink>
                    </Typography>
                  ))}
                </Box>
              </SectionBody>
            </StyledSection>
          </Grid>
        </Grid>
      </PageBody>
    </Page>
  );
};

export default Home;
