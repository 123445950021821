/**
 * Takes a SQL datetime string and converts it into a
 * formatted date string of the form MM/DD/YY
 * @param timestamp
 * @returns Date string
 */
export const timestampToDate = (timestamp: string) => {
  return new Date(timestamp).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

/**
 * Takes a SQL datetime string and converts it into a
 * formatted datetime string
 * @param timestamp
 * @returns Date string
 */
export const timestampToDateTime = (timestamp: string | number) => {
  return new Date(timestamp).toLocaleString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
};
