import * as React from "react";
import { MenuItem, Select, Box } from "@mui/material";
import { StyledInputBase } from "./StyledSelect.styles";
import StyledChip from "../styled/Chip/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "white",
    },
  },
};

interface IStyledSelect {
  onDelete?: (deleteParam: string) => void;
  onChange?: () => void;
  fullWidth: boolean;
  multiple: boolean;
  disabled?: boolean;
  value: string[];
  options: {
    key: string;
    value: string;
    label: string;
    isDisabled: boolean;
  }[];
}

const StyledSelect = ({
  onDelete = () => null,
  onChange,
  value,
  options,
  ...props
}: IStyledSelect) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      input={<StyledInputBase />}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.map((v) => (
            <StyledChip
              key={v}
              label={v}
              onDelete={props.disabled ? undefined : onDelete}
            />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
      {...props}
    >
      {options.map((option) => (
        <MenuItem
          style={{ color: "black" }}
          key={option.key}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StyledSelect;
