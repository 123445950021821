import { xdpColors } from "../../shared/constants/enums/Colors";
import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const RiotSignOnStyles = createNamedStyles({
  tableContainer: {
    margin: "32px 0px",
  },
  boxContainer: {
    display: "flex",
    width: "584px",
    height: "63px",
    alignItems: "center",
    boxShadow: `0px 0.5px 0px ${xdpColors.shadowGrey}`,
  },
  headers: {
    fontSize: "14px !important",
  },
});
