import { RootState } from "../store.interfaces";

export const selectApps = (state: RootState) => state.app.apps;
export const selectAppError = (state: RootState) => state.app.appError;
export const selectLoading = (state: RootState) => state.app.loading;
export const selectAppErrorCode = (state: RootState) => state.app.appErrorCode;
export const selectAppDetails = (state: RootState) => state.app.appDetails;
export const selectAppPendingApprovals = (state: RootState) =>
  state.app.appDetails.app.has_pending_approvals;
export const selectAppDetailsLoadingStatus = (state: RootState) =>
  state.app.appDetailsLoadingStatus;
export const selectJWKs = (state: RootState) => state.app.secretRotation.JWKs;
export const selectIsGetJWKLoading = (state: RootState) =>
  state.app.secretRotation.isGetJWKLoading;
export const selectGeneratedJWK = (state: RootState) =>
  state.app.secretRotation.generatedJWK;
export const selectIsJWKLoading = (state: RootState) =>
  state.app.secretRotation.isJWKLoading;
export const selectIsDeleteJWKLoading = (state: RootState) =>
  state.app.secretRotation.isDeleteJWKLoading;
export const selectKidStored = (state: RootState) =>
  state.app.secretRotation.kid;
export const selectIsSecretBasicLoading = (state: RootState) =>
  state.app.secretRotation.isSecretBasicLoading;
export const selectClientSecret = (state: RootState) =>
  state.app.secretRotation.clientSecret;
export const selectIsSideBarOpen = (state: RootState) =>
  state.app.isSidebarOpen;
export const selectIsSideBarDocsOpen = (state: RootState) =>
  state.app.isSidebarDocsOpen;
