import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LoadingSpinner as styles } from "./LoadingSpinner.styles";

const LoadingSpinner = () => {
  return (
    <Box sx={styles.box}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
