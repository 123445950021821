import React from "react";
import { Alert, Box, Snackbar, Tab, Tabs, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import CreateRsoClientForm from "../../components/CreateRsoClientForm/CreateRsoClientForm";
import { Environments } from "../../shared/utils/Environments";
import { ClientType } from "../../types/clients";
import useRsoRequestClient from "../RsoRequestClient/state/useRsoRequestClient";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NotFound from "../NotFound";
import { SecretRotation } from "../SecretRotation/SecretRotation";
import { ColorsEnum } from "../../shared/constants/enums/Colors";
import {
  AppDetailsLoadingStatusEnum,
  AppsStatus,
} from "../../shared/constants/enums/Apps";
import { CopyButton } from "../../components/CopyButton/CopyButton";
import StyledChip from "../../components/styled/Chip/Chip";
import ClientSummary from "../../components/ClientSummary/ClientSummary";
import { PendingChangesModal } from "../../components/PendingChangesModal";
import useAppDetails from "./state/useAppDetails";
import getLegacyPortalUrlForApp from "../../shared/utils/LegacyPortal";

const AppDetails = () => {
  const { onPatch, resetError, closeSnackbar, snackbarOpen, pendingFields } =
    useRsoRequestClient();

  const {
    appId,
    appDetails,
    appDetailsLoadingStatusForm,
    handleTabChange,
    initialData,
    isLoading,
    redirectToApps,
    tabIndex,
  } = useAppDetails();

  if (
    (isEmpty(appDetails) || !appDetails?.app?.status) &&
    appDetailsLoadingStatusForm === AppDetailsLoadingStatusEnum.FAILED
  ) {
    return <NotFound />;
  }

  const { status } = appDetails.app;

  if (status === AppsStatus.PENDING) {
    return (
      <ClientSummary
        appId={initialData.appId!}
        appUrl={getLegacyPortalUrlForApp(initialData.appId!)}
        gameAccess={initialData.gamesAccess!}
        clientName={initialData.clientName!}
        base64Logo={initialData.companyLogo!}
        policyUri={initialData.privacyPolicyUri!}
        tosUri={initialData.tosUri!}
        postLogoutRedirectUris={initialData.postLogoutRedirectUris!}
        contactEmail={initialData.contactEmails!}
        redirectUris={initialData.redirectUris}
      />
    );
  }

  if (status === AppsStatus.REJECTED) {
    return <NotFound />;
  }

  return (
    <>
      {!isLoading && status === AppsStatus.APPROVED ? (
        <>
          <Typography variant="h2">
            {initialData.clientName}
            <StyledChip
              sx={{ marginLeft: "6px", textTransform: "capitalize" }}
              label={appDetails.user_membership?.role.toLowerCase()}
            />
          </Typography>
          <Typography variant="body1">
            {appDetails.app.rso_client_id}
            <CopyButton
              valueToCopy={appDetails.app.rso_client_id}
              confirmationText="Copied!"
            />{" "}
          </Typography>
          <Box display="flex" marginTop="32px">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              TabIndicatorProps={{ children: <span /> }}
              sx={{
                marginLeft: "-16px",
                "& .MuiTab-root": {
                  fontWeight: 800,
                  color: ColorsEnum["#F5F5F5"],
                  letterSpacing: "0.06em",
                  marginBottom: "12px",
                  fontSize: "16px",
                },
                "& .Mui-selected": {
                  color: `${ColorsEnum["#F5F5F5"]} !important`,
                },
                "& .MuiTabs-indicator": {
                  marginBottom: "18px",
                  height: "4px",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  "& > span": {
                    maxWidth: 120,
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: ColorsEnum["#D53235"],
                  },
                },
              }}
            >
              <Tab label="APP DETAILS" />
              <Tab label="SECRETS" />
            </Tabs>
          </Box>

          {tabIndex !== 1 && (
            <>
              <CreateRsoClientForm
                env={Environments.DEV}
                initialData={initialData}
                onSave={(data) => {
                  onPatch({
                    data,
                    appUuid: appId!,
                  });
                }}
                resetError={resetError}
                clientType={ClientType.ThirdParty}
                error={null}
              />
              <PendingChangesModal
                onClose={redirectToApps}
                pendingFields={pendingFields}
              />
              <Snackbar
                open={snackbarOpen && pendingFields.length === 0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                autoHideDuration={6000}
                onClose={() => {
                  redirectToApps();
                  closeSnackbar();
                }}
              >
                <Alert
                  onClose={() => {
                    redirectToApps();
                    closeSnackbar();
                  }}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Changes successfully submitted. Updates may take a few minutes
                  to appear.
                </Alert>
              </Snackbar>
            </>
          )}
          {tabIndex === 1 && <SecretRotation />}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default AppDetails;
