import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";

import { getArticle } from "../../../store/thunks/documentation/documentation.thunks";
import { ZendeskArticleResponse } from "../../../types/documentation";
import {
  selectArticle,
  selectArticleStatus,
} from "../../../store/selectors/documentation.selector";
import { DocumentationStatusEnum } from "../../../shared/constants/enums/Documentation";

interface IDocumentation {
  article: ZendeskArticleResponse;
  statusPage: DocumentationStatusEnum;
}

export const useDocumentationState = (articleId: string): IDocumentation => {
  const dispatch = useAppDispatch();
  const articleResponse = useAppSelector(selectArticle);
  const statusPage = useAppSelector(selectArticleStatus);

  useEffect(() => {
    dispatch(getArticle({ articleId }));
  }, [articleId]);

  return {
    article: articleResponse!,
    statusPage,
  };
};
