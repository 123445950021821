import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  selectAppDetails,
  selectAppDetailsLoadingStatus,
  selectLoading,
} from "../../../store/selectors/app.selector";
import { getAppDetails } from "../../../store/thunks/app/app.thunks";
import { resetAppDetails } from "../../../store/actions/app.action";
import { useRefreshToken } from "../../../shared/utils/useRefreshToken";
import { GamesAccess, gamesToValues } from "../../../types/clients";
import { AppDetailsResponse } from "../../../types/auth";
import { AppDetailsLoadingStatusEnum } from "../../../shared/constants/enums/Apps";

interface IAppDetails {
  appId?: string;
  appDetails: AppDetailsResponse;
  appDetailsLoadingStatusForm: AppDetailsLoadingStatusEnum;
  handleTabChange: (
    e: React.SyntheticEvent<Element, Event>,
    val: number
  ) => void;
  initialData: {
    appId: string;
    contactEmails: string[];
    clientName: string;
    companyLogo: string;
    privacyPolicyUri: string;
    tosUri: string;
    redirectUris: string[];
    postLogoutRedirectUris: string[];
    gamesAccess: GamesAccess[];
  };
  isLoading: boolean;
  redirectToApps: () => void;
  tabIndex: number;
}

const useAppState = (): IAppDetails => {
  useRefreshToken();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const appDetails = useAppSelector(selectAppDetails);
  const isLoading = useAppSelector(selectLoading);
  const appDetailsLoadingStatusForm = useAppSelector(
    selectAppDetailsLoadingStatus
  );
  const { appId } = useParams<"appId">();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (appId) {
      dispatch(getAppDetails({ appId }));
    }
    return () => {
      dispatch(resetAppDetails());
    };
  }, []);

  const [tabIndex, setTabIndex] = useState(
    Number(searchParams.get("tabIndex")) || 0
  );

  const handleTabChange = (e: SyntheticEvent<Element, Event>, val: number) => {
    setSearchParams((params) => {
      params.set("tabIndex", String(val));
      return params;
    });
    setTabIndex(val);
  };

  const redirectToApps = () => navigate("/apps");

  const { app, rso_client } = appDetails;

  // todo(qm): cache
  const legacyAppId = app.legacy_app_id;
  const contactEmails = rso_client.contacts;
  const clientName = rso_client.client_name;
  const companyLogo = rso_client.logo_uri;
  const privacyPolicyUri = rso_client.policy_uri;
  const tosUri = rso_client.tos_uri;
  const redirectUris = rso_client.redirect_uris;
  const postLogoutRedirectUris = rso_client.post_logout_redirect_uris;
  const gamesAccess = app.games.map((game) => gamesToValues[game]);

  // TODO: enable it when updated_at is correct
  // const parsedDate: moment.Moment = moment(app.updated_at);
  // const lastUpdate = parsedDate.format("MMMM DD, YYYY, HH:mm");

  const initialData = {
    appId: legacyAppId,
    contactEmails,
    clientName,
    companyLogo,
    privacyPolicyUri,
    tosUri,
    redirectUris,
    postLogoutRedirectUris,
    gamesAccess,
  };

  return {
    appDetails,
    appDetailsLoadingStatusForm,
    appId,
    handleTabChange,
    initialData,
    isLoading,
    redirectToApps,
    tabIndex,
  };
};

export default useAppState;
