export enum ColorsEnum {
  "#292727" = "#292727",
  "#F2F2F2" = "#F2F2F2",
  "#111111" = "#111111",
  "#ffffff" = "#ffffff",
  "#EEEEEE" = "#EEEEEE",
  "#575757" = "#575757",
  "#1F1F1F" = "#1F1F1F",
  "#0A0A0A" = "#0A0A0A",
  "#FCFCFC" = "#FCFCFC",
  "#D53235" = "#D53235",
  "#808080" = "#808080",
  "#EB6567" = "#EB6567",
  "#2561bb" = "#2561bb",
  "#C7C7C7" = "#C7C7C7",
  "#EBEBEB" = "#EBEBEB",
  "#F9F9F9" = "#F9F9F9",
  "#282828" = "#282828",
  "#686868" = "#686868",
  "#4A4A4A" = "#4A4A4A",
  "#F5F5F5" = "#F5F5F5",
  "#636363" = "#636363",
}

export const xdpColors = {
  black: "#8A8A8A",
  grey: "#1F1F1F",
  whiteResting: "#E5E5E5",
  whiteHover: "#F0F0F0",
  whiteActive: "#F9F9F9",
  redResting: "#D13639",
  redHover: "#C73033",
  redActive: "#BD2A2C",
  blackActive: "#211E1F",
  blackResting: "#292727",
  blackHover: "#211E1F",
  tableBorder: "#8080804D",
  shadowGrey: "#686868",
  backgroundGrey: "#575757",
  typographyWhite: "#C7C7C7",
};
