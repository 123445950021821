import React, { ReactNode } from "react";
import { Grid, GridProps, SxProps } from "@mui/material";
import { SectionHeadStyles as styles } from "./SectionHead.styles";

type SectionHeadProps = {
  children: ReactNode;
} & GridProps;

const SectionHead = ({ children, sx = {}, ...props }: SectionHeadProps) => {
  return (
    <Grid sx={{ ...styles.root, ...sx } as SxProps} {...props}>
      {children}
    </Grid>
  );
};

export default SectionHead;
