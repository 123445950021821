import { useAppSelector } from "../../../store/hooks/hooks";
import {
  selectIsGetJWKLoading,
  selectAppDetails,
} from "../../../store/selectors/app.selector";
import {
  TokenEndpointAuthMethods,
  clientAuthTypeMap,
} from "../../../types/clients";

interface IUseSecretRotation {
  isGetLoading: boolean;
  authMethod: string;
}

const useSecretRotation = (): IUseSecretRotation => {
  const isGetLoading = useAppSelector(selectIsGetJWKLoading);
  const appDetails = useAppSelector(selectAppDetails);

  return {
    isGetLoading,
    authMethod:
      clientAuthTypeMap[
        appDetails.rso_client
          .token_endpoint_auth_method as TokenEndpointAuthMethods
      ],
  };
};

export default useSecretRotation;
