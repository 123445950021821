import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ClientSummaryStyles as styles } from "./ClientSummary.styles";
import DefaultCompanyLogo from "../../assets/DefaultCompanyLogo.png";
import { ClientRequestData } from "../../types/clients";
import { base64todataURL } from "../../shared/utils/ImagesParser";
import Section from "../styled/Section/Section";
import SectionHead from "../styled/SectionHead/SectionHead";
import SectionBody from "../styled/SectionBody/SectionBody";
import StyledLink from "../styled/Link/Link";
import StyledChip from "../styled/Chip/Chip";

const ClientSummary = ({
  appId,
  appUrl,
  contactEmail,
  base64Logo,
  clientName,
  gameAccess,
  postLogoutRedirectUris,
  policyUri,
  redirectUris,
  tosUri,
}: ClientRequestData) => {
  return (
    <Section>
      <SectionHead>
        <Typography variant="h4">Client Request Summary</Typography>
      </SectionHead>
      <SectionBody>
        <Typography variant="body1">
          Your request has been submitted and is awaiting approval. We&apos;ll
          email you once we&apos;ve reviewed it.
        </Typography>
      </SectionBody>
      <SectionBody>
        <Grid container sx={styles.gridInfo}>
          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Application ID
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridContent}>
              <Typography variant="body1" sx={styles.content}>
                <StyledLink sx={styles.link} toUrl={appUrl}>
                  {appId}
                </StyledLink>
              </Typography>
            </Grid>
          </Box>

          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Client Name
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridContent}>
              <Typography variant="body1" sx={styles.content}>
                {clientName}
              </Typography>
            </Grid>
          </Box>

          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Contact Email
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridContent}>
              <Typography variant="body1" sx={styles.content}>
                {contactEmail}
              </Typography>
            </Grid>
          </Box>

          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridImage}>
              <Typography variant="body1" sx={styles.headers}>
                Client Logo
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridContent}>
              <img
                style={{
                  maxWidth: "275px",
                  maxHeight: "275px",
                }}
                src={
                  !base64Logo
                    ? DefaultCompanyLogo
                    : base64todataURL({ base64image: base64Logo })
                }
                alt="company logo"
              />
            </Grid>
          </Box>

          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Games Access
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridGameAccess}>
              {gameAccess &&
                gameAccess.map((item) => (
                  <Box key={item} sx={{ mr: 1 }}>
                    <StyledChip label={item} />
                  </Box>
                ))}
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Privacy Policy URL
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridContent}>
              <StyledLink sx={styles.link} toUrl={policyUri}>
                {policyUri}
              </StyledLink>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Terms of Service URL
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridContent}>
              <StyledLink sx={styles.link} toUrl={tosUri}>
                {tosUri}
              </StyledLink>
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Redirect URIs
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridUrls}>
              {redirectUris &&
                redirectUris.map((url) => (
                  <Typography key={url} variant="body1" sx={styles.content}>
                    {url}
                  </Typography>
                ))}
            </Grid>
          </Box>
          <Box sx={styles.boxContainer}>
            <Grid item md={2} sx={styles.gridTitles}>
              <Typography variant="body1" sx={styles.headers}>
                Post Logout Redirect URIs
              </Typography>
            </Grid>
            <Grid item md={10} sx={styles.gridUrls}>
              {postLogoutRedirectUris &&
                postLogoutRedirectUris.map((url) => (
                  <Typography variant="body1" key={url} sx={styles.content}>
                    {url}
                  </Typography>
                ))}
            </Grid>
          </Box>
        </Grid>
      </SectionBody>
    </Section>
  );
};

export default ClientSummary;
