/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { camelCase, defaultTo } from "lodash";
import { Typography } from "@mui/material";
import { xdpColors } from "../../theme/themeConstants";

function convertStyleToObject(styleString: string) {
  const cssStyleDeclaration = document.createElement("div").style;
  return (
    styleString &&
    styleString
      .split(";")
      .map((s) => s.trim())
      .filter((s) => s)
      .reduce((acc: { [key: string]: string }, pair) => {
        const [key, value] = pair.split(":").map((s) => s.trim());
        const camelCasedKey = camelCase(key);
        if (camelCasedKey in cssStyleDeclaration) {
          acc[camelCasedKey] = value;
        }
        return acc;
      }, {})
  );
}

export const CustomizeHtmlNode = (node: any, index: any) => {
  const { attribs, children, data, name, type } = node;
  const selfClosingTags = ["input", "link", "meta"];

  if (type === "text") {
    return data;
  }

  if (type !== "tag") return undefined;

  const childrenNode = children.map((childNode: any, childIndex: any) =>
    CustomizeHtmlNode(childNode, childIndex)
  );

  if (["strong", "b"].includes(name)) {
    return <strong key={index}>{childrenNode}</strong>;
  }

  if (["em", "i"].includes(name)) {
    return <em key={index}>{childrenNode}</em>;
  }

  if (["a"].includes(name)) {
    const originalUrl = attribs.href;

    const customStyles = {
      alignItems: "center",
      textDecoration: "underline",
      color: xdpColors.typographyRed,
    };

    const updatedAttributes = {
      target: "_blank",
      rel: "noopener noreferrer",
    };

    const childrenNodes = children.map((childNode: any, childIndex: any) =>
      CustomizeHtmlNode(childNode, childIndex)
    );

    return (
      <a
        key={index}
        {...updatedAttributes}
        style={{ ...customStyles }}
        href={originalUrl}
      >
        {childrenNodes}
      </a>
    );
  }

  if (["li"].includes(name)) {
    const customStyles = {
      color: xdpColors.typographyWhite,
      lineHeight: "1.5",
    };
    return (
      <li key={index} style={{ ...customStyles }}>
        {childrenNode}
      </li>
    );
  }

  if (["h1", "h4", "h3"].includes(name)) {
    const customStyles = {
      color: xdpColors.typographyWhite,
    };
    return React.createElement(
      name,
      { key: index, style: { ...customStyles } },
      childrenNode
    );
  }

  if (["code"].includes(name)) {
    const customStyles: React.CSSProperties = {
      backgroundColor: xdpColors.grey,
      fontSize: "13px",
      border: "1px solid #ddd",
      padding: "0 5px",
      borderRadius: "3px",
      margin: "0 2px",
      color: xdpColors.typographyWhite,
    };

    return (
      <code key={index} style={{ ...customStyles }}>
        {childrenNode}
      </code>
    );
  }

  if (["pre"].includes(name)) {
    const customStyles: React.CSSProperties = {
      whiteSpace: "pre-wrap" as any,
      padding: "10px",
      backgroundColor: xdpColors.grey,
      border: "1px solid #ddd",
      borderRadius: "3px",
    };

    const childrenWithCodeStyled = children.map(
      (childNode: any, childIndex: any) => {
        if (childNode.name === "code") {
          const codeStyles: React.CSSProperties = {
            fontSize: "14px",
            color: xdpColors.typographyWhite,
          };
          return (
            <code
              // eslint-disable-next-line react/no-array-index-key
              key={childIndex}
              style={{ ...childNode.attribs.style, ...codeStyles }}
            >
              {ReactHtmlParser(
                defaultTo(childNode.children[0], { data: "" }).data
              )}
            </code>
          );
        }
        return CustomizeHtmlNode(childNode, childIndex);
      }
    );

    return (
      <pre key={index} style={{ ...attribs.style, ...customStyles }}>
        {childrenWithCodeStyled}
      </pre>
    );
  }

  if (["img"].includes(name)) {
    return (
      <img
        key={index}
        src={attribs.src}
        alt={attribs.alt || ""}
        style={{ maxWidth: "100%" }}
      />
    );
  }

  if (["br"].includes(name)) {
    return <br key={index} />;
  }

  if (["hr"].includes(name)) {
    return <hr key={index} />;
  }

  if (["span"].includes(name)) {
    const customStyles = {
      color: xdpColors.typographyWhite,
      textDecoration: "underline",
    };

    return (
      <span key={index} style={{ ...customStyles }}>
        {childrenNode}
      </span>
    );
  }

  if (["p"].includes(name)) {
    return (
      <Typography variant="body1" display="block" mt={0.2} mb={1}>
        {childrenNode}
      </Typography>
    );
  }

  if (["h2"].includes(name)) {
    const customStyles = {
      color: xdpColors.white,
      fontSize: "24px",
    };

    return (
      <Typography style={{ ...customStyles }} variant="h2">
        {childrenNode}
      </Typography>
    );
  }

  if (["table"].includes(name)) {
    const customStyles: React.CSSProperties = {
      whiteSpace: "pre-wrap" as any,
      padding: "15px",
      backgroundColor: xdpColors.grey,
      borderRadius: "3px",
    };

    return (
      <table key={index} style={{ ...attribs.style, ...customStyles }}>
        {childrenNode}
      </table>
    );
  }

  const styleObject = convertStyleToObject(attribs.style || "");

  if (selfClosingTags.includes(name)) {
    return React.createElement(name, {
      key: index,
      style: styleObject,
      ...attribs,
    });
  }

  const customStylesGeneric = {
    color: xdpColors.typographyWhite,
  };

  return React.createElement(
    name,
    { key: index, style: { styleObject, ...attribs, ...customStylesGeneric } },
    childrenNode
  );
};
