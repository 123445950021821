import React from "react";
import { Alert, AlertTitle } from "@mui/material";

import { useAppSelector } from "../../store/hooks/hooks";
import { selectAppPendingApprovals } from "../../store/selectors/app.selector";

const PendingApprovalsBanner = (): JSX.Element | null => {
  const hasPendingApprovals = useAppSelector(selectAppPendingApprovals);

  if (!hasPendingApprovals) return null;

  return (
    <Alert
      severity="warning"
      style={{
        backgroundColor: "#1b1105",
        borderRadius: "4px",
        color: "#ffdca8",
        marginBottom: "24px",
        padding: "6px 16px",
      }}
      sx={{
        "& .MuiAlert-icon": {
          color: "#ffdca8",
        },
      }}
    >
      <AlertTitle style={{ color: "#ffdca8" }}>Pending Approval</AlertTitle>
      Your request is being reviewed. To cancel or make changes to your request,
      email{" "}
      <a href="mailto:devel-rg@riotgames.com" style={{ color: "#ffdca8" }}>
        devel-rg@riotgames.com
      </a>
    </Alert>
  );
};

export default PendingApprovalsBanner;
