import { createTheme } from "@mui/material/styles";
import { xdpTypography } from "./typography";
import { buttonVariants } from "./button";

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
});

theme = createTheme(theme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
  typography: xdpTypography,
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: `"Inter","Helvetica","Arial",sans-serif`,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "12px 48px 12px 48px",
          borderRadius: "9.6px",
          textTransform: "none",
          fontWeight: "700 ",
          fontSize: "16px",
          margin: "0 8px 0 0px",
          boxShadow: "none",
          display: "flex",
        },
      },
      variants: buttonVariants,
    },
  },
  span: {
    fontSize: "16px !important",
  },
});

export default theme;
