import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const RowOptionsStyles = createNamedStyles({
  icon: {
    "&:focus": {
      color: `${ColorsEnum["#C7C7C7"]} !important`,
      opacity: 1,
      backgroundColor: "rgba(128, 128, 128, 0.4)",
    },
  },
  menu: {
    "& .MuiList-root": {
      backgroundColor: ColorsEnum["#1F1F1F"],
      color: "white",
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "rgba(128, 128, 128, 0.4)",
    },
    "& .MuiMenuItem-root": {
      "&:focus": {
        color: `${ColorsEnum["#F5F5F5"]} !important`,
        opacity: 1,
        backgroundColor: "rgba(128, 128, 128, 0.4)",
      },
    },
  },
  menuItem: {
    fontSize: "14px !important",
  },
});

export default RowOptionsStyles;
