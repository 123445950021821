import React from "react";
import { Typography } from "@mui/material";
import { IoMdLock } from "react-icons/io";
import StyledLink from "../styled/Link/Link";

type PendingChangeInfoProps = {
  fieldName: string;
};

const PendingChangeInfo = ({ fieldName }: PendingChangeInfoProps) => {
  return (
    <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
      <IoMdLock color="white" style={{ marginRight: "5px" }} />
      To modify {fieldName}, email{" "}
      <StyledLink
        display="inline-block"
        toUrl="mailto:devrel-rg@riotgames.com"
        sx={{
          fontSize: "14px !important",
          lineHeight: "24px !important",
          marginLeft: "4px",
        }}
      >
        devrel-rg@riotgames.com
      </StyledLink>
      .
    </Typography>
  );
};

export default PendingChangeInfo;
