import { xdpColors } from "./themeConstants";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    tag: React.CSSProperties;
    custom: React.CSSProperties;
    modalsContent: React.CSSProperties;
    modalsTitle: React.CSSProperties;
    italic: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    tag: React.CSSProperties;
    custom: React.CSSProperties;
    modalsContent: React.CSSProperties;
    modalsTitle: React.CSSProperties;
    italic: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tag: true;
    custom: true;
    modalsContent: true;
    modalsTitle: true;
    italic: true;
  }
}

const MuiTypography = {
  h1: {
    fontSize: 52,
    fontWeight: 700,
    color: "white",
    lineHeight: "64px",
  },
  h2: {
    fontSize: "32px",
    color: "white",
    fontWeight: 700,
    lineHeight: "52px",
  },
  h3: {
    fontSize: "24px",
  },
  h4: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "36px",
    color: "white",
  },
  // Old default
  h5: {
    fontSize: 18,
    lineHeight: "32px",
    letterSpacing: "0.00938em",
    color: "white",
  },
  h6: {
    // Old h5
    // TODO: Review all h6
    fontSize: 16,
    color: "white",
    fontWeight: 700,
    lineHeight: "32px",
  },
  body1: {
    // Old variant="body" size="small"
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.00938em",
    color: xdpColors.typographyWhite,
  },
  body2: {
    // Old variant="body" size="xsmall"
    fontSize: 14,
    lineHeight: "24px",
    letterSpacing: "0.00938em",
    color: xdpColors.typographyWhite,
  },
  // Old modalSubtitles
  subtitle1: {
    fontSize: 14,
    letterSpacing: "0.00938em",
    lineHeight: "32px",
    color: "black",
    fontWeight: 700,
  },
  // Old modalDescription
  subtitle2: {
    fontSize: 14,
    letterSpacing: "0.00938em",
    lineHeight: "32px",
    color: xdpColors.typographySubtitle,
    fontWeight: 400,
  },
  button: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: "none",
  },
  caption: {},
  inherit: {},
  overline: {},
};

const CustomTypography = {
  tag: {
    fontSize: "14px",
    fontWeight: 700,
    color: xdpColors.typographyRed,
  },
  custom: {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.00938em",
    color: xdpColors.white,
  },
  modalsTitle: {
    fontSize: 24,
    lineHeight: "32px",
    letterSpacing: "0.00938em",
    fontWeight: 700,
    color: "black",
  },
  modalsContent: {
    fontSize: 16,
    color: "#4A4A4A",
    lineHeight: "32px",
    letterSpacing: "0.00938em",
    fontWeight: 500,
  },
  italic: {
    fontSize: 12,
    color: xdpColors.typographySubtitle,
    fontStyle: "italic",
    lineHeight: "22px",
  },
};

export const xdpTypography = {
  // TODO: Default values for typography not working
  fontSize: 18,
  fontFamily: `"Inter","Helvetica","Arial",sans-serif`,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontWeightExtraBold: 900,
  ...MuiTypography,
  ...CustomTypography,
};
