import React from "react";
import { Chip, SxProps, Theme } from "@mui/material";
import { ColorsEnum } from "../../../shared/constants/enums/Colors";

interface IStyledChip {
  onDelete?: (label: string) => void;
  label: string;
  sx?: SxProps<Theme>;
}

const StyledChip = ({ label, onDelete, sx = {} }: IStyledChip) => {
  return (
    <Chip
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.16)",
        color: ColorsEnum["#ffffff"],
        ...sx,
      }}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onDelete={onDelete ? () => onDelete(label) : undefined}
      key={label}
      label={label}
    />
  );
};

export default StyledChip;
