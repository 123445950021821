import React, { useMemo, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import {
  SecretsRotationTableStyles as styles,
  StyledAppTableHeader,
  StyledTableSortLabel,
  StyledAppTableRows,
} from "./SecretsRotationTable.styles";
import sortingAscDesc from "../../shared/utils/SortingAscDesc";
import {
  JWKData,
  SecretsRotationTableData,
  SecretsRotationTableHeaders,
} from "../../types/secretsRotation";
import { RowOptions } from "../RowOptions/RowOptions";

export interface ISecretsRotationTable {
  rows: SecretsRotationTableData;
  headers: SecretsRotationTableHeaders;
}

export type Order = "asc" | "desc";

export const SecretsRotationTable = ({
  rows,
  headers,
}: ISecretsRotationTable) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof JWKData>("created_at");
  const sortedRows = useMemo(
    () => sortingAscDesc({ order, rows, orderBy }),
    [orderBy, order, rows]
  );

  const handleRequestSort = (property: keyof JWKData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={styles.box}>
      <Paper sx={styles.paper}>
        <TableContainer>
          <Table sx={styles.table}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <StyledAppTableHeader key={header.value}>
                    <StyledTableSortLabel
                      direction={orderBy === header.value ? order : "asc"}
                      active={orderBy === header.value}
                      onClick={() => handleRequestSort(header.value)}
                    >
                      {header.label}
                    </StyledTableSortLabel>
                  </StyledAppTableHeader>
                ))}
                <StyledAppTableHeader key="ACTIONS" width="100px">
                  ACTIONS
                </StyledAppTableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows.map((row) => {
                const date = new Date(row.created_at).toLocaleString();

                return (
                  <TableRow
                    key={row.kid}
                    data-testid={`row-${row.kid}`}
                    sx={styles.headers}
                  >
                    <StyledAppTableRows>
                      <Stack>
                        <Typography variant="h6" sx={styles.row}>
                          {row.kid}
                        </Typography>
                      </Stack>
                    </StyledAppTableRows>
                    <StyledAppTableRows>
                      <Stack>
                        <Typography variant="h6" sx={styles.row}>
                          {date}
                        </Typography>
                      </Stack>
                    </StyledAppTableRows>
                    <StyledAppTableRows>
                      <Stack direction="row">
                        <Box sx={styles.idText}>
                          <RowOptions
                            keyValue={row.kid}
                            rowValue={row.public_key}
                          />
                        </Box>
                      </Stack>
                    </StyledAppTableRows>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
