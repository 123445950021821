import {
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetAppErrorCode } from "../../../store/actions/rsoClient.action";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  selectAppErrorCode,
  selectBase64Image,
} from "../../../store/selectors/rsoClient.selector";
import {
  createRsoClient,
  patchRsoClient,
} from "../../../store/thunks/rsoClient/rsoClient.thunks";
import { ClientRequestData } from "../../../types/clients";
import { FormInput } from "../../../types/clientForm";

interface IRsoRequestClient {
  onSave: (data: ClientRequestData) => void;
  onPatch: (data: {
    data: Partial<ClientRequestData>;
    appUuid: string;
  }) => Promise<void>;
  resetError: () => void;
  pendingFields: string[];
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  modalOpen: boolean;
  snackbarOpen: boolean;
  closeSnackbar: () => void;
  resetPendingFields: () => void;
}

const useRsoRequestClient = (): IRsoRequestClient => {
  const { appId } = useParams<"appId">();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const base64img = useAppSelector(selectBase64Image);
  const appErrorCode = useAppSelector(selectAppErrorCode);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [pendingFields, setPendingFields] = useState<string[]>([]);

  const onSave = async (data: ClientRequestData) => {
    await dispatch(
      createRsoClient({
        ...data,
        base64Logo: base64img || data.base64Logo,
      })
    );
  };

  const onPatch = async ({
    data,
    appUuid,
  }: {
    data: Partial<ClientRequestData>;
    appUuid: string;
  }) => {
    return dispatch(
      patchRsoClient({
        ...data,
        appUuid,
      })
    ).then((payloadData) => {
      if (payloadData?.meta.requestStatus === "fulfilled") {
        setSnackbarOpen(true);

        const pendingFieldKeys = ["clientName", "companyLogo", "gamesAccess"];
        const filteredPendingFields = pendingFieldKeys.filter((field) => {
          const payload = payloadData.payload as Partial<FormInput>;
          return !!payload[field as keyof FormInput];
        });
        setPendingFields(filteredPendingFields);
      }
    });
  };

  const resetError = useCallback(() => {
    window.location.href = "https://developer.riotgames.com/";
  }, []);

  useEffect(() => {
    if (appErrorCode === "200") {
      if (appId) {
        navigate(`/apps/${appId}`);
      } else {
        navigate("/client-view");
      }
    }
    return () => {
      dispatch(resetAppErrorCode());
    };
  }, [appErrorCode]);

  const useRsoRequestClientValues: IRsoRequestClient = {
    onPatch,
    onSave,
    setModalOpen,
    modalOpen,
    resetError,
    pendingFields,
    resetPendingFields: () => setPendingFields([]),
    closeSnackbar: () => setSnackbarOpen(false),
    snackbarOpen,
  };

  return useRsoRequestClientValues;
};

export default useRsoRequestClient;
