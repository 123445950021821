import React from "react";
import { Typography, Box } from "@mui/material";
import { isArray } from "lodash";
import StyledChip from "../styled/Chip/Chip";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

type DisplayFieldProps = {
  title: string;
  value?: string | string[];
  children?: JSX.Element;
};

const DisplayField = ({ title, value, children }: DisplayFieldProps) => {
  return (
    <Box marginBottom="40px">
      <Typography variant="h6" sx={{ lineHeight: "1.2 !important" }}>
        {title}
      </Typography>
      {isArray(value) ? (
        value.map((item) => (
          <StyledChip key={item} label={item} sx={{ margin: "8px 8px 0 0" }} />
        ))
      ) : (
        <Typography
          sx={{
            lineHeight: "1.7 !important",
            fontSize: "16px !important",
            color: `${ColorsEnum["#C7C7C7"]} !important`,
          }}
        >
          {children ?? value}
        </Typography>
      )}
    </Box>
  );
};

export default DisplayField;
