import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const GenerateJWKStyles = createNamedStyles({
  box: {
    margin: "0 20px 48px 20px",
    padding: "0 80px 0 80px",
    textAlign: "center",
    color: "black !important",
  },
  button: {
    width: "220px",
  },
});
