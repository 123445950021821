import * as yup from "yup";
import { defaultTo, get, isEmpty } from "lodash";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AmbassadorError } from "../errors/errors";
import { Client } from "../../types/clients";
import { EditFormInput, FormError, FormInput } from "../../types/clientForm";

const isEmailSchema = yup.string().email();
const URL = /^https:\/\/[^\s/$.?#].[^\s_]*[^\W_]+$|^$/;
const redirectUrlRegexp =
  /^(https:\/\/|http:\/\/local\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]+(\/\S*)?$/;
const invalidCharactersRegexp =
  /^(?!.*\/$)(https:\/\/|http:\/\/local\.)[^\s/$.?#].[^\s_]*[^\W_]$/;

const checkIfUrlIsValid = (url: string | undefined): boolean => {
  if (url && !redirectUrlRegexp.test(url)) {
    return false;
  }

  return true;
};

const checkIfUrlHasInvalidChars = (url: string | undefined): boolean => {
  if (url && !invalidCharactersRegexp.test(url)) {
    return false;
  }

  return true;
};

const validateUrl = yup
  .string()
  .test("invalidUrl", "Not a valid URL", checkIfUrlIsValid)
  .test(
    "invalidChars",
    "URL can not include special characters",
    checkIfUrlHasInvalidChars
  );

export const validUrlSchema = yup.array().of(validateUrl);

export const postLogoutRedirectUrisSchema = validUrlSchema.label(
  "Web Post Logout Redirect URIs"
);

export const redirectUrisSchema = validUrlSchema.label("Web Redirect URIs");
const rsoClientSchemaValidations = {
  appId: yup
    .string()
    .when("$isEditing", (isEditing, schema) =>
      isEditing ? schema : schema.required()
    )
    .max(6, "App Id must be 6 digits or less")
    .label("App Id"),
  privacyPolicyUri: validateUrl.required().label("Privacy Policy URL"),
  tosUri: validateUrl.required().label("Terms of Service URL"),
  clientName: yup
    .string()
    .when("$isEditing", (isEditing, schema) =>
      isEditing ? schema : schema.required()
    )
    .label("Client Name"),
  companyLogo: yup
    .string()
    .when("$isEditing", (isEditing, schema) =>
      isEditing ? schema : schema.required()
    )
    .label("Company Logo"),
  gamesAccess: yup
    .array()
    .when("$isEditing", (_, schema) => schema.min(1))
    .label("Game Access"),
  redirectUris: validUrlSchema.test({
    name: "redirectUris",
    test(val) {
      return val?.every((v) => v === "")
        ? this.createError({
            message: "Redirect URIs is a required field.",
          })
        : true;
    },
  }),
  postLogoutRedirectUris: validUrlSchema
    .test({
      name: "postLogoutRedirectUris",
      test(val) {
        return val?.every((v) => v === "")
          ? this.createError({
              message: `Post Logout Redirect URIs is a required field.`,
            })
          : true;
      },
    })
    .label("Post Logout Redirect URIs"),
  authMethod: yup.string().label("Auth method"),
  selectedScopes: yup.array().of(yup.string()).label("Scopes"),
};

const editRsoClientSchemaValidations = {
  privacyPolicyUri: yup
    .string()
    .matches(URL, "Not A Valid URL")
    .required()
    .label("Privacy Policy URL"),
  tosUri: yup
    .string()
    .matches(URL, "Not A Valid URL")
    .required()
    .label("Terms of Service URL"),
  redirectUris: yup
    .array()
    .test({
      name: "redirectUris",
      test(val) {
        return val?.every((v) => v === "")
          ? this.createError({
              message: `Redirect URIs is a required field.`,
            })
          : true;
      },
    })
    .label("Redirect URIs"),
  postLogoutRedirectUris: validUrlSchema
    .test({
      name: "postLogoutRedirectUris",
      test(val) {
        return val?.every((v) => v === "")
          ? this.createError({
              message: `Post Logout Redirect URIs is a required field.`,
            })
          : true;
      },
    })
    .label("Post Logout Redirect URIs"),
};

// RegExp to include localhost found here https://github.com/jquense/yup/issues/224#issuecomment-417172609
// Modified to allow capital letters
// eslint-disable-next-line no-useless-escape
export const urlRegexIncludingLocalhost =
  "^(?:([a-zA-Z0-9+.-]+)://)(?:S+(?::S*)?@)?(?:(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*.?)(?::d{2,5})?(?:[/?#]S*)?$";

export const rsoClientSchema = yup.object().shape({
  ...rsoClientSchemaValidations,
});

export const editRsoClientSchema = yup.object().shape({
  ...editRsoClientSchemaValidations,
});

export const thirdPartyClientSchema = yup.object().shape({
  ...rsoClientSchemaValidations,
  policyUri: yup.string().required().url().label("Privacy Policy URL"),
  tosUri: yup.string().required().url().label("Terms of Service URIs"),
  redirectUris: yup
    .array()
    .min(1)
    // url() already doesn't allow localhost
    .of(yup.string())
    .label("Redirect URIs"),
  postLogoutRedirectUris: yup
    .array()
    .min(1)
    // url() already doesn't allow localhost
    .of(yup.string())
    .label("Post Logout Redirect URIs"),
  base64Logo: yup.string().required().url().label("Logo URI"),
  sectorUri: yup.string().url().label("Sector Identifier URI"),
  subjectType: yup.string().required().label("Subject Type"),
  privacyPolicyUri: yup.string().required().label("Privacy Policy URL"),
});

export const transformInitialData = (initialData?: FormInput | EditFormInput) =>
  initialData
    ? {
        ...initialData,
        redirectUris: initialData.redirectUris
          ? [...initialData.redirectUris, ""]
          : [],
        postLogoutRedirectUris: initialData.postLogoutRedirectUris
          ? [...initialData.postLogoutRedirectUris, ""]
          : [],
      }
    : undefined;

export const useRsoForm = (clientType: string, initialData?: FormInput) => {
  const formSchema = rsoClientSchema;

  return useForm<FormInput>({
    resolver: yupResolver(formSchema),
    context: { isEditing: !!initialData },
    defaultValues: transformInitialData(initialData),
  });
};

export const translateError = (
  error: AmbassadorError | null | Error
): FormError => {
  if (!error) {
    return null;
  }

  const { message } = error;

  if (message.includes("The preferred client_name is already in use")) {
    return {
      field: "clientName",
      message: "Client Name already in use. Please choose a different name.",
    };
  }

  if (message.includes("HTTPS URI required for implicit grant")) {
    return {
      field: "redirectUris",
      message: "Redirect URIs must start with https://",
    };
  }

  if (
    message.includes("Invalid redirection URI(s): Missing redirection URI(s)")
  ) {
    return {
      field: "redirectUris",
      message: "Redirect URIs must not be empty",
    };
  }

  if (message) {
    return { message } as FormError;
  }
  return null;
};

export const getFieldError = (field: keyof FormInput, error: FormError) => {
  return error?.field === field ? error?.message : "";
};

export const clientFields = [
  "client_name",
  "grant_types",
  "application_type",
  "contacts",
  "token_endpoint_auth_method",
  "token_endpoint_auth_signing_alg",
  "redirect_uris",
  "post_logout_redirect_uris",
  "scope",
  "logo_uri",
  "tos_uri",
  "policy_uri",
] as const;

export const transformToFormData = (data: Client): FormInput => {
  return {
    appId: defaultTo(get(data, "data.app_id", ""), ""),
    clientName: defaultTo(get(data, "client_name", ""), ""),
    companyLogo: defaultTo(get(data, "logo_uri", ""), ""),
    privacyPolicyUri: defaultTo(get(data, "policy_uri", ""), ""),
    redirectUris: [...(data?.redirect_uris ?? []), ""],
    postLogoutRedirectUris: [...(data?.post_logout_redirect_uris ?? []), ""],
    tosUri: data?.tos_uri ?? "",
    gamesAccess: [...(data?.redirect_uris ?? []), ""],
  };
};

export const transformFromFormData = (data: FormInput) => {
  return {
    app_id: defaultTo(get(data, "appId", ""), ""),
    client_name: defaultTo(get(data, "clientName", ""), ""),
    redirect_uris: data?.redirectUris
      ? data?.redirectUris?.filter((uri) => uri)
      : [],
    post_logout_redirect_uris: data?.postLogoutRedirectUris
      ? data?.postLogoutRedirectUris?.filter((uri) => uri)
      : [],
    logo_uri: defaultTo(get(data, "companyLogo", ""), ""),
    tos_uri: data?.tosUri,
    policy_uri: data?.privacyPolicyUri,
  };
};

export const schema = yup.object({
  appId: yup.string().required().label("App Id"),
  clientName: yup.string().required().label("Client name"),
  grantTypes: yup.array().of(yup.string()).label("Grant type"),
  applicationType: yup.string().label("Application type"),
  contactInfo: yup
    .string()
    .required()
    .test({
      name: "emails",
      test(val) {
        const firstInvalidEmail = val
          ?.split(",")
          .map((email) => email.trim())
          .filter((v) => !isEmpty(v))
          .find((v) => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: `The email address '${firstInvalidEmail}' is invalid.`,
            });
      },
    }),
  selectedScopes: yup.array().of(yup.string()).label("Scopes"),
  tokenEndpointAuthMethod: yup.string(),
  tokenEndpointAuthSigningAlg: yup.string(),
  policyUri: yup.string().url().label("Privacy Policy URIs"),
  tosUri: yup.string().url().label("Terms of Service URIs"),
  base64Logo: yup.string().url().label("Logo URI"),
  sectorUri: yup.string().url().label("Sector Identifier URI"),
});

const webSchema = schema.shape({
  postLogoutRedirectUris: postLogoutRedirectUrisSchema,
  redirectUris: redirectUrisSchema,
});

export const createCustomResolver = () => {
  const resolver: Resolver<FormInput> = async (values, context, options) => {
    const data = await yupResolver(webSchema)(values, context, options);

    if (isEmpty(data.errors)) {
      return data;
    }

    return data;
  };

  return resolver;
};
