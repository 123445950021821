export const dataURLtoBase64 = ({ dataURL }: { dataURL: string }) => {
  return dataURL.split("base64,")[1];
};

export const base64todataURL = ({ base64image }: { base64image: string }) => {
  const base64Regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;
  if (base64Regex.test(base64image)) {
    return `data:image/png;base64, ${base64image}`;
  }

  return base64image;
};

export const checkPNGImage = (base64string: string) => {
  const src = base64string;
  const imageData = Uint8Array.from(
    atob(src.replace("data:image/png;base64,", "")),
    (c) => c.charCodeAt(0)
  );
  const sequence = [0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130]; // in hex:

  for (let i = 12; i > 0; i -= 1) {
    if (imageData[imageData.length - i] !== sequence[12 - i]) {
      return false;
    }
  }

  return true;
};

export const checkJpegImage = (base64string: string) => {
  const src = base64string;
  const imageData = Uint8Array.from(
    atob(src.replace("data:image/jpeg;base64,", "")),
    (c) => c.charCodeAt(0)
  );
  const imageCorrupted =
    imageData[imageData.length - 1] === 217 &&
    imageData[imageData.length - 2] === 255;

  return imageCorrupted;
};
