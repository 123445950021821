import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useRowOptions } from "./state/useRowOptions";
import { RowOptionsStyles as styles } from "./RowOptions.styles";

const ITEM_HEIGHT = 48;

export type rowOptionsProps = {
  keyValue: string;
  rowValue: string;
};

export const RowOptions = ({ keyValue, rowValue }: rowOptionsProps) => {
  const {
    anchorEl,
    handleClick,
    handleClose,
    handleAction,
    handleOpenModal,
    isMenuOpen,
  } = useRowOptions({
    rowValue,
    keyValue,
  });

  return (
    <>
      <IconButton id="menu-button" onClick={handleClick} sx={styles.icon}>
        <BiDotsVerticalRounded color="white" />
      </IconButton>
      <Menu
        id="row-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "104px",
            borderRadius: "8px",
            backgroundColor: "rgba(128, 128, 128, 0.4)",
          },
        }}
        autoFocus={false}
        sx={styles.menu}
      >
        <MenuItem key={keyValue} onClick={handleAction} sx={styles.menuItem}>
          Copy Key
        </MenuItem>
        <MenuItem
          key={`${keyValue}_`}
          data-testid={`option-${keyValue}`}
          onClick={handleOpenModal}
          sx={styles.menuItem}
        >
          Delete Key
        </MenuItem>
      </Menu>
    </>
  );
};
