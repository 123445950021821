import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface IStyledCodeBox {
  children?: ReactNode;
}

const StyledCodeBox = ({ children }: IStyledCodeBox) => {
  return (
    <Box
      sx={{
        padding: "36px",
        margin: "50px 0px",
        marginLeft: "36px",
        backgroundColor: "#1F1F1F",
        display: "flex",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#5C5C5C",
          width: "6px",
          marginRight: "36px",
          height: "fix-content",
        }}
      />
      <Box>{children}</Box>
    </Box>
  );
};

export default StyledCodeBox;
