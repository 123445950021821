import { Grid, Typography } from "@mui/material";
import React from "react";

type SectionUpdatedNotificationProps = {
  iconElement: JSX.Element;
  message: string;
};

const SectionUpdatedNotification: React.FC<SectionUpdatedNotificationProps> = ({
  iconElement,
  message,
}) => (
  <Grid container alignContent="center">
    <Grid item>{iconElement}</Grid>
    <Grid item>
      <Typography marginLeft={1} variant="custom">
        <strong>{message}</strong>
      </Typography>
    </Grid>
  </Grid>
);

export default SectionUpdatedNotification;
