import React from "react";
import { Typography, Box, Button } from "@mui/material";
import StyledModal from "./StyledModal/StyledModal";

type PendingChangesModalProps = {
  onClose: () => void;
  pendingFields: string[];
};

const mapPendingFieldsToDisplayNames = {
  clientName: "Client Name",
  companyLogo: "Company Logo",
  gamesAccess: "Game Access",
};

export const PendingChangesModal: React.FC<PendingChangesModalProps> = ({
  onClose,
  pendingFields,
}) => {
  return (
    <StyledModal
      contentText={
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography
            variant="modalsContent"
            lineHeight="22.4px"
            marginBottom="16px"
          >
            We need to review the following changes:
          </Typography>
          {pendingFields.map((field) => {
            return (
              <Typography color="black" fontWeight="bold">
                {
                  mapPendingFieldsToDisplayNames[
                    field as keyof typeof mapPendingFieldsToDisplayNames
                  ]
                }
              </Typography>
            );
          })}
          <Typography variant="modalsContent" marginY="16px">
            After approval, your changes will be updated here.
          </Typography>
        </Box>
      }
      title="Changes Pending Approval"
      open={!!pendingFields.length}
      modalActions={
        <Button variant="white" onClick={onClose} size="small">
          Close
        </Button>
      }
      handleClose={onClose}
    />
  );
};
