import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const CopyInputStyles = createNamedStyles({
  input: {
    background: "rgba(186, 186, 186, 0.3) !important",
    color: ColorsEnum["#0A0A0A"],
    "& .MuiOutlinedInput-root": {
      paddingRight: 0,
      "& fieldset": {
        border: 0,
      },
      "&:hover fieldset": {
        borderColor: ColorsEnum["#FCFCFC"],
      },
      "&.Mui-focused fieldset": {
        borderColor: ColorsEnum["#FCFCFC"],
      },
    },
  },
});
