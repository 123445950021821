import { createNamedStyles } from "../../shared/CreateNamedStyles";
import { ColorsEnum } from "../../shared/constants/enums/Colors";

export const CreateJWKSuccessStyles = createNamedStyles({
  dialog: {
    borderRadius: "9.6px !important",
  },
  box: {
    mt: "16px",
    display: "block",
    textAlign: "center",
    pb: "8px",
  },
  actions: {
    mt: "16px",
    justifyContent: "center",
    backgroundColor: ColorsEnum["#F5F5F5"],
  },
  button: {
    width: "100%",
    margin: "24px 56px 24px 56px",
  },
});
