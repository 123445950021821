import React from "react";
import { Typography, Box, Button } from "@mui/material";
import StyledLink from "../styled/Link/Link";
import StyledModal from "../StyledModal/StyledModal";
import { ConfirmationModalStyles as styles } from "./ConfirmationModal.styles";

interface IConfirmationModal {
  open: boolean;
  handleClose: () => void;
}

const ConfirmationModal = ({ open, handleClose }: IConfirmationModal) => {
  return (
    <StyledModal
      contentText={
        <Typography
          sx={{
            color: "black",
            margin: "40px 0 56px 0",
          }}
          variant="h5"
          textAlign="center"
        >
          If you leave this page your progress won&apos;t be saved.
          <br /> Are you sure you want to leave?
        </Typography>
      }
      open={open}
      modalActions={
        <>
          <Button variant="white" onClick={handleClose} size="small">
            Cancel
          </Button>

          <StyledLink
            toUrl="/home"
            size="small"
            textDecoration="none"
            customColor="white"
          >
            <Box sx={styles.customLinkBox}>
              <Typography variant="h6">Leave</Typography>
            </Box>
          </StyledLink>
        </>
      }
      handleClose={handleClose}
    />
  );
};

export default ConfirmationModal;
