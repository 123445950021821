import { useState } from "react";
import { copyToClipboard } from "../../../shared/utils/CopyText";

interface ICopyButton {
  handleCopy: () => void;
  copied: boolean;
}

type useCopyButtonProps = {
  valueToCopy: string;
};

export const useCopyButton = ({
  valueToCopy,
}: useCopyButtonProps): ICopyButton => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    setCopied(true);
    copyToClipboard(valueToCopy);
    setTimeout(() => setCopied(false), 2000);
  };

  return {
    copied,
    handleCopy,
  };
};
