/* eslint-disable @typescript-eslint/no-explicit-any */
export enum TokenEndpointAuthMethods {
  PrivateKeyJWT = "private_key_jwt",
  ClientSecretJWT = "client_secret_jwt",
  ClientSecretBasic = "client_secret_basic",
  None = "none",
}

export enum GamesAccess {
  None = "none",
  Lol = "League of Legends",
  Val = "VALORANT",
  Tft = "Teamfight Tactics",
  Lor = "Legends of Runeterra",
}

export const gamesAccessValues = {
  "League of Legends": "lol",
  VALORANT: "val",
  "Teamfight Tactics": "tft",
  "Legends of Runeterra": "lor",
};

export const gamesToValues = {
  lol: GamesAccess.Lol,
  val: GamesAccess.Val,
  tft: GamesAccess.Tft,
  lor: GamesAccess.Lor,
};

export const clientGamesAccessMap: Record<GamesAccess, string> = {
  [GamesAccess.None]: "None",
  [GamesAccess.Lol]: "League of Legends",
  [GamesAccess.Val]: "VALORANT",
  [GamesAccess.Tft]: "Teamfight Tactics",
  [GamesAccess.Lor]: "Legends of Runeterra",
};

export type TokenEndpointAuthChangedTypes =
  | TokenEndpointAuthMethods.PrivateKeyJWT
  | TokenEndpointAuthMethods.ClientSecretJWT
  | TokenEndpointAuthMethods.ClientSecretBasic;

export const clientAuthTypeMap: Record<TokenEndpointAuthMethods, string> = {
  [TokenEndpointAuthMethods.PrivateKeyJWT]: "Private Key JWT",
  [TokenEndpointAuthMethods.ClientSecretJWT]: "Client Secret JWT",
  [TokenEndpointAuthMethods.ClientSecretBasic]: "Client Secret Basic",
  [TokenEndpointAuthMethods.None]: "None",
};

export enum GrantTypes {
  AuthorizationCode = "authorization_code",
  Implicit = "implicit",
  ClientCredentials = "client_credentials",
}

export type Client = {
  application_type?: string;
  aws_rep_deleting?: boolean;
  aws_rep_update_region?: string;
  aws_rep_update_time?: number;
  backchannel_logout_session_required?: boolean;
  backchannel_logout_uri?: string;
  client_id?: string;
  client_id_issued_at?: number;
  client_name?: string;
  client_secret?: string;
  client_secret_expires_at?: number;
  client_uri?: string;
  contacts?: string[];
  data?: any;
  grant_types?: string[];
  id_token_signed_response_alg?: string;
  jwks?: JsonWebKeys;
  logo_uri?: string;
  policy_uri?: string;
  post_logout_redirect_uris?: string[];
  redirect_uris?: string[];
  registration_access_token?: string;
  registration_client_uri?: string;
  response_types?: string[];
  scope?: string;
  sector_identifier_uri?: string;
  subject_type?: string;
  token_endpoint_auth_method?: string;
  token_endpoint_auth_signing_alg?: string;
  tos_uri?: string;
  userinfo_encrypted_response_alg?: string;
  userinfo_encrypted_response_enc?: string;
  userinfo_signed_response_alg?: string;

  unconfirmed_scopes: boolean;

  third_party: boolean;
};

export type JsonWebKeys = {
  keys: JsonWebKeyExtended[];
};

export type JsonWebKeyExtended = JsonWebKey & {
  kid?: string;
  x5c?: string;
  x5u?: string;
  x5t?: string;
  xt5_sha256?: string;
  ext?: boolean;
  key_ops?: string[];
  oth?: RsaOtherPrimesInfo[];
};

export type ClientSecret = {
  privateKey: string;
  publicKey: string;
  sampleToken: string;
};

export type CreateSectorURIResponseData = {
  valid: boolean;
  sector_uri: string;
};

export type CreateClientResponse = {
  status: number;
};

export type ClientRequestData = {
  appId: string;
  contactEmail?: string[];
  base64Logo: string;
  clientName?: string;
  gameAccess: string[];
  gamesAccess?: string[];
  policyUri: string;
  tosUri: string;
  redirectUris: string[];
  postLogoutRedirectUris: string[];
  product?: string;
  applicationType?: string;
  environment?: string;
  appUrl?: string;
};

export type UpdateClientRequest = Partial<Client>;

export type CreateClientRequest = {
  app_id: string;
  preferred_client_id?: string;
  client_name: string;
  games: string[];
  scope?: string;
  token_endpoint_auth_method?: string;
  contacts: string[];
  data?: any;
  redirect_uris?: string[];
  logo?: string;
  policy_uri?: string;
  tos_uri?: string;
  sector_identifier_uri?: string;
  subject_type?: string;
  post_logout_redirect_uris?: string[];
  application_type?: string;
};

export type Scope = {
  rrn: string;
  name: string;
  description: string;
  owners: string[];
  approval_required: boolean;
  type: string;
  category: string;
  created_at: Date;
  modified_at: Date;
};

export type Option = {
  value: string;
  label: string;
};

export const grantTypeOptions: Option[] = [
  {
    value: GrantTypes.Implicit as string,
    label: "Implicit",
  },
  {
    value: GrantTypes.AuthorizationCode,
    label: "Authorization Code",
  },
  {
    value: GrantTypes.ClientCredentials,
    label: "Client Credentials",
  },
];

export const clientAuthOptions: Option[] = Object.entries(
  clientAuthTypeMap
).map(([value, label]) => ({
  value,
  label,
}));

export enum ClientType {
  ThirdParty = "third-party",
}

export enum ApplicationType {
  Web = "web",
  Native = "native",
}

export const applicationType: Record<ApplicationType, string> = {
  [ApplicationType.Web]: "web",
  [ApplicationType.Native]: "native",
};

export const applicationTypeOptions: Option[] = Object.entries(
  applicationType
).map(([value, label]) => ({
  value,
  label,
}));

export type EditRsoLocation = {
  state: {
    data: CreateClientResponse;
  };
};

export enum PrivateJWTKeys {
  RS256 = "RS256",
  RS384 = "RS384",
  RS512 = "RS512",
  ES256 = "ES256",
  ES384 = "ES384",
  ES512 = "ES512",
}
export const clientPrivateKeyJWTOptions: Option[] = Object.entries(
  PrivateJWTKeys
).map(([value, label]) => ({
  value,
  label,
}));
