import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import { defaultTo } from "lodash";
import { JWTPanel } from "../JWTPanel/JWTPanel";
import { CreateJWKSuccessStyles as styles } from "./CreateJWKSuccess.styles";
import { useAppSelector } from "../../store/hooks/hooks";
import {
  selectGeneratedJWK,
  selectIsJWKLoading,
} from "../../store/selectors/app.selector";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export type CreateSecretSuccessProps = {
  showCreateSuccess: boolean;
  onClose: () => void;
};

export const CreateJWKSuccess: React.FC<CreateSecretSuccessProps> = ({
  showCreateSuccess,
  onClose,
}) => {
  const isLoading = useAppSelector(selectIsJWKLoading);
  const generatedJWK = useAppSelector(selectGeneratedJWK);

  return (
    <Dialog open={showCreateSuccess} sx={styles.dialog}>
      {!isLoading ? (
        <>
          <DialogTitle sx={styles.box}>
            <Typography variant="modalsTitle">Success!</Typography>
          </DialogTitle>
          <DialogContent>
            <JWTPanel
              secret={{
                privateKey: defaultTo(generatedJWK.private_key, ""),
                publicKey: defaultTo(generatedJWK.public_key, ""),
                sampleToken: defaultTo(generatedJWK.sample_token, ""),
              }}
            />
          </DialogContent>
          <DialogActions sx={styles.actions}>
            <Button
              variant="white"
              onClick={onClose}
              size="small"
              sx={styles.button}
            >
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent
          sx={{
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </DialogContent>
      )}
    </Dialog>
  );
};
