import { useEffect, useState } from "react";
import { ImageListType } from "react-images-uploading";
import { defaultTo, get } from "lodash";
import {
  checkJpegImage,
  checkPNGImage,
  dataURLtoBase64,
} from "../../../shared/utils/ImagesParser";
import { setBase64Image } from "../../../store/actions/rsoClient.action";
import { useAppDispatch } from "../../../store/hooks/hooks";

type IUseUploadImage = {
  images: never[];
  onChange: (imageList: ImageListType, data: number[] | undefined) => void;
  showDefaultImage: boolean;
  hasError: boolean;
};

const useUploadImage = (): IUseUploadImage => {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [showDefaultImage, setShowDefaultImage] = useState(true);

  const onChange = async (imageList: ImageListType) => {
    setImages(imageList as never[]);
    setShowDefaultImage(false);
  };

  useEffect(() => {
    if (images.length === 0) {
      setShowDefaultImage(true);
    } else {
      const checkImage = defaultTo(dataURLtoBase64(images[0]), "");
      let isValidImage = false;

      if (get(images[0], "dataURL", "").includes("png")) {
        isValidImage = checkPNGImage(checkImage);
      } else {
        isValidImage = checkJpegImage(checkImage);
      }

      if (!isValidImage) {
        setHasError(true);
        setShowDefaultImage(true);
      } else {
        setHasError(false);
      }

      dispatch(setBase64Image(checkImage));
    }
  }, [images]);

  const useCreateRsoClientFormValues: IUseUploadImage = {
    images,
    onChange,
    showDefaultImage,
    hasError,
  };

  return useCreateRsoClientFormValues;
};

export default useUploadImage;
