/* eslint-disable import/no-extraneous-dependencies */
import { useState } from "react";
import copy from "copy-to-clipboard";
import { useAppDispatch } from "../../../store/hooks/hooks";
import { setKidProcessed } from "../../../store/actions/app.action";

export type useRowOptionsProps = {
  rowValue: string;
  keyValue: string;
};

interface IUseRowOptions {
  anchorEl: null | HTMLElement;
  firstOpen: boolean;
  isMenuOpen: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  handleAction: () => void;
  handleOpenModal: () => void;
}

export const useRowOptions = ({
  rowValue,
  keyValue,
}: useRowOptionsProps): IUseRowOptions => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [firstOpen, setFirstOpen] = useState(true);
  const dispatch = useAppDispatch();
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setFirstOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    dispatch(setKidProcessed(""));
  };

  const handleAction = () => {
    setAnchorEl(null);
    copy(rowValue);
  };

  const handleOpenModal = () => {
    dispatch(setKidProcessed(keyValue));
    setAnchorEl(null);
  };

  return {
    anchorEl,
    firstOpen,
    isMenuOpen,
    handleClick,
    handleClose,
    handleAction,
    handleOpenModal,
  };
};
