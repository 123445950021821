import React, { useEffect } from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import { defaultTo, isEmpty } from "lodash";
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import ImageUploading, { ErrorsType } from "react-images-uploading";
import useUploadImage from "./state/useUploadImage";
import { UploadImageStyles as styles } from "./UploadImage.styles";
import UploadImageIcon from "../../icons/uploadImageIcon";
import { FormInput } from "../../types/clientForm";
import { dataURLtoBase64 } from "../../shared/utils/ImagesParser";
import { xdpColors } from "../../theme/themeConstants";

const UploadImage = ({
  setFormValue,
  triggerValidation,
  error,
  value,
  canDelete,
  setFormError,
  clearErrors,
}: {
  error: boolean;
  setFormValue: UseFormSetValue<FormInput>;
  setFormError: UseFormSetError<FormInput>;
  clearErrors: UseFormClearErrors<FormInput>;
  canDelete: boolean;
  value: string;
  triggerValidation: UseFormTrigger<FormInput>;
}) => {
  const { hasError, images, onChange, showDefaultImage } = useUploadImage();

  useEffect(() => {
    if (images[0]) {
      setFormValue("companyLogo", defaultTo(dataURLtoBase64(images[0]), ""), {
        shouldDirty: true,
      });
      triggerValidation();
    } else {
      setFormValue("companyLogo", "");
    }
  }, [images]);

  useEffect(() => {
    if (hasError) {
      setFormError("companyLogo", {
        type: "custom",
        message: "Invalid image format",
      });
    } else {
      clearErrors("companyLogo");
    }
  }, [hasError]);

  const handleImageError = (err: ErrorsType) => {
    if (err?.maxFileSize) {
      setFormError("companyLogo", {
        type: "custom",
        message: "Max Image size is 3mb",
      });
    }
  };

  return (
    <Grid container sx={styles.root}>
      <ImageUploading
        multiple
        value={images || { dataURL: value }}
        onChange={onChange}
        maxNumber={1}
        onError={(err) => handleImageError(err)}
        maxFileSize={3000000}
        acceptType={["png", "jpg", "jpeg"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          dragProps,
          errors,
        }) => {
          const imageUrl = imageList[0]?.dataURL || value;
          return (
            <Grid container sx={styles.boxContainer}>
              {showDefaultImage && !imageUrl && (
                <>
                  <Button
                    onClick={onImageUpload}
                    sx={styles.image}
                    {...dragProps}
                  >
                    <Box
                      sx={{
                        ...styles.boxButton,
                        borderColor:
                          error || !isEmpty(errors)
                            ? "#E15241"
                            : "rgba(128, 128, 128, 0.3)",
                      }}
                    >
                      <Box sx={styles.boxIcon}>
                        <UploadImageIcon />
                      </Box>
                      <Box sx={styles.boxBtnText}>
                        <Typography variant="body2">
                          PNG, JPG (max. 3MB)
                          <br />
                          <span
                            style={{
                              color: xdpColors.typographyRed,
                              fontWeight: "bold",
                              textDecoration: "underline",
                            }}
                          >
                            CLICK TO UPLOAD
                          </span>
                          &nbsp;OR DRAG AND DROP
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                </>
              )}

              {hasError ? (
                <Button
                  variant="white"
                  onClick={() => onImageUpdate(0)}
                  sx={styles.buttonReplace}
                >
                  Replace
                </Button>
              ) : (
                imageUrl && (
                  <Box sx={styles.imgContainer}>
                    <Box sx={styles.boxImg}>
                      <img
                        src={
                          !imageUrl?.includes("base64")
                            ? `${imageUrl}?t=${Date.now()}`
                            : imageUrl
                        }
                        alt="companyLogo"
                      />
                    </Box>
                    <Box sx={styles.boxImgBtns}>
                      {canDelete && (
                        <Button
                          onClick={async () => {
                            await onImageRemove(0);
                            triggerValidation();
                          }}
                          sx={styles.buttonDelete}
                        >
                          Delete
                        </Button>
                      )}
                      <Button
                        variant="white"
                        onClick={() => onImageUpdate(0)}
                        sx={styles.buttonReplace}
                      >
                        Replace
                      </Button>
                    </Box>
                  </Box>
                )
              )}
            </Grid>
          );
        }}
      </ImageUploading>
    </Grid>
  );
};

export default UploadImage;
