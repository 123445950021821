import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const ClientSummaryStyles = createNamedStyles({
  boxContainer: {
    display: "flex",
  },
  gridTitles: {
    color: "#FCFCFC",
    padding: "16px",
    paddingLeft: 0,
  },
  gridImage: {
    color: "#FCFCFC",
    padding: "100px 80px 100px 0",
  },
  gridContent: {
    color: "#FCFCFC",
    padding: "16px",
  },
  gridGameAccess: {
    display: "flex",
    color: "#FCFCFC",
    padding: "16px",
  },
  gridInfo: {
    display: "block",
    alignItems: "left",
    flexDirection: "row",
  },
  gridTypography: {
    color: "#F54251",
    marginLeft: 1,
  },
  gridInput: {
    position: "absolute",
    paddingTop: 12,
    paddingLeft: 10,
  },
  gridUrls: {
    display: "block",
    color: "#222325",
    padding: "16px",
  },
  title: {
    letterSpacing: 1,
  },
  headers: {
    fontWeight: "700 !important",
    color: "#FCFCFC !important",
  },
  content: {
    fontWeight: "400 !important",
  },
  contentGameAccess: {
    fontWeight: "400 !important",
    marginRight: "5px",
    paddingLeft: "7px",
    paddingRight: "7px",
    borderRadius: "5px",
    borderColor: "#E6E6E6",
    borderStyle: "solid",
  },
  titleBtn: {
    color: "#ffffff !important",
    fontWeight: "700 !important",
  },
  sectionButton: {
    height: "40px",
    widht: "126px",
    backgroundColor: "#2561bb",
  },
  link: {
    fontWeight: "400 !important",
  },
});
