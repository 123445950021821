import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { defaultTo } from "lodash";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { selectIsCookieExpired } from "../../store/selectors/rsoClient.selector";
import {
  selectRefreshToken,
  selectRefreshTokenStatus,
  selectToken,
} from "../../store/selectors/user.selector";
import { RefreshTokenRequest } from "../../types/auth";
import { getRefreshToken } from "../../store/thunks/user/user.thunks";
import { AuthStatusEnum } from "../constants/enums/Auth";
import { setRefreshTokenStatus } from "../../store/actions/user.action";

export const useRefreshToken = () => {
  const cookieFromState = useAppSelector(selectIsCookieExpired);
  const refreshToken = useAppSelector(selectRefreshToken);
  const newToken = useAppSelector(selectToken);
  const refreshTokenStatus = useAppSelector(selectRefreshTokenStatus);
  const dispatch = useAppDispatch();
  const [, setCookie] = useCookies(["_rg_ess_jwt"]);

  const refreshTokenRequest = () => {
    if (cookieFromState) {
      const refreshRequest: RefreshTokenRequest = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };

      dispatch(getRefreshToken(refreshRequest));
    }
  };

  useEffect(() => {
    refreshTokenRequest();
  }, []);

  useEffect(() => {
    if (refreshTokenStatus === AuthStatusEnum.SUCCESS) {
      dispatch(setRefreshTokenStatus(AuthStatusEnum.IDLE));
      setCookie("_rg_ess_jwt", newToken, {
        expires: new Date(
          Date.now() +
            defaultTo(Number(process.env.REACT_APP_COOKIE_TTL), 3600000)
        ),
      });
    }
  }, [refreshTokenStatus, newToken]);

  return null;
};
