import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Box, Typography } from "@mui/material";
import Page from "../../components/styled/Content/Page";
import PageHead from "../../components/styled/PageHead/PageHead";
import { useDocumentationState } from "./state/useDocumentationState";
import { DocumentationStyles as styles } from "./Documentation.styles";
import { timestampToDate } from "../../shared/utils/Time";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { DocumentationStatusEnum } from "../../shared/constants/enums/Documentation";
import { CustomizeHtmlNode } from "../../shared/utils/customHtmlParse";
import { xdpColors } from "../../theme/themeConstants";
import SideBarDocs from "../../components/SidebarDocs";

const Documentation: React.FC<{ id: string; title: string }> = ({
  id,
  title,
}) => {
  const { article, statusPage } = useDocumentationState(id);

  return (
    <Page>
      <Box sx={styles.boxMain}>
        <Box sx={styles.boxSidebar}>
          <SideBarDocs />
        </Box>
        <Box sx={styles.boxContent}>
          {statusPage === DocumentationStatusEnum.SUCCESS ? (
            <Box ml={4}>
              <PageHead
                title={title}
                color={xdpColors.typographyWhite}
                marginBottom="0"
              />
              <Typography variant="body1" mb={7}>
                Last Update: {timestampToDate(article.article!.updated_at)}
              </Typography>
              <Box sx={styles.box}>
                {ReactHtmlParser(article.article!.body, {
                  transform: CustomizeHtmlNode,
                })}
              </Box>
            </Box>
          ) : (
            <Box>
              <LoadingSpinner />
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
};

export default Documentation;
