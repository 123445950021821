import React from "react";
import { IconButton } from "@mui/material";
import { MdOutlineContentCopy } from "react-icons/md";
import { useCopyButton } from "./state/useCopyButton";

type copyButtonProps = {
  valueToCopy: string;
  confirmationText?: string;
};

export const CopyButton: React.FC<copyButtonProps> = ({
  valueToCopy,
  confirmationText,
}) => {
  const { copied, handleCopy } = useCopyButton({
    valueToCopy,
  });

  return (
    <IconButton
      sx={{
        color: "white",
        display: "inline",
        margin: "0px 4px",
        fontSize: "12px",
        fontWeight: "bold",
      }}
    >
      <MdOutlineContentCopy onClick={handleCopy} />
      {copied && confirmationText && <small>&nbsp;{confirmationText}</small>}
    </IconButton>
  );
};
