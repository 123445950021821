import styled from "styled-components";
import { createNamedStyles } from "../../shared/CreateNamedStyles";

export const MultipleInputStyles = createNamedStyles({
  gridContainer: {
    position: "relative",
    marginBottom: 2,
    width: "100%",
    padding: 0,
  },
  gridContainerError: {
    color: "#F54251",
    alignItems: "center",
    flexDirection: "row",
  },
  gridContainerGlobalError: {
    color: "#F54251",
    py: 2,
    alignItems: "center",
    flexDirection: "row",
  },
  gridIcon: {
    position: "absolute",
    paddingTop: "12px",
    paddingLeft: "10px",
  },
  input: {
    width: "100% !important",
  },
  gridCancel: {
    position: "absolute",
  },
  gridItem: {
    width: "100%",
  },
});

export const InputWrapperStyled = styled.div({
  display: "flex",
  width: "100%",
  position: "relative",
  ".MuiFormControl-root": {
    padding: 0,
  },
  ".MuiInputBase-root": {
    paddingLeft: "16px",
  },
});
